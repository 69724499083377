<template>
  <div
    v-if="
      isAuthenticatedSuperAdmin ||
      isAuthenticatedDTC ||
      isAuthenticatedGestionePersonale ||
      isAuthenticatedConsultazione
    "
    class="col-4 sidebar"
    :class="{ 'element-disabled': isElementDisabled }"
  >
    <nav class="main-left-nav" role="navigation">
      <!-- Routes / Filters -->
      <ul class="unstyled nav-parent-item">
        <li v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale">
          <router-link
            :to="{ name: 'route-teams-add' }"
            exact
            :class="{ active: $route.name === 'route-teams-add' }"
            >+ Crea Nuovo</router-link
          >
        </li>
        <hr
          v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale"
          role="separator"
          aria-orientation="horizontal"
          class="divider"
        />
        <div>
          <li>
            <router-link
              @click.prevent="clearAllAttributesFilters()"
              :to="{ name: 'route-teams' }"
              exact
              :class="{ active: $route.name === 'route-teams' }"
              >Tutti</router-link
            >
          </li>
        </div>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Profili -->
      <div v-if="tabellaProfili.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroProfili"
              style="color: #fff !important"
              >{{
                `Profili (${
                  checkedTabellaProfili.length > 0
                    ? checkedTabellaProfili.length
                    : "tutti"
                })`
              }}</a
            >
            <div id="menuFiltroProfili" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="profilo in tabellaProfili"
                  :key="profilo.id"
                >
                  <input
                    :id="'filter_' + profilo.id"
                    :value="profilo.id"
                    type="checkbox"
                    class="btn-check-filter"
                    :checked="checkedTabellaProfili.includes(profilo.id)"
                    @change="toggleCheckbox('profili', profilo.id)"
                    :disabled="isEditing"
                  />
                  <label class="btn btn-filter" :for="'filter_' + profilo.id">{{
                    profilo.nome
                  }}</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Aziende -->
      <div v-if="tabellaAziende.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroAziende"
              style="color: #fff !important"
              >{{
                `Società (${
                  checkedTabellaAziende.length > 0
                    ? checkedTabellaAziende.length
                    : "tutte"
                })`
              }}</a
            >
            <div id="menuFiltroAziende" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="azienda in tabellaAziende"
                  :key="azienda._id"
                >
                  <input
                    :id="'filter_' + azienda._id"
                    :value="azienda._id"
                    type="checkbox"
                    class="btn-check-filter"
                    :checked="checkedTabellaAziende.includes(azienda._id)"
                    @change="toggleCheckbox('aziende', azienda._id)"
                    :disabled="isEditing"
                  />
                  <label class="btn btn-filter" :for="'filter_' + azienda._id">{{
                    azienda.ragionesociale
                  }}</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Sedi -->
      <div v-if="tabellaSedi.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroSedi"
              style="color: #fff !important"
              >{{
                `Sedi (${
                  checkedTabellaSedi.length > 0 ? checkedTabellaSedi.length : "tutte"
                })`
              }}</a
            >
            <div id="menuFiltroSedi" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="sede in tabellaSedi"
                  :key="sede.sede"
                >
                  <input
                    :id="'filter_sede' + sede.sede"
                    :value="sede.sede"
                    type="checkbox"
                    class="btn-check-filter"
                    :checked="checkedTabellaSedi.includes(sede.sede)"
                    @change="toggleCheckbox('sedi', sede.sede)"
                    :disabled="isEditing"
                  />
                  <label class="btn btn-filter" :for="'filter_sede' + sede.sede">{{
                    sede.sede
                  }}</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Sort -->
      <ul class="unstyled nav-parent-item" :class="{ 'element-disabled': isEditing }">
        <li v-for="item in sortOrderList" :key="item.text" @click="setOrder(item.value)">
          <a href="#" :class="{ active: currentSortType === item.value }">{{
            item.text
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import sortOrderList from "@/data/sort_order_list.json";
import EventBus from "@/eventBus"; // Importa l'EventBus
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {},
  data() {
    return {
      tabellaProfili: [],
      tabellaAziende: [],
      tabellaSedi: [],
      checkedTabellaProfili: [],
      checkedTabellaAziende: [],
      checkedTabellaSedi: [],
      sortOrderList,
      userAuthenticated: null,
      isElementDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      "getTeamsSortType",
      "getFilterTeamsProfili",
      "getFilterTeamsAziende",
      "getFilterTeamsSedi",
    ]),
    currentSortType() {
      return this.getTeamsSortType;
    },
    isEditing() {
      return (
        this.$route.name === "route-teams-add" || this.$route.name === "route-teams-edit"
      );
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        // Sincronizza i filtri dei checkbox con i nuovi parametri della query
        console.log("** team list sidebar watch $route.query", newQuery);
        this.syncFiltersWithQueryParams(newQuery);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "setTeamsSortType",
      "setCurrentPageTeams",
      "setFilterTeamsProfili",
      "setFilterTeamsAziende",
      "setFilterTeamsSedi",
      "setSearchTeams",
    ]),
    //
    // ** Gestione Filtri **
    //
    syncFiltersWithQueryParams(queryParams = this.$route.query) {
      this.checkedTabellaProfili = queryParams.profili
        ? queryParams.profili.split(",")
        : [];
      this.checkedTabellaAziende = queryParams.aziende
        ? queryParams.aziende.split(",")
        : [];
      this.checkedTabellaSedi = queryParams.sedi ? queryParams.sedi.split(",") : [];
    },
    clearAllAttributesFilters() {
      this.setCurrentPageTeams(1);
      this.checkedTabellaProfili = [];
      this.checkedTabellaAziende = [];
      this.checkedTabellaSedi = [];

      this.setSearchTeams("");

      this.$router.push({
        name: "route-teams",
        query: this.mixins_initTeamsQueryFilters(), // Resetta i query params per rimuovere i filtri
      });

      // if (this.$route.name === "route-teams") {
      //   this.updateQueryParams();
      // } else {
      //   this.$router.push({
      //     name: "route-teams",
      //     query: {
      //       profili: "",
      //       aziende: "",
      //       sedi: "",
      //       mercato: "",
      //       tipologia: "",
      //       tiposkill: "",
      //       livello: "",
      //       labels: [],
      //     }, // Resetta i query params per rimuovere i filtri
      //   });
      // }
    },
    onChangeFilterAttributes(attribute) {
      if (attribute === "profili") {
        this.setFilterTeamsProfili(this.checkedTabellaProfili);
      } else if (attribute === "aziende") {
        this.setFilterTeamsAziende(this.checkedTabellaAziende);
      } else if (attribute === "sedi") {
        this.setFilterTeamsSedi(this.checkedTabellaSedi);
      }

      this.setCurrentPageTeams(1);
      this.updateQueryParams();
    },
    collectFiltersData() {
      return {
        profili: this.checkedTabellaProfili.join(","),
        aziende: this.checkedTabellaAziende.join(","),
        sedi: this.checkedTabellaSedi.join(","),
        labels: [
          ...this.tabellaProfili
            .filter((p) => this.checkedTabellaProfili.includes(p.id))
            .map((p) => p.nome),
          ...this.tabellaAziende
            .filter((a) => this.checkedTabellaAziende.includes(a._id))
            .map((a) => a.ragionesociale),
          ...this.tabellaSedi
            .filter((s) => this.checkedTabellaSedi.includes(s.sede))
            .map((s) => s.sede),
        ],
      };
    },
    updateQueryParams() {
      const filtersData = this.collectFiltersData();
      this.$router.push({ query: { ...this.$route.query, ...filtersData } });
    },
    toggleCheckbox(type, id) {
      const targetArray = this[
        `checkedTabella${type.charAt(0).toUpperCase() + type.slice(1)}`
      ];

      if (targetArray.includes(id)) {
        const index = targetArray.indexOf(id);
        if (index > -1) {
          targetArray.splice(index, 1);
        }
      } else {
        targetArray.push(id);
      }

      this.onChangeFilterAttributes(type);
    },
    //
    // ** Gestione Sort **
    //
    setOrder(value) {
      this.setTeamsSortType(value);
      this.setCurrentPageTeams(1);
      this.updateQueryParams();
    },
    onChangeViewAssenze(event) {
      if (event) {
        this.isElementDisabled = true;
      } else {
        this.isElementDisabled = false;
      }
    },
    onChangeViewRapportini(event) {
      if (event) {
        this.isElementDisabled = true;
      } else {
        this.isElementDisabled = false;
      }
    },
  },
  async created() {
    try {
      this.tabellaProfili = await this.mixins_getTabProfili();
      this.tabellaProfili.sort((a, b) => a.nome.localeCompare(b.nome));
      console.log("** tab per filtro profili", this.tabellaProfili);

      const datiAziende = await this.mixins_getTabAziende();
      this.tabellaAziende = JSON.parse(JSON.stringify(datiAziende));
      console.log("** tab per filtro aziende", this.tabellaAziende);

      this.tabellaSedi = this.mixins_estraiSediUniche(datiAziende);
      this.tabellaSedi.sort((a, b) => a.sede.localeCompare(b.sede));
      console.log("** tab per filtro sedi", this.tabellaSedi);
    } catch (err) {
      console.error("Si è verificato un errore", err);
    }
  },
  mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Ascolta l'evento changeViewAssenze
    EventBus.on("changeViewAssenze", this.onChangeViewAssenze);
    // Ascolta l'evento changeViewRapportini
    EventBus.on("changeViewRapportini", this.onChangeViewRapportini);

    this.syncFiltersWithQueryParams();
  },
};
</script>
