<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>MODIFICA DOCUMENTO</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <!-- Dialog Content -->
        <div class="container-fluid p-0 m-0">
          <!-- Content -->
          <div class="container-fluid px-0 py-3">
            <div class="row mb-3">
              <label
                for="inputDescrizione"
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>DESCRIZIONE</span>
              </label>
              <div class="align-items-center col-lg-10 d-flex justify-content-center">
                <textarea
                  rows="3"
                  type="text"
                  v-input-textarea
                  class="form-control"
                  v-model.trim="localData.descrizione"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="DESCRIZIONE DOCUMENTO"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label
                for="inputTipoDocumento"
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>TIPO DOCUMENTO</span>
              </label>
              <div class="align-items-center col-lg-10 d-flex justify-content-center">
                <MultiSelect
                  v-model="localData.tipo"
                  :options="tipodocumentoOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'above'"
                  :maxHeight="210"
                  tagPlaceholder="Premi enter per creare un tag"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                  @select="onTipodocSelect"
                  @remove="onTipodocRemove"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <!-- Errors model -->
          <div v-if="!errorsModel" class="mb-4">
            <div
              v-for="(errorModel, key) in errorsModel"
              :key="key"
              class="text-danger small mt-1"
            >
              {{ errorModel }}
            </div>
          </div>
          <!-- <div v-if="Object.keys(errorsModel).length > 0" class="mb-4">
            <div
              v-for="(errorModel, field) in errorsModel"
              :key="field"
              class="text-danger small mt-1"
            >
              ddd
              {{ errorModel }}
            </div>
          </div> -->
        </div>
        <!-- Buttons Cancel & Ok -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span><i class="zmdi zmdi-floppy"></i>Aggiorna i dati</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { DocumentoSchema, Documento } from "@/models/TeamJoiModel";
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/css/vue-multiselect.css";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveDocumento"],
  data() {
    return {
      localData: new Documento(), // Inizializzo localData come una nuova istanza di Documento
      errorsModel: {}, // Oggetto per gestire gli errori di validazione
      tipodocumentoOptions: [], // Array delle opzioni per il tipo di documento
    };
  },
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData` come una nuova istanza di Documento o come una copia dell'oggetto `dataItem`
        this.localData = new Documento(this.dataItem);
        this.errorsModel = {};
      }

      this.localData.tipo = this.convertToObject(this.localData.tipo);
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal"); // Emetto l'evento per chiudere il modal
    },
    saveData() {
      // Salvo i valori originali prima della conversione per ripristinarli in caso di errore
      const originalTipodocumento = this.localData.tipo;
      // Converto `tipo` da oggetto a stringa per la validazione
      this.localData.tipo = this.convertToValue(this.localData.tipo);

      // // Validazione dei dati con lo schema Joi
      const { error } = DocumentoSchema.validate(this.localData, { abortEarly: false });

      if (error) {
        console.error("** localData error", error);
        // Se ci sono errori di validazione, li gestisco e ripristino i valori originali
        this.errorsModel = error.details.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        this.localData.tipo = originalTipodocumento;
      } else {
        // Se la validazione è corretta, pulisco gli errori e salvo i dati
        this.errorsModel = {};
        this.$emit("saveDocumento", this.localData, this.index); // Emetto l'evento di salvataggio
      }
    },
    convertToObject(value) {
      // Converto un valore stringa in un oggetto `{ value, label }`
      return value ? { value, label: value } : null;
    },
    convertToValue(obj) {
      // Estraggo la stringa `value` dall'oggetto `{ value, label }`
      return obj && typeof obj === "object" ? obj.value : obj;
    },
    onTipodocSelect(selected) {
      this.localData.tipo = selected;
    },
    onTipodocRemove() {
      this.localData.tipo = "";
    },
    onCittaRemove() {
      // Quando il comune viene rimosso, lo resetto
      this.localData.citta = "";
    },
  },
  async mounted() {
    // Carico le opzioni del tipo di documento
    const tabTipoDoc = await this.mixins_getTabTipoDocumenti();
    this.tipodocumentoOptions = tabTipoDoc.map((item) => ({
      value: item.nome,
      label: item.nome,
    }));
    console.log("** this.tipodocumentoOptions:", this.tipodocumentoOptions);
  },
};
</script>
