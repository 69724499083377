<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <div class="row m-0 pt-4">
    <!-- Image Picture -->
    <div class="col-lg-3 mb-3 p-0 text-center">
      <div class="profile-picture-container">
        <img
          :src="
            getProfilePicture
              ? getProfilePicture
              : require('@/assets/images/user-placeholder.jpg')
          "
          @error="$event.target.src = require('@/assets/images/user-placeholder.jpg')"
          class="profile-picture"
        />
      </div>
      <div v-if="isMe" class="mt-3">
        <button
          type="button"
          class="btn icon-button me-2"
          alt="CARICA IMMAGINE"
          title="CARICA IMMAGINE"
          @click="uploadImage"
          :disabled="!isEditMode"
        >
          <i class="bi bi-image"></i>
        </button>
        <button
          type="button"
          class="btn icon-button me-2"
          alt="RIMUOVI IMMAGINE"
          title="RIMUOVI IMMAGINE"
          @click="removeImage"
          :disabled="getProfilePicture === ''"
        >
          <i class="bi bi-trash"></i>
        </button>
        <input
          type="file"
          ref="fileInput"
          @change="onFileChange"
          accept="image/*"
          style="display: none"
        />
      </div>
    </div>

    <!-- Details Anagrafica -->
    <div class="col-lg-9 p-0 ps-lg-4">
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>NOME</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.nome"
            @input="onInputChange($event, 'nome')"
            @paste="onPaste($event, 'nome')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="NOME"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>COGNOME</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.cognome"
            @input="onInputChange($event, 'cognome')"
            @paste="onPaste($event, 'cognome')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="COGNOME"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>DATA DI NASCITA</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <VueDatePicker
            v-model="selectedLocalModels.datanascita"
            dark
            locale="it"
            format="dd/MM/yyyy"
            cancelText="Annulla"
            selectText="Ok"
            :enable-time-picker="false"
            @update:modelValue="onDataNascitaUpdate"
            @cleared="onDataNascitaClear"
            placeholder="DATA DI NASCITA"
          >
            <template #input-icon>
              <i class="input-slot-image2 zmdi zmdi-calendar"></i>
            </template>
            <template #clear-icon="{ clear }">
              <i @click="clear" class="input-slot-image zmdi zmdi-close zmdi-hc-lg"></i>
            </template>
          </VueDatePicker>
          <button
            class="timepicker-button"
            alt="OGGI"
            title="OGGI"
            @click="setToday('datanascita')"
          >
            <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
          </button>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>NAZIONE DI NASCITA</span>
        </label>
        <div class="align-items-center col-lg-3 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.nazionenascita"
            :options="nazioniOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="nome"
            track-by="nome"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onNazioneNascitaUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>

        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneNascitaItalia == 2 || isNazioneNascitaItalia == 0,
          }"
          ><span>LUOGO DI NASCITA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneNascitaItalia == 2 || isNazioneNascitaItalia == 0,
          }"
          class="align-items-center col-lg-5 d-flex justify-content-center"
        >
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.luogonascita"
            @input="onInputChange($event, 'luogonascita')"
            @paste="onPaste($event, 'luogonascita')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="LUOGO DI NASCITA"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneNascitaItalia == 1 || isNazioneNascitaItalia == 0,
          }"
          ><span>PROV. DI NASCITA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneNascitaItalia == 1 || isNazioneNascitaItalia == 0,
          }"
          class="align-items-center col-lg-2 d-flex justify-content-center"
        >
          <MultiSelect
            v-model="selectedLocalModels.provnascita"
            :options="provinceOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @select="onProvinciaNascitaSelect"
            @remove="onProvinciaNascitaRemove"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneNascitaItalia == 1 || isNazioneNascitaItalia == 0,
          }"
          ><span>LUOGO DI NASCITA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneNascitaItalia == 1 || isNazioneNascitaItalia == 0,
          }"
          class="align-items-center col-lg-6 d-flex justify-content-center"
        >
          <MultiSelect
            v-model="selectedLocalModels.luogonascita"
            :options="comuniOptionsNascita"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @select="onLuogoNascitaSelect"
            @remove="onLuogoNascitaRemove"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>CODICE FISCALE</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.codicefiscale"
            @input="onInputChange($event, 'codicefiscale')"
            @paste="onPaste($event, 'codicefiscale')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="CODICE FISCALE"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>SESSO</span>
        </label>
        <div class="align-items-center col-lg-2 d-flex justify-content-center">
          <MultiSelect
            :searchable="false"
            v-model="selectedLocalModels.sesso"
            :options="sessoOptions"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :openDirection="'below'"
            :showLabels="false"
            @update:modelValue="onSessoUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>STATO CIVILE</span>
        </label>
        <div class="align-items-center col-lg-6 d-flex justify-content-center">
          <MultiSelect
            :searchable="false"
            v-model="selectedLocalModels.statocivile"
            :options="statocivileOptions"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :openDirection="'below'"
            :showLabels="false"
            @update:modelValue="onStatocivileUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>TITOLO DI STUDIO</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.titolostudio"
            :options="titolostudioOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onTitolostudioUpdate"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.titolostudio &&
                  selectedLocalModels.titolostudio.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="clearTitolostudio"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>NAZIONE DI RESIDENZA</span>
        </label>
        <div class="align-items-center col-lg-3 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.nazioneresidenza"
            :options="nazioniOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="nome"
            track-by="nome"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onNazioneResidenzaUpdate"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneResidenzaItalia == 2 || isNazioneResidenzaItalia == 0,
          }"
          ><span>COMUNE DI RESIDENZA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneResidenzaItalia == 2 || isNazioneResidenzaItalia == 0,
          }"
          class="align-items-center col-lg-5 d-flex justify-content-center"
        >
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.cittaresidenza"
            @input="onInputChange($event, 'cittaresidenza')"
            @paste="onPaste($event, 'cittaresidenza')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="COMUNE DI RESIDENZA"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneResidenzaItalia == 1 || isNazioneResidenzaItalia == 0,
          }"
        >
          <span>PROV. DI RESIDENZA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneResidenzaItalia == 1 || isNazioneResidenzaItalia == 0,
          }"
          class="align-items-center col-lg-2 d-flex justify-content-center"
        >
          <MultiSelect
            v-model="selectedLocalModels.provresidenza"
            :options="provinceOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @select="onProvinciaResidenzaSelect"
            @remove="onProvinciaResidenzaRemove"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          :class="{
            'd-none': isNazioneResidenzaItalia == 1 || isNazioneResidenzaItalia == 0,
          }"
        >
          <span>COMUNE DI RESIDENZA</span>
        </label>
        <div
          :class="{
            'd-none': isNazioneResidenzaItalia == 1 || isNazioneResidenzaItalia == 0,
          }"
          class="align-items-center col-lg-6 d-flex justify-content-center"
        >
          <MultiSelect
            v-model="selectedLocalModels.cittaresidenza"
            :options="comuniOptionsResidenza"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @select="onLuogoResidenzaSelect"
            @remove="onLuogoResidenzaRemove"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>VIA/PIAZZA</span>
        </label>
        <div class="align-items-center col-lg-7 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.via"
            @input="onInputChange($event, 'via')"
            @paste="onPaste($event, 'via')"
            v-input-textarea
            :disabled="isCittaResidenzaEmpty"
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="VIA/PIAZZA"
          />
        </div>
        <label
          class="col-form-label form-label col-lg-1 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>CAP</span>
        </label>
        <div class="align-items-center col-lg-2 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.cap"
            @input="onInputChange($event, 'cap')"
            @paste="onPaste($event, 'cap')"
            v-input-textarea
            :disabled="isCittaResidenzaEmpty"
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="CAP"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>TELEFONO FISSO</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.telefonofisso"
            @input="onInputChange($event, 'telefonofisso')"
            @paste="onPaste($event, 'telefonofisso')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="TELEFONO FISSO"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>CELLULARE</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.telefonocellulare"
            @input="onInputChange($event, 'telefonocellulare')"
            @paste="onPaste($event, 'telefonocellulare')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="CELLULARE"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>EMAIL AZIENDALE</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.emailaziendale"
            @input="onInputChange($event, 'emailaziendale')"
            @paste="onPaste($event, 'emailaziendale')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="EMAIL AZIENDALE"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>EMAIL PERSONALE</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localAnagrafica.emailpersonale"
            @input="onInputChange($event, 'emailpersonale')"
            @paste="onPaste($event, 'emailpersonale')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="EMAIL PERSONALE"
          />
        </div>
      </div>
      <!-- Errors model -->
      <div v-if="errorsModel" class="mb-4">
        <div
          v-for="(errorModel, key) in errorsModel"
          :key="key"
          class="text-danger small mt-1"
        >
          {{ errorModel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Anagrafica, AnagraficaSchema } from "@/models/TeamJoiModel";
import {
  fetchUserAnagrafica,
  uploadPictureProfile,
  deleteProfilePicture,
} from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { formatISO, parseISO } from "date-fns";
import "date-fns/locale/it";
import EventBus from "@/eventBus"; // Importa l'EventBus

export default {
  mixins: [UtilityMixins],
  components: {
    Loading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    userDataAnagrafica: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:userDataAnagrafica",
  ],
  data() {
    return {
      localAnagrafica: new Anagrafica(), // Creo un oggetto per gestire i dati locali
      initialAnagrafica: null, // Memorizzo lo stato iniziale per il confronto
      isLoading: false, // Gestione del loader per indicare il caricamento
      errorsModel: null, // Oggetto per memorizzare gli errori di validazione
      nazioniOptions: [], // Opzioni per il multiselect delle nazioni
      provinceOptions: [], // Array delle opzioni per le province
      provinceComuni: [], // Dati completi di province e comuni
      comuniOptionsNascita: [], // Array delle opzioni per i comuni di nascita
      comuniOptionsResidenza: [], // Array delle opzioni per i comuni di residenza
      sessoOptions: [
        // Array delle opzioni per il sesso
        { value: "M", label: "Maschile" },
        { value: "F", label: "Femminile" },
      ],
      statocivileOptions: [
        // Array delle opzioni per lo statocivile
        { value: "1", label: "Celibe/Nubile" },
        { value: "2", label: "Coniugato/a" },
        { value: "3", label: "Vedovo/a" },
        { value: "4", label: "Divorziato/a" },
      ],
      titolostudioOptions: [], // Array delle opzioni per il titolostudio
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        datanascita: null,
        provnascita: [],
        nazionenascita: [],
        luogonascita: [],
        nazioneresidenza: [],
        provresidenza: [],
        cittaresidenza: [],
        sesso: [],
        statocivile: [],
        titolostudio: [],
      },
      paramsId: this.$route.params.id || null,
      userAuthenticated: null,
      profilePicture: "",
      // gestione atterraggio su Tab Attività
      dataLoaded: false,
      targetTab: null, // Tab target specificato
    };
  },
  computed: {
    getProfilePicture() {
      console.log("** dati picture getProfilePicture", this.profilePicture);
      return this.profilePicture;
    },
    // Verifico se la nazione di nascita è Italia
    isNazioneNascitaItalia() {
      if (
        !this.selectedLocalModels.nazionenascita ||
        !this.selectedLocalModels.nazionenascita.nome
      ) {
        // Se nazionenascita non è selezionata o non ha un nome, ritorna 0
        return 0;
      } else if (this.selectedLocalModels.nazionenascita.nome !== "Italia") {
        // Se nazionenascita ha un nome diverso da "Italia", ritorna 1
        return 1;
      } else if (this.selectedLocalModels.nazionenascita.nome === "Italia") {
        // Se nazionenascita ha un nome uguale a "Italia", ritorna 2
        return 2;
      }
      return 0;
    },
    // Verifico se la nazione di residenza è Italia
    isNazioneResidenzaItalia() {
      if (
        !this.selectedLocalModels.nazioneresidenza ||
        !this.selectedLocalModels.nazioneresidenza.nome
      ) {
        // Se nazioneresidenza non è selezionata o non ha un nome, ritorna 0
        return 0;
      } else if (this.selectedLocalModels.nazioneresidenza.nome !== "Italia") {
        // Se nazioneresidenza ha un nome diverso da "Italia", ritorna 1
        return 1;
      } else if (this.selectedLocalModels.nazioneresidenza.nome === "Italia") {
        // Se nazioneresidenza ha un nome uguale a "Italia", ritorna 2
        return 2;
      }
      return 0;
    },
    isCittaResidenzaEmpty() {
      return (
        !this.localAnagrafica.cittaresidenza &&
        (!this.selectedLocalModels.cittaresidenza ||
          !this.selectedLocalModels.cittaresidenza.value)
      );
    },
    isMe() {
      return this.userAuthenticated?.providerUserId == this.paramsId;
    },
  },
  watch: {
    // Osservo il cambiamento di userDataAnagrafica per aggiornare i dati locali
    userDataAnagrafica: {
      immediate: true, // Eseguo l'handler al montaggio
      handler(newVal) {
        // Aggiorno localAnagrafica e i modelli locali al cambio dei dati
        this.localAnagrafica = new Anagrafica(newVal);
        this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
      },
    },
    "localAnagrafica.cittaresidenza"() {
      this.checkAndResetViaCap();
    },
    "selectedLocalModels.cittaresidenza"() {
      this.checkAndResetViaCap();
    },
  },
  methods: {
    //
    // ** Gestione immagine profilo
    //
    async uploadImage() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.isLoading = true;
        try {
          const response = await uploadPictureProfile(file);
          console.log("** dati profile picture response", response);
          const parsed = this.convertToObj(response);
          console.log("** dati profile picture upload", parsed);
          if (parsed && parsed.url) {
            this.profilePicture = parsed.url;
            console.log("** dati profile picture upload", this.profilePicture);
          }
        } catch (error) {
          console.error("Errore durante il caricamento dell'immagine", error);
          this.mixins_showMessage(
            "Immagine del profilo",
            "Errore durante il caricamento dell'immagine",
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
    async removeImage() {
      if (!this.profilePicture) return;
      this.isLoading = true;
      try {
        const response = await deleteProfilePicture();
        if (response) {
          this.profilePicture = "";
          console.log("** dati profile picture ='' remove", this.profilePicture);
        }
      } catch (error) {
        console.error("Errore durante la cancellazione dell'immagine", error);
        this.mixins_showMessage(
          "Immagine del profilo",
          "Errore durante la cancellazione dell'immagine",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    convertToObj(data) {
      // Se è una stringa
      if (typeof data === "string") {
        // Rimuovo eventuali spazi e parentesi graffe dalla stringa
        const cleanString = data.trim().replace(/{|}/g, "");

        // Trovo l'indice del primo ":" per separare la chiave dal valore
        const indexOfColon = cleanString.indexOf(":");
        const key = cleanString.substring(0, indexOfColon).trim();
        const value = cleanString.substring(indexOfColon + 1).trim();

        // Ritorno un oggetto con la chiave e il valore corretti
        return {
          [key]: value,
        };
      }

      // Se è già un oggetto, ritorno direttamente l'oggetto
      if (typeof data === "object" && data !== null) {
        return data;
      }

      // Se non è né una stringa né un oggetto valido, ritorno null o un oggetto vuoto
      return null;
    },
    //
    // ** Fetch Data **
    //
    async fetchUserData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchUserAnagrafica(this.$route.params.id);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto,  non visualizzo la scheda
          if (this.mixins_isEmptyObject(response)) {
            // this.$parent.errorMessage = "L'elemento 'anagrafica' non esiste";
            // return;
            this.localAnagrafica = new Anagrafica();
            this.profilePicture = "";
            console.log("** dati profile picture ='' fetchUserData", this.profilePicture);
          } else {
            this.localAnagrafica = new Anagrafica(response.anagrafica);
            this.profilePicture = response.anagrafica?.picture ?? "";
            console.log("** dati profile picture fetchUserData", this.profilePicture);
          }

          // Aggiorno i dati locali con la risposta
          this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
          console.log("** dati localAnagrafica", this.localAnagrafica);
          console.log("** dati initialAnagrafica", this.initialAnagrafica);

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:userDataAnagrafica", this.localAnagrafica);
          this.$emit("fetch-data", "anagrafica");
          this.$parent.errorMessage = "";
        } else {
          this.$parent.errorMessage = "L'elemento 'anagrafica' non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.$parent.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati dell'anagrafica solo se isEditMode
        await this.fetchUserData();
      } else {
        // Altrimenti genero un nuovo utente in locale
        this.localAnagrafica = new Anagrafica();
        this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
        console.log("** dati localAnagrafica", this.localAnagrafica);
        console.log("** dati initialAnagrafica", this.initialAnagrafica);
        this.profilePicture = "";

        console.log(
          "** dati profile picture ='' fetchDataAndUpdateFields",
          this.profilePicture
        );

        this.validateImmediate();
        this.updateParent();

        // Emetto eventi per aggiornare il v-model e segnalare il nuovo utente creato nel model
        this.$emit("update:userDataAnagrafica", this.localAnagrafica);
        this.$emit("fetch-data", "anagrafica");
        this.$parent.errorMessage = "";
      }

      this.$nextTick(() => {
        this.isLoading = true;
      });

      // Carico le opzioni delle province e aggiorno l'elenco delle opzioni di provinceOptions
      this.provinceComuni = await this.mixins_fetchProvinceComuni();
      this.provinceOptions = this.provinceComuni.map((item) => ({
        value: item.sigla,
        label: `${item.provincia} ${item.sigla}`,
      }));
      console.log("** this.provinceOptions:", this.provinceOptions);

      // Carico le opzioni delle nazioni
      const nazioni = await this.mixins_fetchNazioni();
      this.nazioniOptions = nazioni.nazioni;
      console.log("** this.nazioniOptions", this.nazioniOptions);

      // Carico le opzioni per i titoli di studio
      const titoliDiStudio = await this.mixins_getTabSkillsTypeTitoloDiStudio();
      this.titolostudioOptions = titoliDiStudio.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));

      // Sincronizzo i modelli locali
      this.updateLocalModels();

      this.$nextTick(() => {
        this.isLoading = false;
      });
    },
    //
    // ** Gestione aggiornamenti **
    //
    onInputChange() {
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    onPaste(event, field) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.Clipboard).getData("text");
      const trimmedText = pastedText.trim();
      this.localAnagrafica[field] = trimmedText;
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    updateParent() {
      if (
        JSON.stringify(this.localAnagrafica) !== JSON.stringify(this.initialAnagrafica)
      ) {
        this.markAsModified("anagrafica");

        if (this.validateImmediate()) {
          this.$emit("update:userDataAnagrafica", this.localAnagrafica);
          this.$emit("data-updated", this.localAnagrafica);
        }
      }
    },
    //
    // ** Gestione delle date **
    //
    onDataNascitaUpdate(newDate) {
      this.localAnagrafica.datanascita = newDate ? formatISO(newDate) : null;
      this.validateImmediate();
      this.updateParent();
    },
    onDataNascitaClear() {
      this.localAnagrafica.datanascita = null;
      this.selectedLocalModels.datanascita = null;
      this.validateImmediate();
      this.updateParent();
    },
    setToday(fieldName) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this.selectedLocalModels[fieldName] = today;
      this.localAnagrafica[fieldName] = formatISO(today);

      // Mappo i campi ai rispettivi metodi di aggiornamento
      const updateMethods = {
        datanascita: this.onDataNascitaUpdate,
      };

      // Invoco il metodo corretto in modo dinamico
      if (updateMethods[fieldName]) {
        updateMethods[fieldName](today);
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // ** Gestione Multiselect **
    //
    onMultiselectUpdate(newValue, field) {
      this.localAnagrafica[field] = newValue?.nome || "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Sesso
    //
    onSessoUpdate(newValue) {
      this.localAnagrafica.sesso = newValue?.value || "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Stato civile
    //
    onStatocivileUpdate(newValue) {
      this.localAnagrafica.statocivile = newValue?.value || "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Titolo di studio
    //
    onTitolostudioUpdate(newValue) {
      // Controlla se newValue è un array e lo mappa per estrarre solo i valori
      if (Array.isArray(newValue)) {
        this.localAnagrafica.titolostudio = newValue.map((item) => item.value);
      } else {
        // Se non è un array, imposta un array vuoto
        this.localAnagrafica.titolostudio = [];
      }

      this.validateImmediate();
      this.updateParent();
    },
    clearTitolostudio() {
      this.localAnagrafica.titolostudio = [];
      this.selectedLocalModels.titolostudio = [];
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Nascita, provnascita e luogonascita
    //
    onNazioneNascitaUpdate(newValue) {
      this.localAnagrafica.nazionenascita = newValue?.nome || "";
      this.comuniOptionsNascita = [];
      this.selectedLocalModels.provnascita = [];
      this.selectedLocalModels.luogonascita = [];
      this.localAnagrafica.provnascita = "";
      this.localAnagrafica.luogonascita = "";
      this.validateImmediate();
      this.updateParent();
    },
    onProvinciaNascitaSelect(selected) {
      const provincia = this.provinceComuni.find((item) => item.sigla === selected.value);

      this.comuniOptionsNascita = provincia
        ? provincia.comuni.map((comune) => ({
            value: comune,
            label: comune,
          }))
        : [];

      if (this.localAnagrafica.provnascita !== selected.value) {
        this.localAnagrafica.luogonascita = "";
        this.selectedLocalModels.luogonascita = null;
      }

      this.localAnagrafica.provnascita = selected.value;
      this.validateImmediate();
      this.updateParent();
    },
    onProvinciaNascitaRemove() {
      this.comuniOptionsNascita = [];
      this.selectedLocalModels.luogonascita = [];
      this.localAnagrafica.provnascita = "";
      this.localAnagrafica.luogonascita = "";
      this.validateImmediate();
      this.updateParent();
    },
    onLuogoNascitaSelect(selected) {
      this.localAnagrafica.luogonascita = selected.value;
      this.validateImmediate();
      this.updateParent();
    },

    onLuogoNascitaRemove() {
      this.localAnagrafica.luogonascita = "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Residenza, provresidenza e cittaresidenza
    //
    onNazioneResidenzaUpdate(newValue) {
      this.localAnagrafica.nazioneresidenza = newValue?.nome || "";
      this.comuniOptionsResidenza = [];
      this.selectedLocalModels.provresidenza = [];
      this.selectedLocalModels.cittaresidenza = [];
      this.localAnagrafica.provresidenza = "";
      this.localAnagrafica.cittaresidenza = "";
      this.localAnagrafica.via = "";
      this.localAnagrafica.cap = "";
      this.validateImmediate();
      this.updateParent();
    },
    onProvinciaResidenzaSelect(selected) {
      const provincia = this.provinceComuni.find((item) => item.sigla === selected.value);

      this.comuniOptionsResidenza = provincia
        ? provincia.comuni.map((comune) => ({
            value: comune,
            label: comune,
          }))
        : [];

      if (this.localAnagrafica.provresidenza !== selected.value) {
        this.localAnagrafica.cittaresidenza = "";
        this.selectedLocalModels.cittaresidenza = null;
      }

      this.localAnagrafica.provresidenza = selected.value;
      this.validateImmediate();
      this.updateParent();
    },
    onProvinciaResidenzaRemove() {
      this.comuniOptionsResidenza = [];
      this.selectedLocalModels.cittaresidenza = [];
      this.validateImmediate();
      this.updateParent();
    },
    onLuogoResidenzaSelect(selected) {
      this.localAnagrafica.cittaresidenza = selected.value;
      this.validateImmediate();
      this.updateParent();
    },
    onLuogoResidenzaRemove() {
      this.localAnagrafica.cittaresidenza = "";
      this.validateImmediate();
      this.updateParent();
    },
    updateLocalModels() {
      // Sincronizzo i dati ricevuti

      // datanascita
      this.selectedLocalModels.datanascita = this.localAnagrafica.datanascita
        ? parseISO(this.localAnagrafica.datanascita)
        : null;

      // nazionenascita
      this.selectedLocalModels.nazionenascita = this.localAnagrafica.nazionenascita
        ? { nome: this.localAnagrafica.nazionenascita }
        : [];

      // nazioneresidenza
      this.selectedLocalModels.nazioneresidenza = this.localAnagrafica.nazioneresidenza
        ? { nome: this.localAnagrafica.nazioneresidenza }
        : [];

      // sesso
      this.selectedLocalModels.sesso = this.localAnagrafica.sesso
        ? this.sessoOptions.find((item) => item.value === this.localAnagrafica.sesso)
        : [];

      // statocivile
      this.selectedLocalModels.statocivile = this.localAnagrafica.statocivile
        ? this.statocivileOptions.find(
            (item) => item.value === this.localAnagrafica.statocivile
          )
        : [];

      // titolostudio
      // Controllo e mappo titolostudio solo se è un array e contiene elementi
      if (
        Array.isArray(this.localAnagrafica.titolostudio) &&
        this.localAnagrafica.titolostudio.length > 0
      ) {
        // Se l'array contiene solo una stringa vuota, lo reimposto come array vuoto
        if (
          this.localAnagrafica.titolostudio.length === 1 &&
          this.localAnagrafica.titolostudio[0] === ""
        ) {
          this.localAnagrafica.titolostudio = [];
        }

        // Mappo i titoli presenti in `localAnagrafica` con le opzioni corrispondenti
        this.selectedLocalModels.titolostudio = this.localAnagrafica.titolostudio.map(
          (titolo) => {
            // Trovo l'elemento corrispondente in `titolostudioOptions`
            const matchedOption = this.titolostudioOptions.find(
              (option) => option.value === titolo
            );
            // Se trovo una corrispondenza, ritorno l'opzione, altrimenti ritorno un oggetto con valori vuoti
            return matchedOption || { value: titolo, label: titolo };
          }
        );
      } else {
        // Se non ci sono titoli di studio selezionati, imposto un array vuoto
        this.selectedLocalModels.titolostudio = [];
      }

      // Se esiste una provincia di nascita selezionata, popolo l'elenco dei comuni e seleziono il comune
      if (this.localAnagrafica.provnascita) {
        const provinciaNascita = this.provinceOptions.find(
          (item) => item.value === this.localAnagrafica.provnascita
        );
        this.selectedLocalModels.provnascita = provinciaNascita;
        this.onProvinciaNascitaSelect(provinciaNascita);
        if (this.localAnagrafica.luogonascita) {
          this.selectedLocalModels.luogonascita = {
            value: this.localAnagrafica.luogonascita,
            label: this.localAnagrafica.luogonascita,
          };
        }
      }

      // Se esiste una provincia di residenza selezionata, popolo l'elenco dei comuni e seleziono il comune
      if (this.localAnagrafica.provresidenza) {
        const provinciaResidenza = this.provinceOptions.find(
          (item) => item.value === this.localAnagrafica.provresidenza
        );
        this.selectedLocalModels.provresidenza = provinciaResidenza;
        this.onProvinciaResidenzaSelect(provinciaResidenza);
        if (this.localAnagrafica.cittaresidenza) {
          this.selectedLocalModels.cittaresidenza = {
            value: this.localAnagrafica.cittaresidenza,
            label: this.localAnagrafica.cittaresidenza,
          };
        }
      }
    },
    //
    // ** Gestione Validazioni **
    //
    validateImmediate() {
      const result = AnagraficaSchema.validate(this.localAnagrafica, {
        abortEarly: false,
      });
      if (result.error) {
        this.errorsModel = result.error.details.reduce((acc, curr) => {
          acc[curr.path[0]] = curr.message;
          return acc;
        }, {});
        this.markAsVerified("anagrafica", false);
        return false;
      } else {
        this.errorsModel = null;
        this.markAsVerified("anagrafica", true);
        return true;
      }
    },

    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    //
    // Altri metodi
    //
    checkAndResetViaCap() {
      const isLocalEmpty = !this.localAnagrafica.cittaresidenza;
      const isSelectedEmpty =
        !this.selectedLocalModels.cittaresidenza ||
        !this.selectedLocalModels.cittaresidenza.value;

      if (isLocalEmpty && isSelectedEmpty) {
        this.localAnagrafica.via = "";
        this.localAnagrafica.cap = "";
      }
    },
    switchToAttivita() {
      this.activeTab = "contentAttivita";
      if (this.$refs.userAttivita && this.$refs.userAttivita.fetchDataAndUpdateFields) {
        this.$refs.userAttivita.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Metodo fetchDataAndUpdateFields non disponibile per userAttivita."
        );
      }
    },
  },
  async mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Controlla il parametro targetTab nella route
    this.targetTab = this.$route.query.targetTab || null;

    if (this.targetTab === "attivita") {
      await this.fetchDataAndUpdateFields();
      EventBus.emit("switchToAttivita"); // Emette l'evento per cambiare tab
    } else {
      await this.fetchDataAndUpdateFields();
    }
  },
  async created() {
    //******* DEBUG ******//
    //Debug: carico tutte le tipologie di skill raggruppati per tipologie
    // const skillsData = await this.mixins_getTabSkillsData(); // carico gli skills data
    // const groupedSkillsData = await this.mixins_groupSottoclassiByType(skillsData);
    // console.log("** tab skills data raggruppati per tipologia", groupedSkillsData);
  },
};
</script>
