<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <div class="container-fluid m-0 p-0 pt-4">
    <div class="row m-0 mb-4">
      <!-- No data -->
      <div v-if="!hasData">
        <h5>Nessun dato da visualizzare</h5>
      </div>
      <div v-else class="scroll-x-container p-0">
        <table class="table-bordered table-responsive-md table-setup">
          <!-- Intestazione -->
          <thead class="table-header-font">
            <tr class="td-center">
              <th colspan="4">RIEPILOGO</th>
              <th style="min-width: 100px !important; width: 125px !important">DAL</th>
              <th style="min-width: 100px !important; width: 125px !important">AL</th>
              <th style="min-width: 100px !important; width: 125px !important">
                ORDINARIO DIURNO
              </th>
              <th style="min-width: 100px !important; width: 125px !important">
                ORDINARIO FESTIVO
              </th>
              <th style="min-width: 100px !important; width: 125px !important">
                STRAORDINARIO
              </th>
              <th style="min-width: 100px !important; width: 125px !important">
                REPERIBILITÀ
              </th>
            </tr>
          </thead>
          <tbody class="table-body-font td-vertical-center">
            <tr class="riepilogo-row">
              <td colspan="4" class="td-center">TOTALE ATTIVITÀ</td>
              <td class="td-center">
                {{ mixins_getLocalDate(dataRapportinoGrouped.from) }}
              </td>
              <td class="td-center">
                {{ mixins_getLocalDate(dataRapportinoGrouped.from) }}
              </td>
              <td class="td-right">
                {{
                  mixins_formatNumberWithLocale(
                    dataRapportinoGrouped.totale_ordinario_diurno,
                    1
                  )
                }}
              </td>
              <td class="td-right">
                {{
                  mixins_formatNumberWithLocale(
                    dataRapportinoGrouped.totale_ordinario_festivo,
                    1
                  )
                }}
              </td>
              <td class="td-right">
                {{
                  mixins_formatNumberWithLocale(
                    dataRapportinoGrouped.totale_straordinario,
                    1
                  )
                }}
              </td>
              <td class="td-right">
                {{
                  mixins_formatNumberWithLocale(
                    dataRapportinoGrouped.totale_reperibilita,
                    1
                  )
                }}
              </td>
            </tr>
            <!-- Riga di spaziatura -->
            <tr class="spacer-row">
              <td colspan="10"></td>
            </tr>
            <!-- Intestazione RISORSA, SOCIETÀ, PROFILO, RUOLO, DAL, AL, ORDINARIO DIURNO, ORDINARIO FESTIVO, STRAORDINARIO, REPERIBILITÀ -->
            <tr class="td-center">
              <th class="highlighted">RISORSA</th>
              <th class="highlighted">SOCIETÀ</th>
              <th class="highlighted">PROFILO</th>
              <th class="highlighted">RUOLO</th>
              <th class="highlighted">DAL</th>
              <th class="highlighted">AL</th>
              <th class="highlighted">ORDINARIO DIURNO</th>
              <th class="highlighted">ORDINARIO FESTIVO</th>
              <th class="highlighted">STRAORDINARIO</th>
              <th class="highlighted">REPERIBILITÀ</th>
            </tr>
            <tr v-for="risorsa in dataRapportinoGrouped.risorse" :key="risorsa._id">
              <td>{{ risorsa.risorsa }}</td>
              <td>{{ risorsa.societa.descrizione }}</td>
              <td class="td-center">
                <ul class="list-tags">
                  <li v-for="profilo in risorsa.profili" :key="profilo.id">
                    <span
                      class="badge-colored blue"
                      :title="profilo.descrizione"
                      :alt="profilo.descrizione"
                    >
                      {{ profilo.descrizione }}
                    </span>
                  </li>
                </ul>
              </td>
              <td>{{ risorsa.ruolo.descrizione }}</td>
              <td class="td-center">{{ mixins_getLocalDate(risorsa.from) }}</td>
              <td class="td-center">{{ mixins_getLocalDate(risorsa.to, true) }}</td>
              <td class="td-right">
                {{ mixins_formatNumberWithLocale(risorsa.ordinario_diurno, 1) }}
              </td>
              <td class="td-right">
                {{ mixins_formatNumberWithLocale(risorsa.ordinario_festivo, 1) }}
              </td>
              <td class="td-right">
                {{ mixins_formatNumberWithLocale(risorsa.straordinario, 1) }}
              </td>
              <td class="td-right">
                {{ mixins_formatNumberWithLocale(risorsa.reperibilita, 1) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCommessaAttivita } from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { Attivita } from "@/models/CommessaJoiModel";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [UtilityMixins],
  components: {
    Loading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      isLoading: false, // Gestione del loader per indicare il caricamento
      dataRapportino: [], // Array rapportino aggregato proveniente dal server
      dataRapportinoGrouped: {}, // Oggetto rapportino raggruppato per id risorsa e calcolo totali
    };
  },
  computed: {
    hasData() {
      // Controlla se ci sono risorse e se l'array non è vuoto
      return (
        this.dataRapportinoGrouped.risorse &&
        this.dataRapportinoGrouped.risorse.length > 0
      );
    },
  },
  methods: {
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      try {
        const response = await fetchCommessaAttivita(this.$route.params.id);
        if (response) {
          // Memorizzo il rapportino che arriva dal server
          this.dataRapportino = response.map((item) => new Attivita(item));

          console.log("** dati rapportino", this.dataRapportino);
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    aggregateResourceActivities(activities) {
      const resourceMap = {};
      let totalOrdinarioDiurno = 0;
      let totalOrdinarioFestivo = 0;
      let totalStraordinario = 0;
      let totalReperibilita = 0;

      activities.forEach((activity) => {
        const {
          _id,
          risorsa,
          societa,
          profili,
          ruolo,
          from,
          to,
          ordinario_diurno,
          ordinario_festivo,
          straordinario,
          reperibilita,
        } = activity;

        if (!resourceMap[_id]) {
          resourceMap[_id] = {
            _id,
            risorsa,
            societa,
            profili,
            ruolo,
            from,
            to,
            ordinario_diurno: 0,
            ordinario_festivo: 0,
            straordinario: 0,
            reperibilita: 0,
          };
        }

        resourceMap[_id].ordinario_diurno += ordinario_diurno;
        resourceMap[_id].ordinario_festivo += ordinario_festivo;
        resourceMap[_id].straordinario += straordinario;
        resourceMap[_id].reperibilita += reperibilita;

        totalOrdinarioDiurno += ordinario_diurno;
        totalOrdinarioFestivo += ordinario_festivo;
        totalStraordinario += straordinario;
        totalReperibilita += reperibilita;
      });

      const result = {
        risorse: Object.values(resourceMap),
        totale_ordinario_diurno: totalOrdinarioDiurno,
        totale_ordinario_festivo: totalOrdinarioFestivo,
        totale_straordinario: totalStraordinario,
        totale_reperibilita: totalReperibilita,
      };

      return result;
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati dell'attività
        await this.fetchData();
        console.log("** rapportino aggregato", this.dataRapportino);

        // Raggruppo il rapportino per commessa
        if (this.dataRapportino && this.dataRapportino.length > 0) {
          this.dataRapportinoGrouped = this.aggregateResourceActivities(
            this.dataRapportino
          );
        }
        console.log("** rapportino raggruppato per risorsa", this.dataRapportinoGrouped);
      }
    },
  },
};
</script>
