<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <Sidebar />
        <!-- Main content -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/views/menu_team/TeamsSidebar.vue";

export default {
  components: { Sidebar },
  methods: {},
  mounted() {
    console.log("** TeamsView montato con rotta:", this.$route);
  },
  created() {
    console.log("** TeamsView creato");
  },
};
</script>
