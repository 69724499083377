export const inputTextareaDirective = {
    mounted(el) {
        // mounted(el, binding, vnode) {
        // console.log("** input directive:", el, binding, vnode);

        let isHandlingEvent = false;

        const inputHandler = () => {
            if (isHandlingEvent) return; // Evita la ricorsione
            isHandlingEvent = true;
            const event = new Event('input', { bubbles: true });
            el.dispatchEvent(event);
            isHandlingEvent = false;
        };

        const getInputElement = (element) => {
            if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
                return element;
            }
            return element.querySelector('input') || element.querySelector('textarea') || element;
        };

        const inputElement = getInputElement(el);

        // Gestione eventi
        const handleInputChange = () => {
            inputHandler();
        };

        inputElement.addEventListener('paste', handleInputChange);
        inputElement.addEventListener('cut', handleInputChange);
        inputElement.addEventListener('input', handleInputChange);

        // Gestione specifica per il backspace e delete
        inputElement.addEventListener('keyup', (e) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
                handleInputChange();
            }
        });
    }
};
