<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>DESCRIZIONE SEDE</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <input
                  type="text"
                  v-input-textarea
                  class="form-control"
                  id="inputDescrizioneSede"
                  v-model.trim="localData.sede"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="DESCRIZIONE SEDE"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>INDIRIZZO</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <input
                  type="text"
                  v-input-textarea
                  class="form-control"
                  id="inputIndirizzo"
                  v-model.trim="localData.indirizzo"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="INDIRIZZO"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>CAP</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <input
                  type="text"
                  v-input-textarea
                  class="form-control"
                  id="inputCap"
                  v-model.trim="localData.cap"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="CAP"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>PROVINCIA</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <MultiSelect
                  v-model="localData.prov"
                  :options="provinceOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'above'"
                  :maxHeight="210"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                  @select="onProvinciaSelect"
                  @remove="onProvinciaRemove"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>CITTÀ</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <MultiSelect
                  v-model="localData.citta"
                  :options="comuniOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'above'"
                  :maxHeight="210"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <div class="row">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>CODICE FILIALE</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <input
                  type="text"
                  v-input-textarea
                  class="form-control"
                  id="inputCodiceFiliale"
                  v-model.trim="localData.codicefiliale"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="CODICE FILIALE"
                />
              </div>
            </div>
          </div>

          <!-- Errors model -->
          <div v-if="Object.keys(errorsModel).length > 0" class="mb-4">
            <div
              v-for="(errorModel, field) in errorsModel"
              :key="field"
              class="text-danger small mt-1"
            >
              {{ errorModel }}
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span
                ><i class="zmdi zmdi-save"></i
                >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { SedeSchema, Sede } from "@/models/AziendaJoiModel";
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/css/vue-multiselect.css";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveSede"],
  data() {
    return {
      localData: new Sede(), // Inizializzo localData come una nuova istanza di Sede
      errorsModel: {}, // Oggetto per gestire gli errori di validazione
      provinceOptions: [], // Array delle opzioni per le province
      provinceComuni: [], // Dati completi di province e comuni
      comuniOptions: [], // Array delle opzioni per i comuni basato sulla provincia selezionata
    };
  },
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData` come una nuova istanza di Sede o come una copia dell'oggetto `dataItem`
        this.localData = new Sede(this.dataItem);
        this.errorsModel = {};

        // Gestione sicura delle selezioni di `prov` e `citta`
        if (this.localData.prov || this.localData.citta) {
          this.initializeSelections(); // Chiamo la funzione per gestire le selezioni di provincia e comune
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal"); // Emetto l'evento per chiudere il modal
    },
    saveData() {
      // Salvo i valori originali prima della conversione per ripristinarli in caso di errore
      const originalProv = this.localData.prov;
      const originalCitta = this.localData.citta;

      // Converto `prov` e `citta` da oggetti a stringhe per la validazione
      this.localData.prov = this.convertToValue(this.localData.prov);
      this.localData.citta = this.convertToValue(this.localData.citta);

      console.log("** localData.prov", this.localData.prov);
      console.log("** localData.citta", this.localData.citta);

      // Validazione dei dati con lo schema Joi
      const { error } = SedeSchema.validate(this.localData, { abortEarly: false });

      if (error) {
        // Se ci sono errori di validazione, li gestisco e ripristino i valori originali
        this.errorsModel = error.details.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        this.localData.prov = originalProv;
        this.localData.citta = originalCitta;
      } else {
        // Se la validazione è corretta, pulisco gli errori e salvo i dati
        this.errorsModel = {};

        this.$emit("saveSede", this.localData, this.index); // Emetto l'evento di salvataggio
      }
    },
    updateComuniOptions(sigla) {
      // Aggiorno le opzioni dei comuni basate sulla provincia selezionata
      const selectedProvince = this.provinceComuni.find((item) => item.sigla === sigla);
      this.comuniOptions = selectedProvince
        ? selectedProvince.comuni.map((comune) => this.convertToObject(comune))
        : [];
      console.log("** comuniOptions:", this.comuniOptions);
    },
    initializeSelections() {
      // Inizializzo la selezione della provincia solo se esiste un valore in `localData.prov`
      if (this.localData.prov) {
        this.localData.prov = this.findOption(this.localData.prov, this.provinceOptions);
        this.updateComuniOptions(this.localData.prov.value);
      }

      // Assicuro che il comune venga selezionato correttamente se esiste un valore
      this.selectComuneAfterOptionsLoad();
    },
    selectComuneAfterOptionsLoad(attempt = 0) {
      if (attempt > 5 || !this.localData.prov) return; // Evito troppi tentativi e verifico che `prov` sia valido

      // Utilizzo `$nextTick` per selezionare il comune una volta che il DOM è stato aggiornato
      this.$nextTick(() => {
        if (this.localData.citta) {
          this.localData.citta = this.findOption(
            this.localData.citta,
            this.comuniOptions
          );
        }

        if (!this.localData.citta && this.comuniOptions.length === 0 && attempt < 5) {
          // Se le opzioni non sono ancora pronte, riprovo dopo un breve ritardo
          setTimeout(() => {
            this.selectComuneAfterOptionsLoad(attempt + 1);
          }, 100);
        }
      });
    },
    findOption(value, options) {
      // Trovo l'opzione corrispondente in `options`, o converto `value` in un oggetto `{ value, label }`
      return (
        options.find((option) => option.value === value) || this.convertToObject(value)
      );
    },
    convertToObject(value) {
      // Converto un valore stringa in un oggetto `{ value, label }`
      return value ? { value, label: value } : null;
    },
    convertToValue(obj) {
      // Estraggo la stringa `value` dall'oggetto `{ value, label }`
      return obj && typeof obj === "object" ? obj.value : obj;
    },
    onProvinciaSelect(selected) {
      // Quando viene selezionata una provincia, aggiorno `prov` e resetto `citta`
      this.localData.prov = selected;
      this.localData.citta = null;
      this.updateComuniOptions(selected.value);
    },
    onProvinciaRemove() {
      // Quando la provincia viene rimossa, resetto sia `prov` che `citta`
      this.localData.prov = "";
      this.localData.citta = "";
      this.comuniOptions = [];
    },
    onCittaRemove() {
      // Quando il comune viene rimosso, lo resetto
      this.localData.citta = "";
    },
  },
  async mounted() {
    // Carico le opzioni delle province e aggiorno l'elenco delle opzioni di `provinceOptions`
    this.provinceComuni = await this.mixins_fetchProvinceComuni();
    this.provinceOptions = this.provinceComuni.map((item) => ({
      value: item.sigla,
      label: `${item.provincia} ${item.sigla}`,
    }));
    console.log("** this.provinceOptions:", this.provinceOptions);
  },
};
</script>
