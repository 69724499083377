<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <div class="d-flex justify-content-center m-0 py-5 row">
    <div class="col-lg-6 p-0">
      <form novalidate>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>VECCHIA PASSWORD</span>
          </label>
          <div class="align-items-center col-lg-9 d-flex justify-content-center">
            <div class="input-group">
              <input
                ref="inputVecchiaPassword"
                v-model.trim="localDataSicurezza.oldPassword"
                type="password"
                class="form-control"
                placeholder="VECCHIA PASSWORD"
                required
                spellcheck="false"
                @click.right.prevent
                autocomplete="new-password"
                autocapitalize="off"
                @keydown.space.prevent
                @keydown.enter.prevent
                @input="validateImmediate()"
                tabindex="1"
              />
              <button
                class="input-group-button"
                :alt="eye_old_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                :title="eye_old_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                @keydown.enter.prevent
                @click.prevent="eye_old_psw = toggleVisibility('inputVecchiaPassword')"
              >
                <i
                  :class="
                    eye_old_psw
                      ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                      : 'zmdi zmdi-eye zmdi-hc-lg'
                  "
                ></i>
              </button>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <label
            class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>NUOVA PASSWORD</span>
          </label>
          <div class="align-items-center col-lg-9 d-flex justify-content-center">
            <div class="input-group">
              <input
                ref="inputNuovaPassword"
                v-model.trim="localDataSicurezza.newPassword"
                type="password"
                class="form-control"
                placeholder="NUOVA PASSWORD"
                required
                spellcheck="false"
                @click.right.prevent
                autocomplete="new-password"
                autocapitalize="off"
                @keydown.space.prevent
                @keydown.enter.prevent
                @input="validateImmediate()"
                tabindex="2"
              />
              <button
                class="input-group-button"
                alt="GENERA UNA PASSWORD"
                title="GENERA UNA PASSWORD"
                @keydown.enter.prevent
                @click.prevent="
                  localDataSicurezza.confirmPassword = localDataSicurezza.newPassword = mixins_generaPassword();
                  validateImmediate();
                "
              >
                <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
              </button>
              <button
                class="input-group-button"
                :alt="eye_new_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                :title="eye_new_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                @keydown.enter.prevent
                @click.prevent="eye_new_psw = toggleVisibility('inputNuovaPassword')"
              >
                <i
                  :class="
                    eye_new_psw
                      ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                      : 'zmdi zmdi-eye zmdi-hc-lg'
                  "
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <label
            class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>RIPETI NUOVA PASSORD</span>
          </label>
          <div class="align-items-center col-lg-9 d-flex justify-content-center">
            <div class="input-group">
              <input
                ref="inputRipetiNuovaPassword"
                v-model.trim="localDataSicurezza.confirmPassword"
                type="password"
                class="form-control"
                placeholder="RIPETI NUOVA PASSWORD"
                required
                spellcheck="false"
                @click.right.prevent
                autocomplete="new-password"
                autocapitalize="off"
                @keydown.space.prevent
                @keydown.enter.prevent
                @input="validateImmediate()"
                tabindex="3"
              />
              <button
                class="input-group-button"
                :alt="eye_cnf_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                :title="eye_cnf_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                @keydown.enter.prevent
                @click.prevent="
                  eye_cnf_psw = toggleVisibility('inputRipetiNuovaPassword')
                "
              >
                <i
                  :class="
                    eye_cnf_psw
                      ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                      : 'zmdi zmdi-eye zmdi-hc-lg'
                  "
                ></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex flex-row justify-content-end my-5">
        <button
          @click="azzeraCampi()"
          type="button"
          class="btn button-outline-1 small me-3"
        >
          <span><i class="bi bi-x-lg"></i>Azzera i campi</span>
        </button>
        <button
          @click="cambiaPassword()"
          type="button"
          class="btn button-outline-1 small"
        >
          <span><i class="bi bi-lock-fill"></i>Cambia Password</span>
        </button>
      </div>
      <!-- Messaggi validità password -->
      <div class="row my-5">
        <div v-if="!isOldPasswordValid" class="text-danger small mt-1">
          Il campo "VECCHIA PASSWORD" non può essere vuoto
        </div>
        <div v-if="!isPasswordValid">
          <div
            v-for="(msg, index) in passwordMessages"
            :key="index"
            class="text-danger small mt-1"
          >
            {{ msg }}
          </div>
        </div>
        <div v-if="!isPasswordMatched" class="text-danger small mt-1">
          Le password nei campi "NUOVA PASSWORD" e "RIPETI NUOVA PASSWORD" non coincidono
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { updateUserSicurezza } from "@/services/api";

class SicurezzaChange {
  constructor(data = {}) {
    this.oldPassword = data.oldPassword || "";
    this.newPassword = data.newPassword || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}

export default {
  mixins: [UtilityMixins],
  components: {
    Loading,
  },
  data() {
    return {
      eye_old_psw: false,
      eye_new_psw: false,
      eye_cnf_psw: false,
      isPasswordValid: true,
      isPasswordMatched: true,
      isOldPasswordValid: true,
      passwordMessages: [],
      isLoading: false,
      localDataSicurezza: new SicurezzaChange(),
    };
  },
  methods: {
    toggleVisibility(refId) {
      const input = this.$refs[refId];
      input.type = input.type === "password" ? "text" : "password";
      return input.type === "text";
    },
    resetFieldToPassword(refId) {
      const input = this.$refs[refId];
      input.type = "password";
    },
    validatePassword(password) {
      const result = this.mixins_controllaPasswordMsg(password);
      this.isPasswordValid = result.isValid;
      this.passwordMessages = result.messages;
    },
    validateRepeatPassword() {
      this.isPasswordMatched =
        this.localDataSicurezza.newPassword === this.localDataSicurezza.confirmPassword;
    },
    validateOldPassword() {
      this.isOldPasswordValid = this.localDataSicurezza.oldPassword.trim() !== "";
      return this.isOldPasswordValid;
    },
    validateImmediate() {
      // Eseguo tutte le validazioni
      this.validatePassword(this.localDataSicurezza.newPassword);
      this.validateRepeatPassword();
      this.validateOldPassword();

      // Controlla tutte le condizioni: Nuova password valida, le due password coincidono, Vecchia password non vuota
      if (this.isPasswordValid && this.isPasswordMatched && this.isOldPasswordValid) {
        return true;
      } else {
        return false;
      }
    },
    async cambiaPassword() {
      let isUpdated = false;
      if (this.validateImmediate()) {
        console.log("** Aggiornamento sezione Sicurezza", this.localDataSicurezza);

        this.isLoading = true;

        const data = this.localDataSicurezza;
        const response = await updateUserSicurezza(data);

        if (typeof response == "object") {
          console.log("** response updateUserSicurezza", response);
          if (response.code === 0) {
            this.mixins_showMessage(
              "Sicurezza",
              "Password cambiata correttamente",
              "info"
            );
            isUpdated = true;
            this.azzeraCampi();
          } else {
            if (response.code === 113) {
              this.mixins_showMessage(
                "Sicurezza",
                'Le password nei campi "NUOVA PASSWORD" e "RIPETI NUOVA PASSWORD" non coincidono',
                "warning"
              );
            } else if (response.code === 114) {
              this.mixins_showMessage(
                "Sicurezza",
                'La password nel campo "VECCHIA PASSWORD" è errata',
                "warning"
              );
            } else if (response.code === 116) {
              this.mixins_showMessage(
                "Sicurezza",
                'Le password nei campi "NUOVA PASSWORD" e "RIPETI NUOVA PASSWORD" non rispettano le regole:\n - una lettera maiuscola\n- una lettera minuscola\n- un numero\n- un carattere speciale da !@#$%&*\n- min 8 caratteri e max 12 caratteri',
                "warning"
              );
            } else {
              this.mixins_showMessage(
                "Sicurezza",
                "Si è verificato un errore durante il cambio password",
                "warning"
              );
            }

            isUpdated = false;
          }
        }
        this.isLoading = false;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },
    azzeraCampi() {
      this.localDataSicurezza = new SicurezzaChange();
      this.isPasswordValid = true;
      this.isPasswordMatched = true;
      this.isOldPasswordValid = true;
      this.eye_old_psw = false;
      this.eye_new_psw = false;
      this.eye_cnf_psw = false;
      this.resetFieldToPassword("inputVecchiaPassword");
      this.resetFieldToPassword("inputNuovaPassword");
      this.resetFieldToPassword("inputRipetiNuovaPassword");
    },
  },
};
</script>
