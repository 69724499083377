<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE TEAM<span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-user fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <!-- Main contents -->
        <div v-if="!mixins_isEmptyObject(localDataAnagrafica) && !errorMessage">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="color-white m-0 p-0">
                <span
                  v-html="
                    localDataAnagrafica.nome + ' ' + localDataAnagrafica.cognome || blank
                  "
                ></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>

          <!-- Navigazione dei tab -->
          <div class="row m-0 padding-x-4px">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <!-- Tab Anagrafica -->
              <li class="nav-item">
                <a
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex active"
                  data-bs-toggle="tab"
                  >ANAGRAFICA<span
                    class="ms-2 change-data"
                    v-if="isModified.anagrafica"
                  ></span
                ></a>
              </li>
              <!-- Tab Rapporto -->
              <li class="nav-item">
                <a
                  @click.prevent="fetchRapportoHandler"
                  href="#contentRapporto"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >RAPPORTO<span
                    class="ms-2 change-data"
                    v-if="isModified.rapporto"
                  ></span
                ></a>
              </li>
            </ul>

            <!-- Contenuto dei tab -->
            <div class="tab-content p-0">
              <!-- Content Anagrafica -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentAnagrafica' }"
                id="contentAnagrafica"
              >
                <TabUserAnagrafica
                  ref="userAnagrafica"
                  :isEditMode="isEditMode"
                  @data-updated="handleDataAnagraficaUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataAnagrafica="localDataAnagrafica"
                />
              </div>
              <!-- Content Rapporto -->
              <div class="tab-pane fade" id="contentRapporto">
                <TabUserRapporto
                  ref="userRapporto"
                  :isEditMode="isEditMode"
                  @data-updated="handleDataRapportoUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataRapporto="localDataRapporto"
                />
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                type="button"
                class="btn button-outline-1 float-end"
                :class="{
                  notSaved: isModified.anagrafica || isModified.rapporto,
                }"
                @click="createUser"
              >
                <span><i class="zmdi zmdi-floppy"></i>Salva i dati</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { mapGetters } from "vuex";
import { Anagrafica, Rapporto } from "@/models/TeamJoiModel";
import { addNewUser } from "@/services/api";
import TabUserAnagrafica from "@/views/menu_team/TabUserAnagrafica.vue";
import TabUserRapporto from "@/views/menu_team/TabUserRapporto.vue";
import config from "@/config";

export default {
  mixins: [UtilityMixins],
  components: {
    TabUserAnagrafica,
    TabUserRapporto,
  },
  data() {
    return {
      errorMessage: null,
      isEditMode: false,
      isModified: {
        anagrafica: false,
        rapporto: false,
      },
      isVerified: {
        anagrafica: false,
        rapporto: false,
      },
      headerTitle: "",
      activeTab: "contentAnagrafica",
      // gestione tab data
      localDataAnagrafica: new Anagrafica(),
      localDataRapporto: new Rapporto(),
      blank: `&nbsp;`,
      userAuthenticated: null,
      isFirstTimeRapporto: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageTeams"]),
  },
  methods: {
    //
    // ** Gestione aggiornamenti e validazioni
    //
    //
    // Anagrafica
    //
    handleDataAnagraficaUpdate(updatedData) {
      this.localDataAnagrafica = new Anagrafica(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Anagrafica:",
        this.localDataAnagrafica
      );
    },
    //
    // Rapporto
    //
    handleDataRapportoUpdate(updatedData) {
      this.localDataRapporto = new Rapporto(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Rapporto:",
        this.localDataRapporto
      );
    },
    //
    // Handle per sezioni modificate e verificate
    //
    handleSectionModified(section) {
      this.isModified[section] = true;
      console.log(`** Sezione ${section} modificata`);
    },
    handleSectionVerified(section, value) {
      this.isVerified[section] = value;
      console.log(`** Sezione ${section} verificata`, value);
    },
    //
    // ** Creazione Nuovo Utente
    //
    async createUser() {
      // Array per tracciare le sezioni aggiornate e quelle con errori
      const isUpdated = [];
      const hasErrors = [];

      // Definisco le sezioni e le rispettive funzioni di aggiornamento
      const sections = [
        { label: "ANAGRAFICA", name: "anagrafica", updateFn: this.updateDataAnagrafica },
        { label: "RAPPORTO", name: "rapporto", updateFn: this.updateDataRapporto },
      ];

      // Ciclo attraverso ogni sezione e chiamo la funzione di aggiornamento corrispondente
      for (const section of sections) {
        if (section && section.updateFn) {
          const updated = await section.updateFn.call(this);
          if (updated) {
            isUpdated.push(section.name);
          } else if (this.isModified[section.name]) {
            hasErrors.push(section.label);
          }
        }
      }

      // Se ci sono stati aggiornamenti
      if (isUpdated.length > 0) {
        this.resetSectionModified(isUpdated);
        this.resetSectionVerified(isUpdated);
        console.log(
          `** Le seguenti sezioni sono state aggiornate correttamente: ${isUpdated.join(
            ", "
          )}`
        );
      }

      // Se ci sono errori, mostro un messaggio specifico
      if (hasErrors.length > 0) {
        let msg;
        if (hasErrors.length == 1) {
          msg = "Ci sono dati non validi nella seguente sezione:";
        } else if (hasErrors.length > 1) {
          msg = "Ci sono dati non validi nelle seguenti sezioni:";
        }
        this.mixins_showMessage("Attenzione", `${msg} ${hasErrors.join(", ")}`, "error");
        return false;
      }

      // Preparo l'oggetto newUser per salvarae i dati
      const newUser = {
        username: this.localDataAnagrafica.emailaziendale,
        email: this.localDataAnagrafica.emailaziendale,
        password: this.mixins_generaPassword(),
        clientid: config.CLIENT_ID,
        lang: "it",
        deleted: 0,
        metadata: {
          fullname:
            this.localDataAnagrafica.nome + " " + this.localDataAnagrafica.cognome,
          isSuperAdmin: false,
          country: "it",
          anagrafica: this.localDataAnagrafica,
          rapporto: this.localDataRapporto,
          manager: "",
          skills: [],
          documenti: [],
        },
      };
      console.log("** create new user, newUser data", newUser);

      this.mixins_showLoader(this);
      const response = await addNewUser(newUser);
      this.mixins_hideLoader(this);

      console.log("** addNewUser api response", response);

      if (response?.response?.data) {
        // C'è un errore
        console.log("** addNewUser errore", response.response.data);
        if (response.response.data.code === 103) {
          // Utente esistente
          this.mixins_showMessage(
            "Creazione Nuovo Utente",
            `${this.localDataAnagrafica.emailaziendale} utente esistente`,
            "error"
          );
        } else {
          // Errore generico
          this.mixins_showMessage(
            "Creazione Nuovo Utente",
            "Si è verificato un errore, si prega di riprovare",
            "error"
          );
        }
        return;
      }

      // Inserire la logica per il reminder all'utente per le credenziali

      this.mixins_showMessage(
        "Creazione Nuovo Utente",
        `Utente creato correttamente`,
        // `Utente creato correttamente, le credenziali sono state inviate all'utente ${this.localDataAnagrafica.emailaziendale}`,
        "info"
      );

      // Se la creazione è andata a buon fine, richiamo l'utente appena creato in modifica tramite providerUserId
      if (response?.providerUserId) {
        this.$router.push({
          name: "route-teams-edit",
          params: { id: response.providerUserId },
          query: {},
        });
      }
    },
    async updateDataAnagrafica() {
      let isUpdated = false;
      if (this.isModified.anagrafica && this.isVerified.anagrafica) {
        console.log("** Aggiornamento sezione Anagrafica", this.localDataAnagrafica);
        isUpdated = true;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },
    async updateDataRapporto() {
      let isUpdated = false;
      if (this.isModified.rapporto && this.isVerified.rapporto) {
        console.log("** Aggiornamento sezione Rapporto", this.localDataRapporto);
        isUpdated = true;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },
    //
    // ** Fetch Data
    //
    fetchRapportoHandler() {
      if (this.isFirstTimeRapporto) {
        console.log(
          "** Rapporto: modifiche rilevate o dati già caricati, fetchDataAndUpdateFields non eseguito."
        );
        return;
      }

      this.isFirstTimeRapporto = true;

      if (this.$refs.userRapporto && this.$refs.userRapporto.fetchDataAndUpdateFields) {
        this.$refs.userRapporto.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userRapporto.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    //
    // ** Altri metodi
    //
    // resetto le sezioni modificate
    resetSectionModified(sections = []) {
      sections.forEach((section) => {
        this.isModified[section] = false;
      });
    },

    // resetto le sezioni verificate
    resetSectionVerified(sections = []) {
      sections.forEach((section) => {
        this.isVerified[section] = false;
      });
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return this.isModified.anagrafica || this.isModified.rapporto;
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  mounted() {
    console.log("** TeamsAdd montato");

    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    this.resetSectionModified();
    this.resetSectionVerified();

    this.headerTitle = "Crea Nuovo";

    this.handleSectionModified("anagrafica");
    this.handleSectionVerified("anagrafica", false);
    this.handleSectionModified("rapporto");
    this.handleSectionVerified("rapporto", false);

    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
