import axios from "axios";
import store from "@/store";
import config from "@/config";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import MessagesService from "@/services/MessagesService";

const AUTH_URL = config.AUTH_URL;

// Funzione per controllare se il token è scaduto basandosi su validUntil
export const isTokenExpired = (validUntil) => {
    const validUntilTimestamp = parseInt(validUntil, 10);
    if (isNaN(validUntilTimestamp)) {
        throw new Error("Invalid validUntil value");
    }
    console.log(`Checking if token is expired. Valid until: ${validUntilTimestamp * 1000}, Current time: ${Date.now()}`);
    return Date.now() > validUntilTimestamp * 1000;
};

// Funzione per la login
export const login = async (username, password) => {
    try {
        // Chiamata API per la login
        console.log("Attempting to login");
        const response = await axios.post(`${AUTH_URL}/api/auth/login`, {
            username,
            password,
            lang: config.LANG,
            clientid: config.CLIENT_ID,
            metadata: {}
        });
        // Login effettuatto correttamente
        console.log("AXIOS Login successful", response.data);
        const msgData = MessagesService.getMessageData(
            "authentication",
            8000
        );
        // Visualizza messaggi di login effettuato e benvenuto
        UtilityMixins.methods.mixins_showMessage("Login", msgData.text, msgData.notificationType);
        if (response.data.fullname) UtilityMixins.methods.mixins_showMessage("", `Ciao ${response.data.fullname}`, "info");
        return response.data;
    } catch (error) {
        // Gestione errori
        console.error("AXIOS Login error:", error);
        if (error.code === "ERR_NETWORK") {
            // Errore di rete
            console.error("AXIOS Login error:", "Network Error");
            const errorData = MessagesService.getMessageData(
                "generic",
                9001
            );
            UtilityMixins.methods.mixins_showMessage("", errorData.text, errorData.notificationType);
        } else if (error.response && error.response.status) {
            // Errore di autenticazione
            console.error("AXIOS Login error:", error.response.status);
            const errorData = MessagesService.getMessageData(
                "authentication",
                error.response.status
            );
            UtilityMixins.methods.mixins_showMessage("Login", errorData.text, errorData.notificationType);
        }
        throw new Error("Login failed");
    }
};

// Funzione per il refresh del token
export const refreshToken = async () => {
    try {
        console.log("Attempting to refresh token");
        const payload = {
            provider: config.PROVIDER,
            // email: store.getters.getUsername,
            clientID: config.CLIENT_ID,
            refreshToken: store.getters.getRefreshToken,
            actualToken: store.getters.getToken
        };
        console.log("Payload for refreshToken:", payload);
        const response = await axios.post(`${AUTH_URL}/api/auth/refreshtoken`, payload, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        console.log("Refresh token successful");
        return response.data;
    } catch (error) {
        console.error("Refresh token error:", error);
        throw new Error("Refresh token failed");
    }
};

// Funzione per richiedere il reset della password
export const forgotPassword = async (email) => {
    try {
        const response = await axios.post(`${AUTH_URL}/api/auth/forgot-password`, {
            email,
            clientid: config.CLIENT_ID
        });
        return response.data;
    } catch (error) {
        console.error("Forgot password error:", error);
        // throw new Error("Request to reset password failed");
        return error;
    }
};

// Funzione per richiedere il reset della password
export const resetPassword = async (passwordToken, newPassword) => {
    try {
        const response = await axios.post(`${AUTH_URL}/api/auth/reset-password/${passwordToken}`, {
            newPassword,
        });
        return response.data;
    } catch (error) {
        console.error("Reset password error:", error);
        // throw new Error("Request to reset password failed");
        return error;
    }
};

// Funzione per effettuare una richiesta API autenticata
export const makeAuthenticatedRequest = async (config) => {
    const validUntil = store.getters.getValidUntil;
    if (isTokenExpired(validUntil)) {
        try {
            const newUserData = await refreshToken();
            newUserData.refreshToken = newUserData.newRefreshToken; // Assicurarsi di aggiornare refreshToken
            delete newUserData.newRefreshToken; // Rimuovere la chiave newRefreshToken
            store.dispatch("updateToken", newUserData);
            store.dispatch("syncWithLocalStorage"); // Sincronizzare lo store con il local storage
        } catch (refreshError) {
            // Sessione scaduta, logout
            throw new Error("Session expired. Please log in again.");
        }
    }
    const token = store.getters.getToken;
    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
        };
    }
    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Sessione scaduta, logout
            store.dispatch("logout");
            throw new Error("Session expired. Please log in again.");
        } else {
            throw error;
        }
    }
};
