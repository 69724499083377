<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div
      ref="parentElement"
      class="dialog background-gray"
      :style="`width: ${dialogWidth}`"
      v-if="isVisible"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>ASSEGNATO A</span>
              </label>
              <div
                class="align-items-center col-lg-9 d-flex flex-column justify-content-center"
              >
                <MultiSelect
                  v-model="selectedUser"
                  :options="userOptions"
                  :searchable="true"
                  placeholder="- CERCA... -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'below'"
                  tagPlaceholder="Premi enter per creare un tag"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                  @update:modelValue="onUserUpdate"
                  :loading="isSearchingUser"
                  @search-change="asyncFetchUsers"
                  @open="onOpenMultiselect"
                  @close="onCloseMultiselect"
                  @blur="onOpenMultiselect"
                  :maxHeight="510"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DATA ASSEGNAZIONE</span>
              </label>
              <div class="align-items-center col-lg-9 d-flex justify-content-center">
                <VueDatePicker
                  v-model="localData.dataassegnazione"
                  dark
                  locale="it"
                  format="dd/MM/yyyy"
                  cancelText="Annulla"
                  selectText="Conferma"
                  :enable-time-picker="false"
                  @update:modelValue="onDataassegnazioneUpdate"
                  @cleared="onDataassegnazioneClear"
                  placeholder="DATA ASSEGNAZIONE"
                  :teleport="true"
                >
                  <template #input-icon>
                    <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                  </template>
                  <template #clear-icon="{ clear }">
                    <i
                      @click="clear"
                      class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                    ></i>
                  </template>
                </VueDatePicker>
                <button
                  class="timepicker-button"
                  alt="OGGI"
                  title="OGGI"
                  @click="setToday('dataassegnazione')"
                >
                  <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                </button>
              </div>
            </div>
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DATA RICONSEGNA</span>
              </label>
              <div class="align-items-center col-lg-9 d-flex justify-content-center">
                <VueDatePicker
                  v-model="localData.datariconsegna"
                  dark
                  locale="it"
                  format="dd/MM/yyyy"
                  cancelText="Annulla"
                  selectText="Conferma"
                  :enable-time-picker="false"
                  @update:modelValue="onDatariconsegnaUpdate"
                  @cleared="onDatariconsegnaClear"
                  placeholder="DATA RICONSEGNA"
                  :teleport="true"
                >
                  <template #input-icon>
                    <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                  </template>
                  <template #clear-icon="{ clear }">
                    <i
                      @click="clear"
                      class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                    ></i>
                  </template>
                </VueDatePicker>
                <button
                  class="timepicker-button"
                  alt="OGGI"
                  title="OGGI"
                  @click="setToday('datariconsegna')"
                >
                  <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- Errors model -->
          <div v-if="Object.keys(errorsModel).length > 0" class="mb-4">
            <div
              v-for="(errorModel, field) in errorsModel"
              :key="field"
              class="text-danger small mt-1"
            >
              {{ errorModel }}
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span
                ><i class="zmdi zmdi-save"></i
                >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { AssegnazioneSchema, Assegnazione } from "@/models/DotazioneJoiModel";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { formatISO } from "date-fns";
// import { formatISO, parseISO } from "date-fns";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveAssegnazione"],
  data() {
    return {
      localData: new Assegnazione(),
      errorsModel: {},
      utenti: [],
      totalRecordsUtenti: 0,
      selectedUser: null,
      userOptions: [],
      isSearchingUser: false,
      originalOverflow: "",
    };
  },
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData` come una nuova istanza di Assegnazione o come una copia dell'oggetto `dataItem`
        console.log("** watch", this.localData);
        this.localData = new Assegnazione(this.dataItem);
        this.errorsModel = {};
        this.initializeSelectedUser();
      }
    },
    // isVisible: {
    //   immediate: true,
    //   handler(newVal) {
    //     if (newVal) {
    //       this.initializeData();
    //     }
    //   },
    // },
    // selectedUserId: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.localData.iduser = newValue.value;
    //       this.localData.fullname = newValue.label;
    //     } else {
    //       this.localData.iduser = "";
    //       this.localData.fullname = "";
    //     }
    //     console.log("Selected user changed:", newValue);
    //     console.log("Updated localData:", this.localData);
    //   },
    //   immediate: true,
    // },
  },

  methods: {
    disableOverflow() {
      this.originalOverflow = this.$refs.parentElement.style.overflowY;
      this.$refs.parentElement.style.overflowY = "visible";
    },
    enableOverflow() {
      this.$refs.parentElement.style.overflowY = this.originalOverflow || "auto";
    },
    onOpenMultiselect() {
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.add("no-overflow");
      }
    },
    onCloseMultiselect() {
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.remove("no-overflow");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    initializeData() {
      console.log("** Initializing data:", this.dataItem);
      this.localData = new Assegnazione(this.dataItem);
      this.errorsModel = {};
      this.initializeSelectedUser();
    },
    setToday(fieldName) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this.localData[fieldName] = formatISO(today); // Usa la data con orario 00:00:00

      // Mappo i campi ai rispettivi metodi di aggiornamento
      const updateMethods = {
        dataassegnazione: this.onDataassegnazioneUpdate,
        datariconsegna: this.onDatariconsegnaUpdate,
      };

      // Invoco il metodo corretto in modo dinamico
      if (updateMethods[fieldName]) {
        updateMethods[fieldName](today);
      }
    },
    initializeSelectedUser() {
      // Inizializzo user
      console.log("** Initializing user:", this.localData);
      if (this.localData.iduser && this.localData.fullname) {
        const initialUser = {
          value: this.localData.iduser,
          label: this.localData.fullname,
        };
        this.userOptions = [initialUser];
        this.$nextTick(() => {
          this.selectedUser = initialUser;
        });
      } else {
        this.selectedUser = null;
        this.userOptions = [];
      }
      console.log("After initialization:", this.selectedUser, this.userOptions);

      // Inizializzo dataassegnazione
      // if (
      //   this.localData.dataassegnazione &&
      //   typeof this.localData.dataassegnazione === "string"
      // ) {
      //   this.localData.dataassegnazione = parseISO(this.localData.dataassegnazione);
      // } else {
      //   this.localData.dataassegnazione = null;
      // }
    },
    async asyncFetchUsers(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      this.isSearchingUser = true;
      const tabUsers = await this.mixins_getTeamUsers(query);
      this.userOptions = tabUsers.map((user) => ({
        value: user.providerUserId,
        label: user.metadata.fullname,
      }));
      this.isSearchingUser = false;
      console.log("** userOptions", this.userOptions);
    },

    saveData() {
      const { error } = AssegnazioneSchema.validate(this.localData, {
        abortEarly: false,
      });

      if (error) {
        // Raggruppa gli errori in un modello di errori
        const errors = error.details.reduce((acc, err) => {
          // Ignora gli errori per dataassegnazioneTS e datariconsegnaTS
          if (
            err.path[0] !== "dataassegnazioneTS" &&
            err.path[0] !== "datariconsegnaTS"
          ) {
            acc[err.path[0]] = err.message;
          }
          return acc;
        }, {});

        // Se ci sono errori su iduser e fullname, mostra solo l'errore di iduser
        if (errors.iduser && errors.fullname) {
          delete errors.fullname; // Elimina l'errore di fullname
        }

        this.errorsModel = errors;
      } else {
        this.errorsModel = {};

        const dataToSave = {
          ...this.localData,
          // iduser: this.selectedUser ? this.selectedUser.iduser : "",
          // fullname: this.selectedUser ? this.selectedUser.fullname : "",
        };
        this.$emit("saveAssegnazione", dataToSave, this.index);
      }
    },
    //
    // ** Gestione dei campi di input **
    //
    onDataassegnazioneUpdate(newDate) {
      this.localData.dataassegnazione = newDate ? formatISO(newDate) : null;
    },
    onDataassegnazioneClear() {
      this.localData.dataassegnazione = null;
    },
    onDatariconsegnaUpdate(newDate) {
      this.localData.datariconsegna = newDate ? formatISO(newDate) : null;
    },
    onDatariconsegnaClear() {
      this.localData.datariconsegna = null;
    },
    onUserUpdate(selected) {
      if (selected) {
        this.localData = {
          iduser: selected.value,
          fullname: selected.label,
        };
      } else {
        this.localData.iduser = "";
        this.localData.fullname = "";
      }
    },
    //
    // Fetch data
    //
    async fetchAllUsers(page, limit, textsearch) {
      try {
        const utenti = await this.mixins_fetchAllUsers(page, limit, textsearch);
        if (utenti) {
          this.utenti = utenti.utenti;
          this.totalRecordsUtenti = utenti.totalRecordsUtenti;
        }
      } catch (err) {
        console.error = "Si è verificato un errore";
      }
    },
  },
  async mounted() {},
};
</script>
<style>
.custom-datepicker-container {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 9999;
}
</style>
