import Joi from 'joi';

class Fornitore {
    constructor(data = {}) {
        this._id = data._id || '';
        this.info = data.info || '';
        this.ragionesociale = data.ragionesociale || '';
        this.riferimenti = data.riferimenti || '';
        this.logo = data.logo || '';
    }
}

const FornitoreSchema = Joi.object({
    _id: Joi.string().allow(''),
    info: Joi.string().allow(''),
    ragionesociale: Joi.string().required().messages({
        'string.empty': 'Il campo "RAGIONE SOCIALE" è obbligatorio',
        'any.required': 'Il campo "RAGIONE SOCIALE" è obbligatorio'
    }),
    riferimenti: Joi.string().allow(''),
    logo: Joi.string().allow(''),
});

export { FornitoreSchema, Fornitore };