<template>
  <div
    class="align-items-center container-fluid d-flex flex-row justify-content-end pt-4 px-0"
  >
    <button type="button" class="btn button-outline-1 small">
      <span><i class="zmdi zmdi-plus"></i>Importa Dati</span>
    </button>
  </div>
  <div class="container-fluid m-0 p-0 pt-4">
    <div class="row m-0 mb-3">
      <table class="table-bordered table-responsive-md table-setup">
        <thead class="table-header-font">
          <tr class="td-center">
            <th width="150">ANNO</th>
            <th width="150">MESE</th>
            <th width="150">RAL</th>
            <th width="150">CONTRIBUTI</th>
            <th width="150">BENEFIT</th>
            <th width="150">TOTALE</th>
          </tr>
        </thead>
        <!-- Table Records -->
        <tbody class="table-body-font td-vertical-center">
          <tr>
            <td class="td-center">2024</td>
            <td class="td-center">Maggio</td>
            <td class="td-right">€ 2.450,00</td>
            <td class="td-right">€ 1.150,00</td>
            <td class="td-right">€ 275,00</td>
            <td class="td-right">€ 3.875,00</td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-2 mt-4 mb-1">
        <label class="form-label"><span>COSTO TOTALE</span> </label>
        <input
          type="text"
          class="form-control text-start"
          value="€ 3.455,00"
          style="width: 200px"
          disabled
        />
      </div>
      <div class="row mt-2 mb-1">
        <label class="form-label"><span>COSTO MEDIO MENSILE</span> </label>
        <input
          type="text"
          class="form-control text-start"
          value="€ 3.455,00"
          style="width: 200px"
          disabled
        />
      </div>
    </div>
  </div>
</template>
