<template>
  <header class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <!-- Logo -->
        <a class="navbar-brand" href="/">
          <img src="@/assets/images/logo-unigate-web.png" class="img-fluid logo-header" />
        </a>

        <!-- Hamburger button -->
        <button
          id="navbarBtnToggle"
          class="navbar-toggler custom-navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainNavBar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Main Navigation -->
        <div
          v-if="isAuthenticated"
          class="navbar-collapse collapse justify-content-center"
          id="mainNavBar"
        >
          <ul class="navbar-nav custom-navbar-nav mx-auto mb-2 mb-lg-0">
            <!-- Dashboard -->
            <li class="nav-item">
              <router-link
                @click="hideOffNavbarBtnToggle()"
                class="nav-link"
                :class="{ active: isDashboardActive }"
                :to="{ name: 'route-dashboard' }"
                >DASHBOARD</router-link
              >
            </li>

            <!-- Attività -->
            <li class="nav-item">
              <router-link
                @click="hideOffNavbarBtnToggle()"
                class="nav-link"
                :class="{ active: isAttivitaActive }"
                :to="{
                  name: 'route-attivita-riepilogo',
                  query: {},
                }"
                >ATTIVITÀ</router-link
              >
            </li>

            <!-- Commesse -->
            <li
              v-if="
                isAuthenticatedSuperAdmin ||
                isAuthenticatedGestionePersonale ||
                isAuthenticatedDTC ||
                isAuthenticatedConsultazione
              "
              class="nav-item"
            >
              <router-link
                @click="hideOffNavbarBtnToggle()"
                class="nav-link"
                :class="{ active: isCommesseActive }"
                :to="{
                  name: 'route-commesse',
                  query: mixins_initCommesseQueryFilters(),
                }"
                >COMMESSE</router-link
              >
            </li>

            <!-- Team -->
            <li
              v-if="
                isAuthenticatedSuperAdmin ||
                isAuthenticatedGestionePersonale ||
                isAuthenticatedDTC ||
                isAuthenticatedConsultazione
              "
              class="nav-item"
            >
              <router-link
                @click="hideOffNavbarBtnToggle()"
                class="nav-link"
                :class="{ active: isTeamActive }"
                :to="{
                  name: 'route-teams',
                  query: mixins_initTeamsQueryFilters(),
                }"
                >TEAM</router-link
              >
            </li>

            <!-- Impostazioni -->
            <li
              v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale"
              class="nav-item dropdown"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle"
                :class="{ active: isSettingsActive }"
                id="navbarImpostazioni"
                role="button"
                aria-expanded="false"
                @click="toggleDropdown('settings', $event)"
                >IMPOSTAZIONI</a
              >
              <ul
                ref="settingsDropdown"
                class="navbar-custom-dropdown dropdown-menu"
                aria-labelledby="navbarImpostazioni"
              >
                <!-- Aziende -->
                <li v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale">
                  <router-link
                    @click="closeDropdown('settings')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-aziende' }"
                    :to="{ name: 'route-aziende' }"
                    ><i class="fa-solid fa-city fa-fw me-2"></i>AZIENDE</router-link
                  >
                </li>

                <!-- Clienti -->
                <li v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale">
                  <router-link
                    @click="closeDropdown('settings')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-clienti' }"
                    :to="{ name: 'route-clienti' }"
                    ><i class="fa-solid fa-hotel fa-fw me-2"></i>CLIENTI</router-link
                  >
                </li>

                <!-- Fornitori -->
                <li v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale">
                  <router-link
                    @click="closeDropdown('settings')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-fornitori' }"
                    :to="{ name: 'route-fornitori' }"
                    ><i class="fa-solid fa-building fa-fw me-2"></i>FORNITORI</router-link
                  >
                </li>

                <!-- Skills -->
                <li v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale">
                  <router-link
                    @click="closeDropdown('settings')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-skills' }"
                    :to="{ name: 'route-skills' }"
                    ><i class="bi bi-award-fill me-2"></i>SKILLS</router-link
                  >
                </li>
              </ul>
            </li>

            <!-- Esporta -->
            <li
              v-if="
                isAuthenticatedSuperAdmin ||
                isAuthenticatedGestionePersonale ||
                isAuthenticatedDTC ||
                isAuthenticatedConsultazione
              "
              class="nav-item dropdown"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle"
                :class="{ active: isExportActive }"
                id="navbarExport"
                role="button"
                aria-expanded="false"
                @click="toggleDropdown('export', $event)"
                >ESPORTA</a
              >
              <ul
                ref="exportDropdown"
                class="navbar-custom-dropdown dropdown-menu"
                aria-labelledby="navbarExport"
              >
                <!-- Dettaglio Presenze -->
                <li>
                  <router-link
                    @click="closeDropdown('export')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-esporta-dettagliopresenze' }"
                    :to="{ name: 'route-esporta-dettagliopresenze' }"
                    ><i class="bi bi-grid-3x3 me-2"></i>DETTAGLIO PRESENZE</router-link
                  >
                </li>
                <!-- Paco -->
                <li>
                  <router-link
                    @click="closeDropdown('export')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-esporta-paco' }"
                    :to="{ name: 'route-esporta-paco' }"
                    ><i class="bi bi-grid-3x3 me-2"></i>PACO</router-link
                  >
                </li>
                <!-- NAV -->
                <li>
                  <router-link
                    @click="closeDropdown('export')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-esporta-nav' }"
                    :to="{ name: 'route-esporta-nav' }"
                    ><i class="bi bi-grid-3x3 me-2"></i>NAV</router-link
                  >
                </li>
              </ul>
            </li>

            <!-- Supporto -->
            <li class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                :class="{ active: isSupportActive }"
                id="navbarSupport"
                role="button"
                aria-expanded="false"
                @click="toggleDropdown('support', $event)"
                >SUPPORTO</a
              >
              <ul
                ref="supportDropdown"
                class="navbar-custom-dropdown dropdown-menu"
                aria-labelledby="navbarSupport"
              >
                <!-- Manuale Utente -->
                <li>
                  <router-link
                    @click="closeDropdown('support')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-manualeUtente' }"
                    :to="{ name: 'route-manualeUtente' }"
                    ><i class="fa-solid fa-circle-question fa-fw me-2"></i>MANUALE
                    UTENTE</router-link
                  >
                </li>

                <!-- Contatta Supporto -->
                <li>
                  <router-link
                    @click="closeDropdown('support')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-contattaSupporto' }"
                    :to="{ name: 'route-contattaSupporto' }"
                    ><i class="fa-solid fa-circle-info fa-fw me-2"></i>CONTATTA
                    SUPPORTO</router-link
                  >
                </li>

                <!-- Storico Rilasci -->
                <li>
                  <router-link
                    @click="closeDropdown('support')"
                    class="dropdown-item"
                    :class="{ active: $route.name === 'route-storicoRilasci' }"
                    :to="{ name: 'route-storicoRilasci' }"
                    ><i class="fa-solid fa-code fa-fw me-2"></i>STORICO
                    RILASCI</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>

          <!-- Notifications -->
          <ul class="d-none navbar-nav custom-navbar-but ml-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-no-arrow"
                href="#"
                id="navbarNotification"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="fa-solid fa-bell fa-fw"></i
                ><span class="custom-badge">3</span></a
              >
              <ul
                class="navbar-custom-dropdown dropdown-menu dropdown-menu-end notifications-container"
                aria-labelledby="navbarNotification"
              >
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Gestione Personale</strong><br /><span class="text-white"
                      >Ha pubblicato un nuovo messaggio in dashboard</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Mario Rossi</strong><br /><span class="text-white"
                      >Ha aggiunto un nuovo skill al suo profilo</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Giovanni Verdi</strong><br /><span class="text-white"
                      >Ha inviato una richiesta di dotazione tecnologica</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Giovanni Verdi</strong><br /><span class="text-white"
                      >Ha inviato una richiesta di dotazione tecnologica</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Giovanni Verdi</strong><br /><span class="text-white"
                      >Ha inviato una richiesta di dotazione tecnologica</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Giovanni Verdi</strong><br /><span class="text-white"
                      >Ha inviato una richiesta di dotazione tecnologica</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><strong>Giovanni Verdi</strong><br /><span class="text-white"
                      >Ha inviato una richiesta di dotazione tecnologica</span
                    ></a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
              </ul>
            </li>
          </ul>

          <!-- User Profile -->
          <ul class="navbar-nav custom-navbar-but ml-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-no-arrow"
                href="#"
                id="navbarProfile"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><span class="small me-2 color-mainblue">{{
                  userAuthenticated?.fullname ?? ""
                }}</span
                ><i class="fa-solid fa-user fa-fw me-1"></i
              ></a>
              <ul
                class="navbar-custom-dropdown dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarProfile"
              >
                <li class="text-center" style="white-space: nowrap">
                  <span class="color-mainblue">{{
                    userAuthenticated?.fullname ?? ""
                  }}</span>
                  <br />
                  <span v-if="isAuthenticatedSuperAdmin" class="badge-colored-privilege"
                    >SUPER ADMIN</span
                  >
                  <span
                    v-else-if="isAuthenticatedGestionePersonale"
                    class="badge-colored-privilege"
                    >GESTIONE PERSONALE</span
                  >
                  <span v-else-if="isAuthenticatedDTC" class="badge-colored-privilege"
                    >DTC</span
                  >
                  <span
                    v-else-if="isAuthenticatedConsultazione"
                    class="badge-colored-privilege"
                    >CONSULTAZIONE</span
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a
                    @click.prevent="hideOffNavbarBtnToggle(), goAreaPersonale()"
                    class="dropdown-item"
                    :class="{ active: isAreapersonaleActive }"
                    href="#"
                    ><i class="fa-solid fa-user me-2"></i>AREA PERSONALE</a
                  >
                </li>
                <li>
                  <a @click.prevent="logoutAndRedirect" class="dropdown-item" href="#"
                    ><i class="fa-solid fa-right-from-bracket me-2"></i>LOGOUT</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";
import EventBus from "@/eventBus"; // Importa l'EventBus

export default {
  name: "HeaderComp",
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {},
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      openDropdown: null,
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    isSettingsActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/impostazioni");
    },
    isSupportActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/supporto");
    },
    isExportActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/esporta");
    },
    isTeamActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/team") && !this.isMe;
      // return currentPath === "/team" || currentPath === "/team/modifica/:id";
    },
    isCommesseActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/commesse");
    },
    isAttivitaActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/attivita");
    },
    isDashboardActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/dashboard");
    },
    isAreapersonaleActive() {
      const currentPath = this.$route.path;
      return currentPath.startsWith("/team/modifica") && this.isMe;
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
    isMe() {
      return this.userAuthenticated?.providerUserId == this.$route.params.id;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    logoutAndRedirect() {
      this.logout();
      this.$router.push({ name: "route-login" });
    },
    getCurrentPath() {
      return this.$route.path;
    },
    hideOffNavbarBtnToggle() {
      let navbarBtnToggle = document.getElementById("navbarBtnToggle");
      if (
        !navbarBtnToggle.classList.contains("collapsed") &&
        navbarBtnToggle.getAttribute("aria-expanded") == "true" &&
        this.width <= 991
      ) {
        console.log("** hideOffNavbarBtnToggle is clicked");
        navbarBtnToggle.click();
      }
    },
    toggleDropdown(name, event) {
      event.preventDefault();
      event.stopPropagation();
      const dropdownRef = this.$refs[name + "Dropdown"];
      if (dropdownRef) {
        const dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownRef);
        if (this.openDropdown === name) {
          dropdownInstance.hide();
          this.openDropdown = null;
          document.removeEventListener("click", this.handleOutsideClick);
        } else {
          if (this.openDropdown) {
            this.closeDropdown(this.openDropdown);
          }
          dropdownInstance.show();
          this.openDropdown = name;
          document.addEventListener("click", this.handleOutsideClick);
        }
      }
    },
    closeDropdown(name) {
      const dropdownRef = this.$refs[name + "Dropdown"];
      if (dropdownRef) {
        const dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(dropdownRef);
        dropdownInstance.hide();
        this.openDropdown = null;
        document.removeEventListener("click", this.handleOutsideClick);
      }
      this.hideOffNavbarBtnToggle();
    },
    handleOutsideClick(event) {
      const dropdownRef = this.$refs[this.openDropdown + "Dropdown"];
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        this.closeDropdown(this.openDropdown);
      }
    },
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      let navbarBtnToggle = document.getElementById("navbarBtnToggle");
      if (!navbarBtnToggle.classList.contains("collapsed") && this.width > 991) {
        navbarBtnToggle.click();
      }
    },
    onChangeUser() {
      // Oggetto user da local storage
      this.userAuthenticated = this.mixin_getUserDataLS();

      console.log("** utente autenticato", this.userAuthenticated);
    },
    goAreaPersonale() {
      this.$router.push({
        name: "route-teams-edit",
        params: { id: this.userAuthenticated.providerUserId },
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    // Inizializzo lo user
    this.onChangeUser();
    // Ascolta l'evento changeUser
    EventBus.on("changeUser", this.onChangeUser);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
