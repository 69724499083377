<template>
  <div class="d-flex container-fluid justify-content-center m-0 pt-4 pb-4 row">
    <div class="col-lg-5 m-0 mb-3">
      <!-- Aggiungi -->
      <div
        class="align-items-center container-fluid d-flex flex-row justify-content-end pb-3 px-0"
      >
        <button type="button" class="btn button-outline-1 small">
          <span><i class="zmdi zmdi-plus"></i>Aggiungi</span>
        </button>
      </div>
      <!-- Table Gruppi -->
      <table class="table-bordered table-responsive-md table-setup">
        <thead class="table-header-font">
          <tr class="td-center">
            <th>ABILITAZIONI</th>
            <th style="min-width: 100px !important; width: 100px !important">AZIONI</th>
          </tr>
        </thead>
        <!-- Table Records -->
        <tbody class="table-body-font td-vertical-center">
          <tr>
            <td class="td-center">
              <select class="form-select form-control">
                <option value="0">- SELEZIONA -</option>
                <option value="1" selected>Crea Utenti</option>
              </select>
            </td>
            <td class="td-actions td-center">
              <button alt="MODIFICA" title="MODIFICA">
                <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
              </button>
              <button class="delete" alt="ELIMINA" title="ELIMINA">
                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="td-center">
              <select class="form-select form-control">
                <option value="0">- SELEZIONA -</option>
                <option value="1" selected>Gestione Personale</option>
              </select>
            </td>
            <td class="td-actions td-center">
              <button alt="MODIFICA" title="MODIFICA">
                <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
              </button>
              <button class="delete" alt="ELIMINA" title="ELIMINA">
                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
