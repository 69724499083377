<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <Sidebar @applyFilters="applyFilters" @clearFilters="clearFilters" />
        <!-- Main content -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/views/menu_attivita/AttivitaSidebar.vue";

export default {
  components: { Sidebar },
  methods: {
    applyFilters(filtersData) {
      console.log("** applyFilters chiamato con:", filtersData);
      this.$router.push({ query: { ...filtersData } });
    },
    clearFilters() {
      console.log("** clearFilters chiamato");
      this.$router.push({ query: null });
    },
  },
  mounted() {
    console.log("** AttivitaView montato con rotta:", this.$route);
  },
  created() {
    console.log("** AttivitaView creato");
  },
};
</script>
