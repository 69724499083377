import { createRouter, createWebHistory } from 'vue-router';

// Authentication
import LoginView from '@/views/authentication/LoginView.vue';
import ForgotPasswordView from '@/views/authentication/ForgotPasswordView.vue';
import ResetPasswordView from '@/views/authentication/ResetPasswordView.vue';

// Dashboard
import DashboardView from '@/views/menu_dashboard/DashboardView.vue';
import DashboardContent from '@/views/menu_dashboard/DashboardContent.vue';

// Aziende
import AziendeView from '@/views/menu_impostazioni/aziende/AziendeView.vue';
import AziendeContent from '@/views/menu_impostazioni/aziende/AziendeContent.vue';
import AziendeAddEdit from '@/views/menu_impostazioni/aziende/AziendeAddEdit.vue';

// Clienti
import ClientiView from '@/views/menu_impostazioni/clienti/ClientiView.vue';
import ClientiContent from '@/views/menu_impostazioni/clienti/ClientiContent.vue';
import ClientiAddEdit from '@/views/menu_impostazioni/clienti/ClientiAddEdit.vue';

// Fornitori
import FornitoriView from '@/views/menu_impostazioni/fornitori/FornitoriView.vue';
import FornitoriContent from '@/views/menu_impostazioni/fornitori/FornitoriContent.vue';
import FornitoriAddEdit from '@/views/menu_impostazioni/fornitori/FornitoriAddEdit.vue';

// Dotazioni
import DotazioniView from '@/views/menu_impostazioni/dotazioni/DotazioniView.vue';
import DotazioniContent from '@/views/menu_impostazioni/dotazioni/DotazioniContent.vue';
import DotazioniAddEdit from '@/views/menu_impostazioni/dotazioni/DotazioniAddEdit.vue';

// Skills
import SkillsView from '@/views/menu_impostazioni/skills/SkillsView.vue';
import SkillsContent from '@/views/menu_impostazioni/skills/SkillsContent.vue';

// Team
import TeamsView from '@/views/menu_team/TeamsView.vue';
import TeamsContent from '@/views/menu_team/TeamsContent.vue';
import TeamsAdd from '@/views/menu_team/TeamsAdd.vue';
import TeamsEdit from '@/views/menu_team/TeamsEdit.vue';

// Commesse
import CommesseView from '@/views/menu_commesse/CommesseView.vue';
import CommesseContent from '@/views/menu_commesse/CommesseContent.vue';
import CommesseEdit from '@/views/menu_commesse/CommesseEdit.vue';
import CommesseAdd from '@/views/menu_commesse/CommesseAdd.vue';

// Attivita
import AttivitaView from '@/views/menu_attivita/AttivitaView.vue';
import AttivitaRiepilogo from '@/views/menu_attivita/AttivitaRiepilogo.vue';
import AttivitaAssenzeFerie from '@/views/menu_attivita/AttivitaAssenzeFerie.vue';

// Supporto
import ContattaSupportoView from '@/views/menu_supporto/contatta_supporto/ContattaSupportoView.vue';
import ManualeUtenteView from '@/views/menu_supporto/manuale_utente/ManualeUtenteView.vue';
import StoricoRilasciView from '@/views/menu_supporto/storico_rilasci/StoricoRilasciView.vue';
import AreaPersonaleView from '@/views/area_personale/AreaPersonaleView.vue';

// Esporta
import ExportDettaglioPresenze from '@/views/menu_esporta/ExportDettaglioPresenze.vue';
import ExportPaco from '@/views/menu_esporta/ExportPaco.vue';
import ExportNav from '@/views/menu_esporta/ExportNav.vue';

// Importo le utility, lo store e il file config
import store from '@/store';
// import config from '@/config.js';
import { isTokenExpired, refreshToken } from '@/services/auth';
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

import * as Profiles from '@/mixins/Profiles.js';

const routes = [
  {
    path: '/',
    redirect: () => {
      if (store.getters.isAuthenticated) {
        return { name: 'route-dashboard' };
      } else {
        return { name: 'route-login' };
      }
    }
  },
  {
    path: '/login',
    name: 'route-login',
    component: LoginView,
    meta: { requiresAuth: false, isPublic: true }
  },
  {
    path: '/forgotpassword',
    name: 'route-forgotPassword',
    component: ForgotPasswordView,
    meta: { requiresAuth: false, isPublic: true }
  },
  {
    path: '/resetpassword',
    name: 'route-resetPassword',
    component: ResetPasswordView,
    meta: { requiresAuth: false, isPublic: true }
  },
  // /dashboard
  {
    path: "/dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: 'route-dashboard',
        component: DashboardContent
      },
    ]
  },
  // /impostazioni/aziende
  {
    path: "/impostazioni/aziende",
    component: AziendeView,
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
    children: [
      {
        path: "",
        name: 'route-aziende',
        component: AziendeContent
      },
      {
        path: "aggiungi",
        name: 'route-aziende-add',
        component: AziendeAddEdit
      },
      {
        path: "modifica/:id",
        name: 'route-aziende-edit',
        component: AziendeAddEdit
      },
    ]
  },
  // /impostazioni/clienti
  {
    path: "/impostazioni/clienti",
    component: ClientiView,
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
    children: [
      {
        path: "",
        name: 'route-clienti',
        component: ClientiContent
      },
      {
        path: "aggiungi",
        name: 'route-clienti-add',
        component: ClientiAddEdit
      },
      {
        path: "modifica/:id",
        name: 'route-clienti-edit',
        component: ClientiAddEdit
      },
    ]
  },
  // /impostazioni/fornitori
  {
    path: "/impostazioni/fornitori",
    component: FornitoriView,
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
    children: [
      {
        path: "",
        name: 'route-fornitori',
        component: FornitoriContent
      },
      {
        path: "aggiungi",
        name: 'route-fornitori-add',
        component: FornitoriAddEdit
      },
      {
        path: "modifica/:id",
        name: 'route-fornitori-edit',
        component: FornitoriAddEdit
      },
    ]
  },
  // /impostazioni/dotazioni
  {
    path: "/impostazioni/dotazioni",
    component: DotazioniView,
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_DTC] },
    children: [
      {
        path: "",
        name: 'route-dotazioni',
        component: DotazioniContent
      },
      {
        path: "aggiungi",
        name: 'route-dotazioni-add',
        component: DotazioniAddEdit
      },
      {
        path: "modifica/:id",
        name: 'route-dotazioni-edit',
        component: DotazioniAddEdit
      },
    ]
  },
  // /impostazioni/skills
  {
    path: "/impostazioni/skills",
    component: SkillsView,
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
    children: [
      {
        path: "",
        name: 'route-skills',
        component: SkillsContent,
        props: (route) => ({ selectedType: route.params.selectedType }) // Passa selectedType come prop
      },
    ]
  },
  // /team
  {
    path: "/team",
    component: TeamsView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: 'route-teams',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
        component: TeamsContent
      },
      {
        path: "aggiungi",
        name: 'route-teams-add',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
        component: TeamsAdd
      },
      {
        path: "modifica/:id",
        name: 'route-teams-edit',
        component: TeamsEdit,
      },
    ]
  },
  // /commesse
  {
    path: "/commesse",
    component: CommesseView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: 'route-commesse',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
        component: CommesseContent
      },
      {
        path: "aggiungi",
        name: 'route-commesse-add',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE] },
        component: CommesseAdd
      },
      {
        path: "modifica/:id",
        name: 'route-commesse-edit',
        component: CommesseEdit
      },
    ]
  },
  // /attivita
  {
    path: "/attivita/riepilogo",
    component: AttivitaView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: 'route-attivita-riepilogo',
        component: AttivitaRiepilogo
      },
    ]
  },
  {
    path: "/attivita/ferie",
    component: AttivitaView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: 'route-attivita-ferie',
        component: AttivitaAssenzeFerie
      },
    ]
  },
  {
    path: "/attivita/assenze",
    component: AttivitaView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: 'route-attivita-assenza',
        component: AttivitaAssenzeFerie
      },
    ]
  },
  // /esporta
  {
    path: "/esporta",
    meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
    children: [
      {
        path: "dettaglio-presenze",
        name: 'route-esporta-dettagliopresenze',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
        component: ExportDettaglioPresenze
      },
      {
        path: "paco",
        name: 'route-esporta-paco',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
        component: ExportPaco
      },
      {
        path: "nav",
        name: 'route-esporta-nav',
        meta: { requiresAuth: true, requiresProfile: [Profiles.PROFILE_GESTIONE_PERSONALE, Profiles.PROFILE_CONSULTAZIONE, Profiles.PROFILE_DTC] },
        component: ExportNav
      }
    ]
  },
  // /supporto
  {
    path: '/supporto/contatta-supporto',
    name: 'route-contattaSupporto',
    component: ContattaSupportoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/supporto/manuale-utente',
    name: 'route-manualeUtente',
    component: ManualeUtenteView,
    meta: { requiresAuth: true }
  },
  {
    path: '/supporto/storico-rilasci',
    name: 'route-storicoRilasci',
    component: StoricoRilasciView,
    meta: { requiresAuth: true }
  },
  {
    path: '/area-personale',
    name: 'route-areaPersonale',
    component: AreaPersonaleView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:catchAll(.*)', // Questa è la route che cattura tutte le route non definite
    name: 'NotFound',
    meta: { requiresAuth: true },
    component: DashboardView // Questo può essere un componente vuoto o posso lasciarlo vuoto
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise(resolve => {
        setTimeout(() => resolve({ top: 0 }), 300);
      })
    );
  }
});

router.beforeEach(async (to, from, next) => {
  console.log(`Navigating to: ${to.path}`);

  // Controllo se la rotta è pubblica
  const isPublicRoute = to.matched.some(record => record.meta.isPublic);

  // Ottiengo i dati dell'utente dal localStorage
  const user = UtilityMixins.methods.mixin_getUserDataLS();
  const isAuthenticated = !!user;
  const validUntil = user ? user.validUntil : null;

  // Se l'utente è autenticato e sta cercando di accedere a una pagina pubblica (e.g., forgot/reset password)
  if (isAuthenticated && isPublicRoute) {
    console.log("User is authenticated, redirecting to dashboard.");
    return next({ name: 'route-dashboard' });
  }

  // Se la rotta è pubblica, lascio passare
  if (isPublicRoute) {
    return next();
  }

  // Se non è autenticato e la rotta richiede autenticazione, vado al login
  if (!isAuthenticated && to.meta.requiresAuth) {
    console.log("Not authenticated, redirecting to login.");
    return next({ name: 'route-login' });
  }

  // Verifica la rotta catchAll per route inesistenti
  if (to.name === 'NotFound') {
    if (isAuthenticated) {
      return next({ name: 'route-dashboard' });
    } else {
      return next({ name: 'route-login' });
    }
  }

  // Se richiede autenticazione, verifico il token
  if (to.meta.requiresAuth) {
    if (isTokenExpired(validUntil)) {
      try {
        const newUserData = await refreshToken();
        newUserData.refreshToken = newUserData.newRefreshToken;
        delete newUserData.newRefreshToken;
        store.dispatch('updateToken', newUserData);
        store.dispatch('syncWithLocalStorage');
        return next();
      } catch (error) {
        console.log("Token refresh failed, logging out.");
        store.dispatch("logout");
        return next({ name: 'route-login' });
      }
    }

    // Verifico i profili se necessario
    if (to.meta.requiresProfile) {
      // Verifico che user.profili esista e contenga profili validi
      const userProfiles = user?.profili?.map(profile => profile.descrizione?.toLowerCase()) || [];

      // Verifico che to.meta.requiresProfile sia un array prima di usare map
      const requiredProfiles = Array.isArray(to.meta.requiresProfile)
        ? to.meta.requiresProfile.map(profile => profile.toLowerCase())
        : [];

      // Verifico se l'utente ha accesso in base ai profili richiesti o se è SuperAdmin
      const hasAccess = user?.isSuperAdmin || requiredProfiles.some(profile => userProfiles.includes(profile));

      if (!hasAccess) {
        console.log("Access denied, redirecting to dashboard.");
        return next({ name: 'route-dashboard' });
      }
    }

  }

  // Proseguo normalmente
  return next();
});



export default router;
