<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE CLIENTI<span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-hotel fa-fw"></i>
                </span>
                <span v-if="getSearchClienti" class="mx-3 color-white"
                  >[ <span class="color-mainblue">{{ getSearchClienti }}</span> ]</span
                >
                <span v-if="clienti.length > 0 && !loading">
                  Tutti
                  <span class="ms-2" style="font-size: smaller">
                    ({{ totalRecords }} {{ totalRecords == 1 ? "elemento" : "elementi" }})
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="getSearchClienti"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="clienti.length > 0 && !loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Error -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <!-- No data -->
        <div v-if="clienti.length == 0 && !loading && !error">
          <h5 class="mt-1">Nessun dato da visualizzare</h5>
        </div>
        <!-- Data -->
        <div v-if="clienti.length > 0 && !loading">
          <!-- Export Data -->
          <div class="d-none row m-0 my-2 padding-x-4px">
            <div class="container-fluid p-0 mb-2">
              <div class="dropdown float-end">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_esporta"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span> <i class="me-2 fa-solid fa-file-export"></i>Esporta </span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_esporta"
                >
                  <li>
                    <div class="but dropdown-item small">
                      <span>CLIENTI in Excel</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Table Data -->
          <div class="row m-0 padding-x-4px">
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th>
                    RAGIONE SOCIALE<span class="small color-white fw-light ms-2"
                      >[ {{ getSortLabeltByValue(getClientiSortType) }} ]</span
                    >
                  </th>
                  <th style="min-width: 200px !important; width: 400px !important">
                    INFO
                  </th>
                  <th style="min-width: 100px !important; width: 280px !important">
                    RIFERIMENTI
                  </th>
                  <th style="min-width: 100px !important; width: 100px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <tbody class="table-body-font td-vertical-center">
                <tr v-for="cliente in clienti" :key="cliente._id">
                  <td>{{ cliente.ragionesociale }}</td>
                  <td>{{ cliente.info }}</td>
                  <td style="white-space: pre-wrap">{{ cliente.riferimenti }}</td>
                  <td class="td-actions td-center">
                    <button alt="MODIFICA" title="MODIFICA" @click="editCliente(cliente)">
                      <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                    </button>
                    <button
                      class="delete"
                      alt="ELIMINA"
                      title="ELIMINA"
                      @click="askRemoveCliente(cliente._id)"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <div
              class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
            >
              <div class="row" v-if="clienti.length > 0 && totalPages > 1">
                <vue-pagination
                  :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="6"
                  @pagechanged="onPageChange"
                ></vue-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { fetchClienti, deleteCliente } from "@/services/api";
// import JsonExcel from "vue-json-excel3";
import VuePagination from "@/components/ui/VuePagination.vue";
import { mapGetters, mapActions } from "vuex";
import { Cliente } from "@/models/ClienteJoiModel";
import sortOrderList from "@/data/sort_order_list.json";

export default {
  components: {
    SearchButton,
    VuePagination,
    // esportaExcel: JsonExcel,
  },
  data() {
    return {
      isSearching: false,
      clienti: [],
      loading: false,
      error: null,
      page: 1,
      limit: 20,
      totalPages: 1,
      export_json_meta: [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
      totalRecords: 0,
      sortOrderList,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageClienti", "getClientiSortType", "getSearchClienti"]),
  },
  watch: {
    getClientiSortType() {
      this.fetchClienti();
    },
    getSearchClienti() {
      this.fetchClienti();
    },
  },
  methods: {
    ...mapActions(["setCurrentPageClienti", "setSearchClienti"]),
    async fetchClientiData() {
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchClienti(
          this.page,
          this.limit,
          this.getClientiSortType,
          this.getSearchClienti
        );
        const totalCount = response?.totalCount ?? 0;
        this.clienti = response.clienti?.map((item) => new Cliente(item)) ?? [];
        this.totalPages = Math.ceil(totalCount / this.limit);
        this.totalRecords = totalCount;
        console.log(this.clienti);
      } catch (err) {
        this.error = "Si è verificato un errore";
      } finally {
        this.loading = false;
      }
    },
    editCliente(cliente) {
      this.$router.push({ name: "route-clienti-edit", params: { id: cliente._id } });
    },
    async askRemoveCliente(id) {
      this.$swal
        .fire({
          title: "Cancellazione Cliente",
          html: "Sei sicuro di voler cancellare il cliente?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeCliente(id);
          }
        });
    },
    async removeCliente(id) {
      this.loading = true;
      try {
        await deleteCliente(id);
        this.page = 1;
        this.setCurrentPageClienti(1);
        await this.fetchClientiData();
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'cliente", error);
        console.error("Error details:", error.response?.data);
        this.error = "Errore durante l'eliminazione dell'cliente";
      } finally {
        this.loading = false;
      }
    },
    onPageChange(page) {
      this.page = page;
      this.setCurrentPageClienti(page);
      this.fetchClientiData();
    },
    fetchClienti() {
      this.page = 1;
      this.setCurrentPageClienti(1);
      this.fetchClientiData();
    },
    getSortLabeltByValue(value) {
      const foundItem = this.sortOrderList.find((item) => item.value === value);
      return foundItem ? foundItem.text : "";
    },
    //
    // ** Gestione ricerca **
    //
    filterTextToSearch(text) {
      this.setSearchClienti(text);
    },
    filterClearTextToSearch() {
      this.setSearchClienti("");
    },
  },
  async created() {
    this.page = this.getCurrentPageClienti;
    await this.fetchClientiData();
  },
};
</script>
