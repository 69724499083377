import Joi from "joi";

//
// Anagrafica
//
class Anagrafica {
    constructor(data = {}) {
        this.nome = data.nome || "";
        this.cognome = data.cognome || "";
        this.datanascita = data.datanascita || null;
        this.luogonascita = data.luogonascita || "";
        this.provnascita = data.provnascita || "";
        this.sesso = data.sesso || "";
        this.codicefiscale = data.codicefiscale || "";
        this.statocivile = data.statocivile || "";
        // this.titolostudio = data.titolostudio || "";
        // Modifica per titolostudio come array di stringhe
        this.titolostudio = Array.isArray(data.titolostudio) ? data.titolostudio : [""];
        this.cittaresidenza = data.cittaresidenza || "";
        this.provresidenza = data.provresidenza || "";
        this.nazionenascita = data.nazionenascita || "";
        this.nazioneresidenza = data.nazioneresidenza || "";
        this.via = data.via || "";
        this.cap = data.cap || "";
        this.telefonofisso = data.telefonofisso || "";
        this.telefonocellulare = data.telefonocellulare || "";
        this.emailaziendale = data.emailaziendale || "";
        this.emailpersonale = data.emailpersonale || "";
    }
}
const AnagraficaSchema = Joi.object({
    nome: Joi.string().required().messages({
        "string.empty": 'Il campo "NOME" è obbligatorio',
        "any.required": 'Il campo "NOME" è obbligatorio',
    }),
    cognome: Joi.string().required().messages({
        "string.empty": 'Il campo "COGNOME" è obbligatorio',
        "any.required": 'Il campo "COGNOME" è obbligatorio',
    }),
    datanascita: Joi.date().iso().allow(null),
    luogonascita: Joi.string().allow(""),
    provnascita: Joi.string().length(2).allow(""),
    sesso: Joi.string().valid("M", "F", ""),
    codicefiscale: Joi.string()
        .allow("")
        .custom((value, helpers) => {
            if (value === "") return value;
            if (value.length !== 16) {
                return helpers.error("string.length");
            }
            return value;
        })
        .messages({
            "string.length": 'Il campo "CODICE FISCALE" deve essere di 16 caratteri',
        }),
    statocivile: Joi.string().allow(""),
    // titolostudio: Joi.string().allow(""),
    // Cambiamento per titolostudio a array di stringhe
    titolostudio: Joi.array().items(Joi.string().allow("")).single().allow(""),
    cittaresidenza: Joi.string().allow(""),
    provresidenza: Joi.string().length(2).allow("").messages({
        "string.base": 'Il campo "PROV. DI RESIDENZA" deve essere una stringa',
        "string.length": 'Il campo "PROV. DI RESIDENZA" deve essere di 2 caratteri',
    }),
    nazionenascita: Joi.string().allow("").messages({
        "string.base": 'Il campo "NAZIONE DI NASCITA" deve essere una stringa',
    }),
    nazioneresidenza: Joi.string().allow("").messages({
        "string.base": 'Il campo "NAZIONE DI RESIDENZA" deve essere una stringa',
    }),
    via: Joi.string().allow(""),
    cap: Joi.string()
        .pattern(/^\d{5}$/)
        .allow("")
        .messages({
            "string.pattern.base": 'Il campo "CAP" deve essere composto da 5 cifre',
            "string.empty": 'Il campo "CAP" è obbligatorio',
            "any.required": 'Il campo "CAP" è obbligatorio',
        }),
    telefonofisso: Joi.string().allow(""),
    telefonocellulare: Joi.string().allow(""),
    emailaziendale: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": 'Il campo "EMAIL AZIENDALE" è obbligatorio',
            "string.email": 'Il campo "EMAIL AZIENDALE" non è un formato e-mail valido',
        }),
    emailpersonale: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .allow("")
        .messages({
            "string.email": 'Il campo "EMAIL PERSONALE" non è un formato e-mail valido',
        }),
});

//
// Rapporto
//
class Rapporto {
    constructor(data = {}) {
        this.societa = {
            id: data.societa?.id ?? "",
            descrizione: data.societa?.descrizione ?? "",
            giorniFerie: data.societa?.giorniFerie ?? 0,
        };
        this.profili = data.profili?.map(profilo => ({
            id: profilo.id ?? "",
            descrizione: profilo.descrizione ?? "",
        })) ?? [];
        this.ruoli = data.ruoli?.map(ruolo => ({
            id: ruolo.id ?? "",
            descrizione: ruolo.descrizione ?? "",
        })) ?? [];
        this.matricola = data.matricola ?? "";
        this.codicepaco = data.codicepaco ?? "";
        this.aziendaterzaparte = data.aziendaterzaparte ? {
            id: data.aziendaterzaparte.id ?? "",
            descrizione: data.aziendaterzaparte.descrizione ?? "",
        } : null;
        this.datainiziorapporto = data.datainiziorapporto ?? null;
        this.datafinerapporto = data.datafinerapporto ?? null;
        this.sede = data.sede ? {
            id: data.sede.id ?? "",
            descrizione: data.sede.descrizione ?? "",
        } : null;
        this.referente = {
            id: data.referente?.id ?? "",
            descrizione: data.referente?.descrizione ?? "",
        };
        this.orecontrattuali = data.orecontrattuali ?? 24
        this.turnista = data.turnista ?? 0;
        this.ral = data.ral ?? 0;
        this.diaria = data.diaria ?? 0;
        this.benefit = data.benefit ?? 0;
        this.ticket = data.ticket ?? 0;
    }
}
const RapportoSchema = Joi.object({
    societa: Joi.object({
        id: Joi.string().required().messages({
            "any.required": 'Il campo "SOCIETÀ" è obbligatorio',
            "string.empty": 'Il campo "SOCIETÀ" è obbligatorio',
        }),
        descrizione: Joi.string().required().messages({
            "any.required": 'Il campo "SOCIETÀ" è obbligatorio',
            "string.empty": 'Il campo "SOCIETÀ" è obbligatorio',
        }),
        giorniFerie: Joi.number().required().messages({
            "any.required": 'Il campo "SOCIETÀ" è obbligatorio',
            "number.base": 'Il campo "SOCIETÀ" è obbligatorio',
        }),
    }).required()
        .messages({
            "object.base": 'Il campo "SOCIETÀ" è obbligatorio',
            "any.required": 'Il campo "SOCIETÀ" è obbligatorio',
        }),
    profili: Joi.array()
        .min(1)
        .items(
            Joi.object({
                id: Joi.string().required().messages({
                    "string.base": 'Il campo "PROFILI" è obbligatorio',
                    "any.required": 'Il campo "PROFILI" è obbligatorio',
                }),
                descrizione: Joi.string().required().messages({
                    "string.base": 'Il campo "PROFILI" è obbligatorio',
                    "any.required": 'Il campo "PROFILI" è obbligatorio',
                }),
            }).required().messages({
                "any.required": 'Il campo "PROFILI" è obbligatorio',
            })
        )
        .required()
        .messages({
            "array.base": 'Il campo "PROFILI" è obbligatorio',
            "array.includes": 'Il campo "PROFILI" è obbligatorio',
            "array.min": 'Il campo "PROFILI" è obbligatorio',
            "any.required": 'Il campo "PROFILI" è obbligatorio',
            "any.empty": 'Il campo "PROFILI" è obbligatorio',
        }),
    ruoli: Joi.array()
        .items(
            Joi.object({
                id: Joi.string().messages(),
                descrizione: Joi.string().messages()
            })),
    matricola: Joi.string().required().messages({
        "string.empty": 'Il campo "MATRICOLA" è obbligatorio',
        "any.required": 'Il campo "MATRICOLA" è obbligatorio',
    }),
    codicepaco: Joi.string().required().messages({
        "string.empty": 'Il campo "CODICE PACO" è obbligatorio',
        "any.required": 'Il campo "CODICE PACO" è obbligatorio',
    }),
    aziendaterzaparte: Joi.object({
        id: Joi.string().allow(null, '').required().messages({
            "string.empty": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
            "any.required": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
            "string.min": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
        }),
        descrizione: Joi.string().allow(null, '').required().messages({
            "string.empty": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
            "any.required": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
            "string.min": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
        }),
    }).required().allow(null).messages({
        "string.empty": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
        "any.required": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
        "string.min": 'Il campo "AZIENDA TERZA PARTE" è obbligatorio',
    }),
    datainiziorapporto: Joi.date().required().messages({
        'any.required': 'Il campo "DATA INIZIO RAPPORTO" è obbligatorio',
        'date.base': 'Il campo "DATA INIZIO RAPPORTO" deve essere una data valida',
    }),
    datafinerapporto: Joi.date().allow(null).when('datainiziorapporto', {
        is: Joi.date().required(),
        then: Joi.date().min(Joi.ref('datainiziorapporto')).messages({
            'date.base': 'Il campo "DATA FINE RAPPORTO" deve essere una data valida',
            'date.min': 'La "DATA FINE RAPPORTO" non può essere antecedente alla "DATA INIZIO RAPPORTO"',
        }),
    }),
    sede: Joi.object({
        id: Joi.string().required(),
        descrizione: Joi.string().required().messages({
            "any.required": 'Il campo "SEDE" è obbligatorio',
            "string.empty": 'Il campo "SEDE" è obbligatorio',
        }),
    }).required().allow(null),
    referente: Joi.object({
        id: Joi.string().required().messages({
            "string.empty": 'Il campo "REFERENTE/DTC" è obbligatorio',
            "any.required": 'Il campo "REFERENTE/DTC" è obbligatorio',
            "string.min": 'Il campo "REFERENTE/DTC" è obbligatorio',
        }),
        descrizione: Joi.string().required().messages({
            "string.empty": 'Il campo "REFERENTE/DTC" è obbligatorio',
            "any.required": 'Il campo "REFERENTE/DTC" è obbligatorio',
            "string.min": 'Il campo "REFERENTE/DTC" è obbligatorio',
        }),
    }).required().messages({
        "object.base": 'Il campo "REFERENTE/DTC" è obbligatorio',
        "any.required": 'Il campo "REFERENTE/DTC" è obbligatorio',
    }),
    orecontrattuali: Joi.number().min(1).required().messages({
        "number.base": 'Il campo "ORE CONTRATTUALI" deve essere un numero',
        "number.min": 'Il campo "ORE CONTRATTUALI" deve essere maggiore o uguale a 1',
        "any.required": 'Il campo "ORE CONTRATTUALI" è obbligatorio',
    }),
    turnista: Joi.number().valid(0, 1).required(),
    ral: Joi.number().min(0).required().messages({
        "number.base": 'Il campo "RAL" deve essere un numero',
        "number.min": 'Il campo "RAL" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "RAL" è obbligatorio',
    }),
    diaria: Joi.number().min(0).required().messages({
        "number.base": 'Il campo "DIARIA" deve essere un numero',
        "number.min": 'Il campo "DIARIA" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "DIARIA" è obbligatorio',
    }),
    benefit: Joi.number().min(0).required().messages({
        "number.base": 'Il campo "BENEFIT" deve essere un numero',
        "number.min": 'Il campo "BENEFIT" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "BENEFIT" è obbligatorio',
    }),
    ticket: Joi.number().min(0).required().messages({
        "number.base": 'Il campo "TICKET" deve essere un numero',
        "number.min": 'Il campo "TICKET" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "TICKET" è obbligatorio',
    }),
});

//
// Skill
//
class Skill {
    constructor(data = {}) {
        this.id = data.id ?? "";
        this.mercatodiriferimento = data.mercatodiriferimento ?? "";
        this.tipologia = data.tipologia ?? "";
        this.tiposkill = data.tiposkill ?? "";
        this.livello = data.livello ?? "";
        this.livellonumber = data.livellonumber ?? null;
        this.dataacquisizione = data.dataacquisizione ?? null;
        this.datascadenza = data.datascadenza ?? null;
    }
}
class SkillSet {
    constructor(data = {}) {
        this.skills = Array.isArray(data.skills)
            ? data.skills.map(skillData => new Skill(skillData))
            : [];
    }
}
const SkillSchema = Joi.object({
    id: Joi.string().allow('').required(),
    mercatodiriferimento: Joi.string().allow('').required(),
    tipologia: Joi.string().required(),
    tiposkill: Joi.string().required(),
    livello: Joi.string().trim().required().messages({
        "string.empty": 'Il campo "LIVELLO" è obbligatorio',
        "any.required": 'Il campo "LIVELLO" è obbligatorio',
    }),
    livellonumber: Joi.number().required().messages({
        "number.base": 'Il campo "LIVELLO" è obbligatorio',
        "any.required": 'Il campo "LIVELLO" è obbligatorio',
    }),
    dataacquisizione: Joi.date().iso().required().messages({
        'any.required': 'Il campo "DATA ACQUISIZIONE" è obbligatorio',
        'date.base': 'Il campo "DATA ACQUISIZIONE" è obbligatorio',
    }),
    datascadenza: Joi.date().iso().required().allow(null),
});
const SkillSetSchema = Joi.object({
    skills: Joi.array().items(SkillSchema).required()
});
// Per controllare se è stato installato joi completo con il describe
// const schemaDescription = SkillSetSchema.describe();
// console.warn("** SkillSetSchema dscription", schemaDescription);


//
// Documento
//
class Documento {
    constructor(data = {}) {
        this.id = data.id ?? "";
        this.url = data.url ?? "";
        this.descrizione = data.descrizione ?? "";
        this.tipo = data.tipo ?? "";
        this.datacaricamento = data.datacaricamento ?? null;
        this.dimensione = data.dimensione ?? 0;
        this.filetype = data.filetype ?? "";
    }
}
class DocumentoSet {
    constructor(data = {}) {
        this.documenti = Array.isArray(data.documenti)
            ? data.documenti.map(documentoData => new Documento(documentoData))
            : [];
    }
}
const DocumentoSchema = Joi.object({
    id: Joi.string().allow('').required(),
    url: Joi.string().required(),
    descrizione: Joi.string().allow('').required(),
    tipo: Joi.string().allow('').required(),
    datacaricamento: Joi.date().iso().required().allow(null),
    dimensione: Joi.number().required().default(0),
    filetype: Joi.string().required()
});
const DocumentoSetSchema = Joi.object({
    documenti: Joi.array().items(DocumentoSchema).required()
});



class Dotazione {
    constructor(data = {}) {
        this.id = data.id || "";
        this.tipologia = data.tipologia || "";
        this.descrizione = data.descrizione || "";
        this.matricola = data.matricola || "";
        this.dataconsegna = data.dataconsegna || null;
        this.stato = data.stato || "";
        this.datariconsegna = data.datariconsegna || null;
    }
}

class Gruppo {
    constructor(data = {}) {
        this.abilitazione = {
            id: data.abilitazione?.id || "",
            descrizione: data.abilitazione?.descrizione || "",
        };
        this.gruppo = {
            id: data.gruppo?.id || "",
            descrizione: data.gruppo?.descrizione || "",
        };
    }
}


class Ticket {
    constructor(data = {}) {
        this.id = data.id || "";
        this.tipologia = data.tipologia || "";
        this.descrizione = data.descrizione || "";
        this.matricola = data.matricola || "";
        this.causale_motivazione = data.causale_motivazione || "";
        this.datascadenza = data.datascadenza || null;
        this.note = data.note || "";
        this.accettato = data.accettato || false;
        this.chiuso = data.chiuso || false;
        this.utente = data.utente || "";
        this.stato = data.stato || "";
    }
}

class User {
    constructor(data = {}) {
        this._id = data._id || "";
        this.providerUserId = data.providerUserId || "";
        this.email = data.email || "";
        this.metadata = {
            fullname: data.metadata?.fullname || "",
            isSuperAdmin: data.metadata?.isSuperAdmin || false,
            oldId: data.metadata?.oldId || 0,
            deleted: data.metadata?.deleted || 0,
            country: data.metadata?.country || "",
            manager: data.metadata?.manager || "",
            anagrafica: new Anagrafica(data.metadata?.anagrafica),
            rapporto: new Rapporto(data.metadata?.rapporto),
            skills: (data.metadata?.skills || []).map((skill) => new Skill(skill)),
            documenti: (data.metadata?.documenti || []).map((doc) => new Documento(doc)),
            dotazioni: (data.metadata?.dotazioni || []).map((dot) => new Dotazione(dot)),
            gruppi: (data.metadata?.gruppi || []).map((grp) => new Gruppo(grp)),
            tickets: (data.metadata?.tickets || []).map((ticket) => new Ticket(ticket)),
            attivita: [],
        };
        this.commesse = data.commesse || [];
    }
}

const DotazioneSchema = Joi.object({
    id: Joi.string().required(),
    tipologia: Joi.string().required(),
    descrizione: Joi.string().required(),
    matricola: Joi.string().required(),
    dataconsegna: Joi.date().iso(),
    stato: Joi.string().allow(""),
    datariconsegna: Joi.date().iso().allow(null),
});

const GruppoSchema = Joi.object({
    abilitazione: Joi.object({
        id: Joi.string().required(),
        descrizione: Joi.string().required(),
    }).required(),
    gruppo: Joi.object({
        id: Joi.string().required(),
        descrizione: Joi.string().required(),
    }).required(),
});

const TicketSchema = Joi.object({
    id: Joi.string().required().allow(""),
    tipologia: Joi.string().required(),
    descrizione: Joi.string().required(),
    matricola: Joi.string().required(),
    causale_motivazione: Joi.string().required(),
    datascadenza: Joi.date().iso().allow(null),
    note: Joi.string().allow(""),
    accettato: Joi.boolean(),
    chiuso: Joi.boolean(),
    utente: Joi.string().required(),
    stato: Joi.string().required().allow(""),
});

const UserSchema = Joi.object({
    _id: Joi.string().allow(""),
    providerUserId: Joi.string().required(),
    // email: Joi.string().custom(customEmailValidation, 'custom email validation').required(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": 'Il campo "EMAIL" è obbligatorio',
            "string.email": 'Il campo "EMAIL" non è un formato e-mail valido',
        }),
    metadata: Joi.object({
        fullname: Joi.string().required(),
        isSuperAdmin: Joi.boolean().required(),
        oldId: Joi.number().integer().min(0).required(),
        deleted: Joi.number().integer().valid(0, 1).required(),
        country: Joi.string().required(),
        manager: Joi.string().allow(""),
        anagrafica: AnagraficaSchema,
        rapporto: RapportoSchema,
        skills: Joi.array().items(SkillSchema),
        documenti: Joi.array().items(DocumentoSchema),
        dotazioni: Joi.array().items(DotazioneSchema),
        gruppi: Joi.array().items(GruppoSchema),
        tickets: Joi.array().items(TicketSchema),
        attivita: Joi.array().items(Joi.object()),
    }).required(),
});

export {
    UserSchema,
    User,
    Anagrafica,
    Documento,
    DocumentoSet,
    Dotazione,
    Gruppo,
    Rapporto,
    Skill,
    SkillSet,
    Ticket,
    AnagraficaSchema,
    DocumentoSchema,
    DocumentoSetSchema,
    DotazioneSchema,
    GruppoSchema,
    RapportoSchema,
    SkillSchema,
    SkillSetSchema,
    TicketSchema,
};