<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <!-- Aggiungi Allegato -->
  <div class="container-fluid m-0 p-0 pt-4">
    <div class="form-group p-0">
      <div class="input-group">
        <span class="input-group-text px-3"><i class="zmdi zmdi-file"></i></span>
        <input
          type="file"
          ref="fileInput"
          :accept="acceptedFileTypes"
          @change="handleFileUpload($event, index)"
          class="d-none"
        />
        <input
          type="text"
          class="form-control form-browse"
          placeholder="Scegliere un allegato..."
          disabled
        />
        <button
          @click="triggerFileInput"
          class="btn button-outline-1 small px-4"
          type="button"
        >
          <i class="fa-solid fa-folder-open"></i>Aggiungi Allegato
        </button>
      </div>
    </div>
  </div>
  <!-- No data -->
  <div class="mt-4 mb-5" v-if="localDocumento.documenti.length == 0">
    <h5>Nessun dato da visualizzare</h5>
  </div>
  <!-- Table Documenti -->
  <div v-if="localDocumento.documenti.length" class="container-fluid m-0 p-0 pt-5">
    <div class="row m-0 mb-3">
      <table class="table-bordered table-responsive-md table-setup">
        <thead class="table-header-font">
          <tr class="td-center">
            <th>DESCRIZIONE</th>
            <th width="250">TIPO DOCUMENTO</th>
            <th width="180">DATA CARICAMENTO</th>
            <th width="160">DIMENSIONE</th>
            <th width="80">TIPO FILE</th>
            <th style="min-width: 130px !important; width: 130px !important">AZIONI</th>
          </tr>
        </thead>
        <!-- Table Records -->
        <tbody class="table-body-font td-vertical-center">
          <tr v-for="(documento, index) in localDocumento.documenti" :key="index">
            <td>{{ documento.descrizione }}</td>
            <td class="td-center">
              <ul class="list-tags">
                <li v-if="documento.tipo != ''">
                  <span
                    class="badge-colored fucsia"
                    :title="documento.tipo"
                    :alt="documento.tipo"
                    >{{ documento.tipo }}</span
                  >
                </li>
              </ul>
            </td>
            <td class="td-center">
              {{ mixins_getLocalDate(documento.datacaricamento) }}
            </td>
            <td class="td-center">{{ mixins_formatFileSize(documento.dimensione) }}</td>
            <td class="td-center">
              <ul class="list-tags">
                <li>
                  <span
                    class="badge-colored green"
                    :title="documento.filetype"
                    :alt="documento.filetype"
                    >{{ documento.filetype }}</span
                  >
                </li>
              </ul>
            </td>
            <td class="td-actions td-center">
              <button
                @click="editDocumento(documento, index)"
                alt="MODIFICA"
                title="MODIFICA"
              >
                <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
              </button>
              <button
                @click="viewDocumento(documento.url)"
                alt="VISUALIZZA"
                title="VISUALIZZA"
              >
                <i class="bi bi-eye-fill"></i>
              </button>
              <button
                @click="removeDocumento(index)"
                class="delete"
                alt="ELIMINA"
                title="ELIMINA"
              >
                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Errors model container -->
  <div class="d-flex justify-content-center m-0 pb-4 row">
    <div class="col-lg-7 p-0">
      <!-- Errors model -->
      <div v-if="errorsModel" class="mb-4">
        <div
          v-for="(errorModel, key) in errorsModel"
          :key="key"
          class="text-danger small mt-1"
        >
          {{ errorModel }}pippo
        </div>
      </div>
    </div>
  </div>
  <DialogEditDocumento
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="dialogTitle"
    :dataItem="selectedDocumento"
    :index="selectedIndex"
    @closeModal="closeModal"
    @saveDocumento="saveDocumento"
  />
</template>

<script>
import {
  DocumentoSet,
  DocumentoSetSchema,
  Documento,
  DocumentoSchema,
} from "@/models/TeamJoiModel";
import {
  fetchUserDocumento,
  uploadFileDocument,
  updateUserDocumento,
} from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DialogEditDocumento from "@/components/dialogs/DialogEditDocumento.vue";

export default {
  mixins: [UtilityMixins],
  components: {
    Loading,
    DialogEditDocumento,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    userDataDocumento: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:userDataDocumento",
  ],
  data() {
    return {
      localDocumento: new DocumentoSet(), // Creo un oggetto per gestire i dati locali
      initialDocumento: null, // Memorizzo lo stato iniziale per il confronto
      isLoading: false, // Gestione del loader per indicare il caricamento
      errorsModel: null, // Oggetto per memorizzare gli errori di validazione
      acceptedFileTypes: ".pdf,.txt,image/*,.zip", // accetta PDF, TXT, immagini e ZIP
      acceptedTypes: [
        // MIME types dei file accettati
        "application/pdf",
        "text/plain",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/webp",
        "application/zip", // MIME type per file ZIP
        "application/x-zip-compressed", // Altri possibili MIME types per ZIP
      ],
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      selectedDocumento: {},
      selectedIndex: null,
    };
  },
  computed: {},
  watch: {
    // Osservo il cambiamento di userDataDocumento per aggiornare i dati locali
    userDataDocumento: {
      immediate: true, // Eseguo l'handler al montaggio
      handler(newVal) {
        // Aggiorno localDocumento e i modelli locali al cambio dei dati
        this.localDocumento = new DocumentoSet(newVal);
        this.initialDocumento = JSON.parse(JSON.stringify(this.localDocumento));
      },
    },
  },
  methods: {
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      try {
        const response = await fetchUserDocumento(this.$route.params.id);
        if (response) {
          // Aggiorno i dati locali con la risposta
          this.localDocumento = new DocumentoSet(response);
          this.initialDocumento = JSON.parse(JSON.stringify(this.localDocumento));

          console.log("** dati localDocumento", this.localDocumento);
          console.log("** dati initialDocumento", this.initialDocumento);

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:userDataDocumento", this.localDocumento);
          this.$emit("fetch-data", "documenti");
          this.$parent.errorMessage = "";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.mixins_showMessage(
          "Caricamento dati",
          "Errore durante il caricamento dei dati",
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati dello documento
        await this.fetchData();
      }
    },
    //
    // Gestione aggiornamenti
    //
    updateParent() {
      console.warn("** localDocumento prima di validarlo", this.localDocumento);

      if (JSON.stringify(this.localDocumento) !== JSON.stringify(this.initialDocumento)) {
        this.markAsModified("documenti");

        console.log("** diversi");

        if (this.validateDocumenti()) {
          this.$emit("update:userDataDocumento", this.localDocumento);
          this.$emit("data-updated", this.localDocumento);
        } else {
          console.error("Errore nella validazione dei dati.");
        }
      }
    },
    //
    // ** Gestione Validazioni **
    //
    validateDocumenti() {
      this.errorsModel = {}; // Reset degli errori

      const { error } = DocumentoSetSchema.validate(this.localDocumento, {
        abortEarly: false,
      });

      if (error) {
        error.details.forEach((err) => {
          // Aggiungo l'errore al modello di errori
          this.errorsModel[err.path.join(".")] = err.message;
        });

        this.mixins_showMessage(
          "Validazione",
          "Si sono verificati degli errori nei dati del documento",
          "error"
        );
        this.markAsVerified("documenti", false);
        return false;
      }
      this.markAsVerified("documenti", true);
      return true;
    },
    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    //
    // Gestione upload di un allegato
    //
    triggerFileInput() {
      // Attiva l'input file per un determinato documento
      this.$refs.fileInput.click();
    },
    resetFileInput() {
      // Resetta l'input file per permettere un nuovo upload dello stesso file
      this.$refs.fileInput.value = "";
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) return;

      // Verifica se il file è del tipo accettato
      if (!this.acceptedTypes.includes(file.type)) {
        this.mixins_showMessage(
          "Tipo di file non supportato",
          "Sono accettati solo PDF, TXT, ZIP o Immagini",
          "warning"
        );
        return;
      }

      if (file) {
        this.loading = true;

        const fileSize = file.size;

        try {
          const response = await uploadFileDocument(file);
          if (response && response.url) {
            console.log("** document url", response.url);
            this.mixins_showMessage(
              "Aggiungi Allegato",
              "Documento caricato correttamente",
              "info"
            );

            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            };
            const now = new Date().toLocaleString("it-IT", options);

            // Creazione di un nuovo documento
            const nuovoDocumento = new Documento({
              id: "", // sarà generato dal server
              url: response.url,
              descrizione: "Nuovo documento " + now,
              tipo: "",
              datacaricamento: new Date().toISOString(),
              dimensione: fileSize,
              filetype: file.name.split(".").pop().toUpperCase(),
            });

            console.log("** nuovoDocumento", nuovoDocumento);

            // Aggiunta del documento all'array dei documenti
            this.localDocumento.documenti.push(nuovoDocumento);

            // Validazione dei documenti prima dell'invio al server
            const isValid = this.validateDocumenti();

            if (isValid) {
              // Invio i documenti aggiornati al server
              await this.sendDocumentiAlServer();
            }
          }
        } catch (error) {
          this.mixins_showMessage(
            "Upload",
            `Errore durante il caricamento del documento: ${error}`,
            "error"
          );
        } finally {
          this.resetFileInput(); // Reset dell'input file dopo il caricamento
          this.loading = false;
        }
      }
    },
    async sendDocumentiAlServer() {
      try {
        // Inviare i documenti al server
        await updateUserDocumento(this.$route.params.id, this.localDocumento); // Metodo per salvare i documenti al server
        this.mixins_showMessage("Salvataggio", "Documenti salvati correttamente", "info");
      } catch (error) {
        console.error("Errore durante l'invio dei documenti al server:", error);
        this.mixins_showMessage(
          "Errore",
          "Errore durante il salvataggio dei documenti",
          "error"
        );
      }
    },
    removeDocumento(index) {
      // Rimuovo il documento dall'array localDocumento.documenti
      this.localDocumento.documenti.splice(index, 1);

      // Aggiorno lo stato locale e valido nuovamente
      this.updateParent();
      this.validateDocumenti();
    },
    //
    // Gestione dialog
    //
    editDocumento(documento, index) {
      this.dialogTitle = "MODIFICA DOCUMENTO";
      this.selectedDocumento = { ...documento }; // Crea una copia dell'oggetto documento
      console.log("** this.selectedDocumento", this.selectedDocumento);
      this.selectedIndex = index;
      this.isDialogVisible = true;
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    saveDocumento(documento, index) {
      const { error, value } = DocumentoSchema.validate(documento);
      if (error) {
        // Handle validation errors
        return;
      }
      if (index !== null) {
        this.localDocumento.documenti[index] = value;
      }
      this.updateParent();
      this.validateDocumenti();
      this.closeModal();
    },
    viewDocumento(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.mixins_showMessage("Errore", "Documento non disponibile", "error");
      }
    },
  },
};
</script>
