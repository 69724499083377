<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE DOTAZIONI
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-desktop fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Loading -->
        <div v-if="loading" class="linear-loader"><span></span></div>
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <div v-if="localDotazione && !errorMessage && !loading">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="color-white m-0 p-0">
                <span v-html="localDotazione.descrizione || blank"></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>
          <div class="row m-0 padding-x-4px" v-if="localDotazione">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <li class="nav-item">
                <a
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex active"
                  data-bs-toggle="tab"
                >
                  ANAGRAFICA
                  <span class="ms-2 change-data" v-if="isModified.anagrafica"></span>
                </a>
              </li>
              <li v-if="isEditMode" class="nav-item">
                <a
                  href="#contentAssegnazioni"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                >
                  ASSEGNAZIONI
                  <span class="ms-2 change-data" v-if="isModified.assegnazioni"></span>
                </a>
              </li>
            </ul>
            <div class="tab-content p-0">
              <div class="tab-pane fade show active" id="contentAnagrafica">
                <div class="row m-0 pt-4">
                  <div class="col-lg-3 mb-3 p-0 text-center">
                    <img
                      style="border: 1px solid var(--mainBlue)"
                      :src="
                        localDotazione.logo
                          ? localDotazione.logo
                          : require('@/assets/images/logo-placeholder.jpg')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/dotazione-placeholder.jpg')
                      "
                      class="img-fluid w-100 mb-3"
                    />
                    <div>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="CARICA IMMAGINE"
                        title="CARICA IMMAGINE"
                        @click="uploadImage"
                        :disabled="!isEditMode"
                      >
                        <i class="bi bi-image"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="RIMUOVI IMMAGINE"
                        title="RIMUOVI IMMAGINE"
                        @click="removeImage"
                        :disabled="!isEditMode || localDotazione.logo === ''"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                      <input
                        type="file"
                        ref="fileInput"
                        @change="onFileChange"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                  </div>
                  <div class="col-lg-9 p-0 ps-lg-4">
                    <div class="row mb-1">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>TIPOLOGIA</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <MultiSelect
                          v-model="selectedLocalModels.tipologia"
                          :options="dotazioniOptions"
                          :searchable="true"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :show-no-results="true"
                          selectLabel="Seleziona"
                          deselectLabel="Rimuovi"
                          selectedLabel="Selezionato"
                          :openDirection="'below'"
                          tagPlaceholder="Premi enter per creare un tag"
                          :multiple="true"
                          :taggable="false"
                          :showLabels="false"
                          @update:modelValue="onTipologiaUpdate"
                        >
                          <template #clear>
                            <div
                              v-if="
                                selectedLocalModels.tipologia &&
                                selectedLocalModels.tipologia.length
                              "
                              class="multiselect__clear"
                              @mousedown.prevent.stop="onTipologiaClear"
                            ></div>
                          </template>
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>DESCRIZIONE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          v-model.trim="localDotazione.descrizione"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="DESCRIZIONE"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>MATRICOLA</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          v-model.trim="localDotazione.matricola"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="MATRICOLA"
                        />
                      </div>
                    </div>
                    <div class="row mb-1">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>ANNO ACQUISTO</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="false"
                          v-model="selectedLocalModels.annoacquisto"
                          :options="annoacquistoOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          @update:modelValue="onAnnoacquistoUpdate"
                          :maxHeight="210"
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>COSTO STORICO</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model.trim="localDotazione.costostorico"
                          @input="markAsModified('anagrafica')"
                          step="0.01"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="COSTO STORICO"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>AMMORTAMENTO ANNI</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="false"
                          v-model="selectedLocalModels.ammortamentoanni"
                          :options="ammortamentoanniOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          @update:modelValue="onAmmortamentoanniUpdate"
                          :maxHeight="210"
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                    <!-- Errors model -->
                    <div v-if="errorsModel" class="mb-4">
                      <div
                        v-for="(errorModel, key) in errorsModel"
                        :key="key"
                        class="text-danger small mt-1"
                      >
                        {{ errorModel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="contentAssegnazioni">
                <div
                  class="align-items-center container-fluid d-flex flex-row justify-content-end pt-4 px-0"
                >
                  <button
                    @click="openAddAssegnazione"
                    type="button"
                    class="btn button-outline-1 small"
                    :disabled="!isReturned"
                  >
                    <span><i class="zmdi zmdi-plus"></i>Aggiungi Assegnatario</span>
                  </button>
                </div>
                <!-- No data -->
                <div v-if="localDotazione.assegnatoa.length == 0">
                  <h5 class="mt-1">Nessun dato da visualizzare</h5>
                </div>
                <div
                  v-if="localDotazione.assegnatoa.length > 0"
                  class="container-fluid m-0 p-0 pt-4"
                >
                  <div class="row m-0">
                    <table class="table-bordered table-responsive-md table-setup">
                      <thead class="table-header-font">
                        <tr class="td-center">
                          <th>ASSEGNATO A</th>
                          <th width="200">DATA ASSEGNAZIONE</th>
                          <th width="200">DATA RICONSEGNA</th>
                          <th
                            style="min-width: 100px !important; width: 100px !important"
                          >
                            AZIONI
                          </th>
                        </tr>
                      </thead>
                      <!-- Table Records -->
                      <tbody class="table-body-font td-vertical-center">
                        <tr
                          v-for="(
                            assegnatario, index
                          ) in sortedAssegnatoaByDatariconsegna"
                          :key="assegnatario.id"
                        >
                          <td>{{ assegnatario.fullname }}</td>
                          <td class="td-center">
                            {{ mixins_getLocalDate(assegnatario.dataassegnazione) }}
                          </td>
                          <td class="td-center">
                            {{ mixins_getLocalDate(assegnatario.datariconsegna) }}
                          </td>
                          <!-- Actions -->
                          <td class="td-actions td-center">
                            <button
                              alt="MODIFICA"
                              title="MODIFICA"
                              @click="editAssegnazione(assegnatario, index)"
                            >
                              <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                            </button>
                            <button
                              class="delete"
                              alt="ELIMINA"
                              title="ELIMINA"
                              @click="removeAssegnazione(index)"
                            >
                              <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                @click="backToList()"
                type="button"
                class="btn button-outline-1 float-start"
              >
                <span><i class="zmdi zmdi-arrow-left"></i>Torna alla lista</span>
              </button>
              <button
                type="button"
                class="btn button-outline-1 float-end"
                :class="{ notSaved: isModified.anagrafica || isModified.assegnazioni }"
                @click="saveDotazione"
              >
                <span
                  ><i class="zmdi zmdi-floppy"></i
                  >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditAssegnazione
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="dialogTitle"
    :dataItem="selectedAssegnazione"
    :index="selectedIndex"
    @closeModal="closeModal"
    @saveAssegnazione="saveAssegnazione"
  />
</template>

<script>
import { Dotazione, DotazioneSchema, Assegnazione } from "@/models/DotazioneJoiModel";
import {
  fetchDotazioneById,
  createDotazione,
  updateDotazione,
  uploadLogoDotazione,
  deleteLogoDotazione,
} from "@/services/api";
import DialogAddEditAssegnazione from "@/components/dialogs/DialogAddEditAssegnazione.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import ammortamentoAnni from "@/data/ammortamento_anni.json";
import { mapGetters } from "vuex";

export default {
  mixins: [UtilityMixins],
  components: {
    DialogAddEditAssegnazione,
  },
  data() {
    return {
      model_dateFilter: new Date(),
      localDotazione: null,
      loading: false,
      errorMessage: null,
      errorsModel: null,
      isEditMode: false,
      isModified: {
        anagrafica: false,
        assegnazioni: false,
      },
      headerTitle: "",
      isDialogVisible: false,
      dialogTitle: "",
      selectedIndex: null,
      blank: `&nbsp;`,
      ammortamentoanniOptions: [],
      annoacquistoOptions: [],
      dotazioniOptions: [],
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        tipologia: [],
        annoacquisto: [],
        ammortamentoanni: [],
      },
      selectedAssegnazione: [],
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageDotazioni"]),
    isReturned() {
      const assegnazioni = this.localDotazione.assegnatoa;
      // Se l'array è vuoto, restituisci true
      if (!assegnazioni || assegnazioni.length === 0) {
        return true;
      }
      // Controllo se ogni oggetto ha un campo "datariconsegna" valorizzato

      const result = assegnazioni.every(
        (assegnazione) =>
          assegnazione.datariconsegna !== null &&
          assegnazione.datariconsegna !== undefined
      );
      console.warn(result);
      return result;
    },
    sortedAssegnatoaByDatariconsegna() {
      return this.localDotazione.assegnatoa.slice().sort((a, b) => {
        const dateA = new Date(a.datariconsegna);
        const dateB = new Date(b.datariconsegna);
        return dateB - dateA; // Ordina dal più recente al meno recente
      });
    },
    sortedAssegnatoaByDataassegnazione() {
      return this.localDotazione.assegnatoa.slice().sort((a, b) => {
        const dateA = new Date(a.dataassegnazione);
        const dateB = new Date(b.dataassegnazione);
        return dateB - dateA; // Ordina dal più recente al meno recente
      });
    },
  },
  methods: {
    async fetchDotazione() {
      this.loading = true;
      try {
        const response = await fetchDotazioneById(this.$route.params.id);
        if (response) {
          this.localDotazione = new Dotazione(response);
          this.updateLocalModels(); // Sincronizzo i modelli locali
          this.isEditMode = true;
          this.headerTitle = "Modifica";

          console.log("** dotazione", this.localDotazione);
        } else {
          this.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        this.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        this.loading = false;
      }
    },

    async saveDotazione() {
      // controllo che ci siano dati da salvare
      if (!this.hasUnsavedChanges()) return;

      // valido i campi
      if (!this.validateBeforeSave()) return;

      // Sincronizzo i modelli locali
      this.updateLocalModels();

      // salvo i dati
      this.loading = true;
      try {
        if (this.isEditMode) {
          await updateDotazione(this.localDotazione._id, this.localDotazione);
          this.mixins_showMessage(
            "Aggiornamento dati",
            "Dati salvati correttamente",
            "info"
          );
        } else {
          const newDotazione = await createDotazione(this.localDotazione);
          this.isEditMode = true;
          this.headerTitle = "Modifica";
          this.$router.replace({
            name: "route-dotazioni-edit",
            params: { id: newDotazione._id },
          });
        }
        this.isModified.anagrafica = false;
        this.isModified.assegnazioni = false;
        this.errorsModel = null;
        await this.fetchDotazione();
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
        this.errorMessage = "Errore durante il salvataggio dei dati";
      } finally {
        this.loading = false;
      }
    },
    showAnagraficaTab() {
      this.$refs.tabs.querySelector('a[href="#contentAnagrafica"]').click();
    },
    markAsModified(section) {
      this.isModified[section] = true;
    },
    backToList() {
      this.$router.push({
        name: "route-dotazioni",
        query: { page: this.getCurrentPageDotazioni },
      });
    },
    openAddAssegnazione() {
      this.dialogTitle = "CREA NUOVO ASSEGNATARIO";
      this.selectedAssegnazione = new Assegnazione();
      this.selectedIndex = null;
      this.isDialogVisible = true;
    },

    editAssegnazione(assegnazione, index) {
      this.dialogTitle = "MODIFICA ASSEGNATARIO";
      this.selectedAssegnazione = new Assegnazione(assegnazione);
      this.selectedIndex = index;
      this.isDialogVisible = true;
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    saveAssegnazione(assegnazione, index) {
      if (index !== null) {
        this.localDotazione.assegnatoa[index] = new Assegnazione(assegnazione);
      } else {
        this.localDotazione.assegnatoa.push(new Assegnazione(assegnazione));
      }
      this.markAsModified("assegnazioni");
      this.closeModal();
    },
    removeAssegnazione(index) {
      this.localDotazione.assegnatoa.splice(index, 1);
      this.markAsModified("assegnazioni");
    },

    //
    // Gestione Multiselect
    //
    //
    // Tipologia
    //
    onTipologiaUpdate(selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        this.localDotazione.tipologia = selected.map((tipologia) => ({
          idtipologia: tipologia.value,
          descrizione: tipologia.label,
        }));
      } else {
        this.localDotazione.tipologia = null;
      }
      this.markAsModified("anagrafica");
    },
    onTipologiaClear() {
      this.localDotazione.tipologia = null;
      this.selectedLocalModels.tipologia = null;
      this.markAsModified("anagrafica");
    },
    //
    // Anno di acquisto
    //
    onAnnoacquistoUpdate(selected) {
      this.localDotazione.annoacquisto =
        selected && selected.value >= 0 ? selected.value : null;
      this.markAsModified("anagrafica");
    },
    //
    // Ammortamento anni
    //
    onAmmortamentoanniUpdate(selected) {
      this.localDotazione.ammortamentoanni =
        selected && selected.value >= 0 ? selected.value : null;
      this.markAsModified("anagrafica");
    },
    updateLocalModels() {
      // Sincronizzo i dati ricevuti

      // tipologia
      if (
        Array.isArray(this.localDotazione.tipologia) &&
        this.localDotazione.tipologia.length > 0
      ) {
        this.selectedLocalModels.tipologia = this.localDotazione.tipologia.map(
          (tipologia) => ({
            value: tipologia.idtipologia,
            label: tipologia.descrizione,
          })
        );
      } else {
        this.selectedLocalModels.tipologia = null;
      }

      // anno acquisto
      this.selectedLocalModels.annoacquisto =
        this.localDotazione.annoacquisto !== undefined
          ? this.annoacquistoOptions.find(
              (item) => item.value === this.localDotazione.annoacquisto
            )
          : [];

      // ammortamento anni
      this.selectedLocalModels.ammortamentoanni =
        this.localDotazione.ammortamentoanni !== undefined
          ? this.ammortamentoanniOptions.find(
              (item) => item.value == this.localDotazione.ammortamentoanni
            )
          : [];
    },
    //
    // ** Gestione validazioni **
    //
    validateBeforeSave() {
      const result = DotazioneSchema.validate(this.localDotazione, {
        abortEarly: false,
      });
      if (result.error) {
        this.errorsModel = result.error.details.reduce((acc, curr) => {
          acc[curr.path[0]] = curr.message;
          return acc;
        }, {});
        this.showAnagraficaTab();
        return false;
      } else {
        return true;
      }
    },
    //
    // Gestione immagine
    //
    async uploadImage() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        try {
          const response = await uploadLogoDotazione(this.localDotazione._id, file);
          if (response && response.url) {
            this.localDotazione.logo = response.url;
          }
        } catch (error) {
          console.error("Errore durante il caricamento del logo", error);
          this.errorMessage = "Errore durante il caricamento del logo";
        } finally {
          this.loading = false;
        }
      }
    },
    async removeImage() {
      if (!this.localDotazione.logo) return;
      this.loading = true;
      try {
        const response = await deleteLogoDotazione(this.localDotazione._id);
        if (response) {
          this.localDotazione.logo = "";
        }
      } catch (error) {
        console.error("Errore durante la cancellazione del logo", error);
        this.errorMessage = "Errore durante la cancellazione del logo";
      } finally {
        this.loading = false;
      }
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return this.isModified.anagrafica || this.isModified.assegnazioni;
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  async created() {
    // Carico le opzioni della tipologia dotazioni
    const tabDotazioni = await this.mixins_getTabDotazioni();
    this.dotazioniOptions = tabDotazioni.map((item) => ({
      value: item.id,
      label: item.nome,
    }));
    console.log("** dotazioniOptions", this.dotazioniOptions);

    // Carico le opzioni per l'anno di acquisto
    const anni = this.mixins_getArrayYearsUpToCurrentYear();

    this.annoacquistoOptions = anni.map((item) => ({
      value: item,
      label: item,
    }));
    console.log("** annoacquistoOptions", this.annoacquistoOptions);

    // Carico le opzioni per l'ammortamento anni'
    this.ammortamentoanniOptions = ammortamentoAnni.map((ammortamento) => ({
      value: ammortamento.value,
      label: ammortamento.label,
    }));
    console.log("** ammortamentoanniOptions", this.ammortamentoanniOptions);
  },
  async mounted() {
    // fetch dotazione
    try {
      if (this.$route.params.id) {
        // modifica
        await this.fetchDotazione();
      } else {
        // crea nuovo
        this.localDotazione = new Dotazione();
        this.isEditMode = false;
        this.headerTitle = "Crea Nuovo";
      }
    } catch (err) {
      console.error = "Si è verificato un errore";
    }
    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
