// src/views/area_personale/AreaPersonaleView.vue

<template>
  <div class="container">
    <h1>Area Personale</h1>
    <p>Questa è l'area personale dell'utente.</p>
  </div>
</template>

<script>
export default {
  name: "AreaPersonaleView",
};
</script>

<style>
/* Aggiungi i tuoi stili qui */
</style>
