<template>
  <main>
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :width="64"
      :height="64"
      backgroundColor="#000000"
      color="#149efd"
      loader="bars"
    />
    <!-- Main content -->
    <div class="container" style="margin-top: 33px !important">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div
          class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
        >
          <h1>
            MANUALE UTENTE<span class="text-white small ms-3"
              ><i class="fa-solid fa-circle-question fa-fw"></i
            ></span>
          </h1>
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        Under construction
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Loading },
  data() {
    return {};
  },
  methods: {},
};
</script>
