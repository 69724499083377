<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <!-- Main Title -->
          <div
            class="h-100 align-items-end bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              RAPPORTO ATTIVITÀ<span class="text-white small"
                ><span class="mx-3 single-list"><i class="bi bi-bullseye"></i></span
                ><span>{{ intestazione }}</span></span
              >
            </h1>
          </div>
        </div>
        <div
          class="d-none h-100 align-items-end col-sm-2 d-flex justify-content-end p-0 pb-2"
        >
          <button type="button" class="btn button-outline-1 small">
            <span><i class="zmdi zmdi-plus"></i>Crea Nuovo</span>
          </button>
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Rapportino attività -->
        <div class="row m-0 padding-x-4px">
          <!-- Error message -->
          <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
          <div v-if="!errorMessage">
            <div
              class="align-items-center d-flex flex-row flex-1600-column justify-content-between p-0 py-3"
            >
              <!-- Prima colonna -->
              <div
                class="container-fluid align-items-center d-flex flex-row justify-content-between px-0"
              >
                <!-- Info rapportino -->
                <div class="d-flex">
                  <!-- Ultimo giorno per la chiusura -->
                  <div
                    v-if="visualizzazione !== 'annuale'"
                    class="form-label d-flex align-items-center justify-content-start px-0 pe-3"
                  >
                    <span class="pe-2 ws-nowrap">ULTIMO GIORNO<br />PER LA CHIUSURA</span>
                    <span class="fs-2 text-white">
                      {{ dataRapportino.info?.ultimoGiornoChiusura ?? "0" }}
                    </span>
                  </div>
                  <!-- Ore imputabili -->
                  <div
                    v-if="
                      isAuthenticatedSuperAdmin ||
                      isAuthenticatedGestionePersonale ||
                      !isProfileEsterno
                    "
                    class="form-label d-flex align-items-center justify-content-start"
                  >
                    <span class="pe-2 ws-nowrap">ORE<br />IMPUTABILI</span>
                    <span class="fs-2 text-white">
                      {{
                        dataRapportino.info
                          ? mixins_formatNumberWithLocale(
                              dataRapportino.info?.oreImputabili,
                              1
                            )
                          : "0"
                      }}
                    </span>
                  </div>
                  <!-- Ore straordinario -->
                  <div
                    v-if="
                      isAuthenticatedSuperAdmin ||
                      isAuthenticatedGestionePersonale ||
                      !isProfileEsterno
                    "
                    class="align-items-center d-flex ps-3"
                  >
                    <div
                      class="align-items-end d-inline-flex flex-column justify-content-center"
                    >
                      <div class="form-label pe-2 ws-nowrap">ORE STR. CONSENTITE:</div>
                      <div class="form-label pe-2 ws-nowrap">ORE STR. RESIDUE:</div>
                    </div>
                    <div
                      class="align-items-start d-inline-flex flex-column justify-content-center"
                    >
                      <!-- Ore straordinario consentite -->
                      <div class="form-label text-white">
                        {{
                          dataRapportino.info
                            ? mixins_formatNumberWithLocale(
                                dataRapportino.info?.oreStrConsentite,
                                1
                              )
                            : "0"
                        }}
                      </div>
                      <!-- Ore straordinario residue -->
                      <div class="form-label text-white">
                        {{
                          dataRapportino.info
                            ? mixins_formatNumberWithLocale(
                                dataRapportino.info?.strResiduo,
                                1
                              )
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Seconda colonna -->
              <div
                class="container-fluid align-items-center d-flex flex-row justify-content-between px-0 py-2"
              >
                <!-- Selezione periodo -->

                <div class="d-flex me-0 me-lg-2">
                  <!-- Settimana, visibile solo in vista settimanale -->
                  <div class="dropdown me-2" v-if="visualizzazione === 'settimanale'">
                    <button
                      class="btn button-outline-1 small dropdown-toggle"
                      type="button"
                      id="dropdown_settimana"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <!-- Mostra solo il campo 'display' dell'oggetto 'selectedSettimana' -->
                      <span>{{ selectedSettimana.display }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-for="settimana in settimane" :key="settimana.start">
                        <button
                          class="dropdown-item"
                          type="button"
                          @click="setSettimana(settimana)"
                        >
                          {{ settimana.display }}
                        </button>
                      </li>
                    </ul>
                  </div>

                  <!-- Giorno, visibile solo in vista giornaliera -->
                  <div class="dropdown me-2" v-if="visualizzazione === 'giornaliera'">
                    <button
                      class="btn button-outline-1 small dropdown-toggle"
                      type="button"
                      id="dropdown_giorno"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>{{ selectedGiorno }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-for="giorno in giorni" :key="giorno">
                        <button
                          class="dropdown-item"
                          type="button"
                          @click="setGiorno(giorno)"
                        >
                          {{ giorno }}
                        </button>
                      </li>
                    </ul>
                  </div>

                  <!-- Mese, visibile in vista giornaliera e mensile -->
                  <div
                    class="dropdown me-2"
                    v-if="['giornaliera', 'mensile'].includes(visualizzazione)"
                  >
                    <button
                      class="btn button-outline-1 small dropdown-toggle"
                      type="button"
                      id="dropdown_mese"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>{{ selectedMese }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-for="mese in mesi" :key="mese">
                        <button
                          class="dropdown-item"
                          type="button"
                          @click="setMese(mese)"
                        >
                          {{ mese }}
                        </button>
                      </li>
                    </ul>
                  </div>

                  <!-- Anno, visibile in tutte le viste -->
                  <div class="dropdown me-2">
                    <button
                      class="btn button-outline-1 small dropdown-toggle"
                      type="button"
                      id="dropdown_anno"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>{{ selectedAnno }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-for="anno in anni" :key="anno">
                        <button
                          class="dropdown-item"
                          type="button"
                          @click="setAnno(anno)"
                        >
                          {{ anno }}
                        </button>
                      </li>
                    </ul>
                  </div>

                  <!-- Bottone Vai, visibile in tutte le viste -->
                  <button
                    id="but_vai"
                    ref="but_vai"
                    type="button"
                    class="btn button-outline-1 small"
                    @click="onVai"
                  >
                    <i class="zmdi zmdi-long-arrow-return zmdi-hc-lg"></i> VAI
                  </button>
                </div>

                <!-- Riapri / Chiudi rapportino -->
                <div class="w-100 align-items-center d-flex justify-content-end">
                  <div
                    v-if="isRapportinoChiuso"
                    class="status-rapporto-success truncate-ellipsis me-2"
                  >
                    RAPPORTINO<br />CHIUSO
                  </div>
                  <div
                    v-if="isRapportinoAperto"
                    class="status-rapporto-alert truncate-ellipsis me-2"
                  >
                    RAPPORTINO<br />APERTO
                  </div>
                  <div
                    v-if="isRapportinoNonValidato"
                    class="status-rapporto-alert truncate-ellipsis me-2"
                  >
                    RAPPORTINO<br />NON VALIDATO
                  </div>
                  <div
                    v-if="isRapportinoValidato"
                    class="status-rapporto-success truncate-ellipsis me-2"
                  >
                    RAPPORTINO<br />VALIDATO
                  </div>
                  <button
                    v-if="['mensile'].includes(visualizzazione) && isRapportinoAperto"
                    @click="askChiudiRapportino"
                    type="button"
                    class="btn button-outline-1 small me-2"
                  >
                    <span><i class="bi bi-door-closed-fill"></i>Chiudi Rapportino</span>
                  </button>
                  <button
                    v-if="
                      (isAuthenticatedSuperAdmin ||
                        isAuthenticatedGestionePersonale ||
                        isAuthenticatedDTC) &&
                      ['mensile'].includes(visualizzazione) &&
                      isRapportinoChiuso
                    "
                    @click="askRiapriRapportino"
                    type="button"
                    class="btn button-outline-1 small me-2"
                  >
                    <span><i class="bi bi-door-open-fill"></i>Riapri Rapportino</span>
                  </button>
                  <button
                    v-if="
                      (isAuthenticatedSuperAdmin ||
                        isAuthenticatedGestionePersonale ||
                        isAuthenticatedDTC) &&
                      ['mensile'].includes(visualizzazione) &&
                      isRapportinoNonValidato
                    "
                    @click="askValidaRapportino"
                    type="button"
                    class="btn button-outline-1 small me-2"
                  >
                    <span><i class="bi bi-check-circle-fill"></i>Valida Rapportino</span>
                  </button>
                </div>
              </div>
            </div>

            <hr class="sepGray" />

            <!-- Messaggi all'utente -->
            <div
              v-if="isAlertVisible"
              class="alert alert-danger alert-dismissible align-items-start d-flex fade justify-content-start show"
              role="alert"
            >
              <i class="bi bi-exclamation-triangle-fill fs-4 me-2"></i>
              <span v-html="htmlMessage"></span>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Chiudi"
                alt="Chiudi"
                title="Chiudi"
                @click="closeAlert"
              ></button>
            </div>

            <!-- Commesse assegnate -->
            <div class="container-fluid m-0 p-0 pt-4">
              <div class="row m-0">
                <h4 class="color-mainblue p-0 mb-2">Commesse assegnate aperte</h4>
                <div v-if="commesseAssegnateAperte.length" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mb-4">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>CLIENTE</th>
                        <th>TITOLO</th>
                        <th style="width: 130px !important; min-width: 130px !important">
                          DATA INIZIO
                        </th>
                        <th style="width: 130px !important; min-width: 130px !important">
                          DATA FINE
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="commessaAssegnata in commesseAssegnateAperte"
                        :key="commessaAssegnata._id"
                        :class="{ 'bg-danger': commessaAssegnata.eliminato }"
                      >
                        <td>
                          <h6 class="py-2 m-0">
                            {{ commessaAssegnata.cliente.descrizione }}
                          </h6>
                        </td>
                        <td>
                          <h6 v-if="commessaAssegnata.eliminato" class="py-2 m-0">
                            <span class="fs-6 color-light-gray"
                              >[ COMMESSA ELIMINATA ]&nbsp;</span
                            ><br />
                            {{ commessaAssegnata.titolo
                            }}{{
                              commessaAssegnata.identificativonav
                                ? ` | Codice NAV: ${commessaAssegnata.identificativonav}`
                                : ""
                            }}
                          </h6>
                          <h6 v-else class="py-2 m-0">
                            {{ commessaAssegnata.titolo
                            }}{{
                              commessaAssegnata.identificativonav
                                ? ` | Codice NAV: ${commessaAssegnata.identificativonav}`
                                : ""
                            }}
                          </h6>
                        </td>
                        <td class="td-center">
                          {{
                            commessaAssegnata.datainizio
                              ? mixins_getLocalDate(commessaAssegnata.datainizio)
                              : "-"
                          }}
                        </td>
                        <td class="td-center">
                          {{
                            commessaAssegnata.datafine
                              ? mixins_getLocalDate(commessaAssegnata.datafine)
                              : "-"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="!commesseAssegnateAperte.length" class="p-0 mb-2">
              <h5>Nessun dato da visualizzare</h5>
            </div>

            <!-- Se esiste providerUserId -->
            <div class="p-0 m-0" v-if="providerUserId">
              <!-- START Table Rapportino dettagli mensili -->
              <div
                id="dettaglioMensile"
                ref="dettaglioMensile"
                v-if="visualizzazione !== 'annuale'"
                class="container-fluid m-0 p-0 pt-4"
              >
                <h4 class="color-mainblue">
                  {{ userFullName }} - Rapportino Mensile,
                  {{ `${selectedMese} ${selectedAnno}` }}
                </h4>
                <div class="row m-0 mb-3">
                  <div class="scroll-x-container p-0">
                    <table
                      id="tableRapportino"
                      ref="tableRapportino"
                      class="table-bordered table-responsive-md table-rapportino mb-2"
                    >
                      <thead class="table-header-font">
                        <tr class="td-center">
                          <th>GIORNI</th>
                          <th
                            v-for="day in daysInMonth"
                            :key="day.date"
                            :class="[
                              'fixed-day',
                              {
                                festivo:
                                  day.isSabato ||
                                  day.isDomenica ||
                                  day.isFestivita ||
                                  day.isFestivita,
                              },
                            ]"
                            :alt="
                              day.isFestivita
                                ? 'MODIFICA - (' + day.descrizioneFestivita + ')'
                                : 'MODIFICA'
                            "
                            :title="
                              day.isFestivita
                                ? 'MODIFICA - (' + day.descrizioneFestivita + ')'
                                : 'MODIFICA'
                            "
                            @click="!isFirstDayClosed ? editDaily(day) : null"
                          >
                            {{ day.dayOfMonth }}<br />{{ day.dayName }}
                          </th>
                          <th class="fixed-day totali no-pointer">TOT</th>
                        </tr>
                      </thead>
                      <tbody class="table-body-font td-vertical-center">
                        <!-- Riga ORDINARIO -->
                        <tr class="tr-bkg-ordinario">
                          <td>ORDINARIO</td>
                          <td
                            v-for="day in daysInMonth"
                            :key="day.date"
                            :class="[
                              'td-right',
                              'fixed-day',
                              {
                                festivo:
                                  day.isSabato ||
                                  day.isDomenica ||
                                  day.isFestivita ||
                                  day.isFestivita,
                                'zero-value': isZeroValue(
                                  getTotalOrdinaryHours(day.date)
                                ),
                              },
                            ]"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                getTotalOrdinaryHours(day.date),
                                1
                              )
                            }}
                          </td>
                          <td class="td-right fixed-day no-pointer totali">
                            {{
                              mixins_formatNumberWithLocale(getTotalOrdinaryHours(), 1)
                            }}
                          </td>
                        </tr>

                        <!-- Righe per ogni commessa -->
                        <template
                          v-for="commessa in commesse"
                          :key="getCommessaKey(commessa)"
                        >
                          <tr class="tr-bkg-commessa">
                            <td colspan="33">
                              <h6 class="py-2 m-0">
                                {{ getCommessaDescrizione(commessa)
                                }}{{
                                  getCommessaNav(commessa)
                                    ? ` | Codice NAV: ${getCommessaNav(commessa)}`
                                    : ""
                                }}
                              </h6>
                            </td>
                          </tr>
                          <tr
                            v-if="getTotal(commessa, 'tot_ore_ordinarie') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>ORDINARIO DIURNO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'ore_ordinarie')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "ore_ordinarie"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_ore_ordinarie"),
                                  1
                                )
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="getTotal(commessa, 'tot_ore_ordinarie_festive') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>ORDINARIO FESTIVO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'ore_ordinarie_festive')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "ore_ordinarie_festive"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_ore_ordinarie_festive"),
                                  1
                                )
                              }}
                            </td>
                          </tr>

                          <tr
                            v-if="getTotal(commessa, 'tot_ore_ordinarie_notturne') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>ORDINARIO NOTTURNO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'ore_ordinarie_notturne')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "ore_ordinarie_notturne"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_ore_ordinarie_notturne"),
                                  1
                                )
                              }}
                            </td>
                          </tr>

                          <tr
                            v-if="getTotal(commessa, 'tot_straordinario_ordinario') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>STRAORDINARIO ORDINARIO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(
                                      commessa,
                                      day.date,
                                      'straordinario_ordinario'
                                    )
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "straordinario_ordinario"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_straordinario_ordinario"),
                                  1
                                )
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="getTotal(commessa, 'tot_straordinario_festivo') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>STRAORDINARIO FESTIVO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'straordinario_festivo')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "straordinario_festivo"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_straordinario_festivo"),
                                  1
                                )
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="getTotal(commessa, 'tot_straordinario_notturno') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>STRAORDINARIO NOTTURNO</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'straordinario_notturno')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "straordinario_notturno"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_straordinario_notturno"),
                                  1
                                )
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="getTotal(commessa, 'tot_reperibilita') > 0"
                            class="tr-bkg-dati"
                          >
                            <td>REPERIBILITÀ</td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getHours(commessa, day.date, 'reperibilita')
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getHours(commessa, day.date, "reperibilita"),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{
                                mixins_formatNumberWithLocale(
                                  getTotal(commessa, "tot_reperibilita"),
                                  1
                                )
                              }}
                            </td>
                          </tr>
                        </template>

                        <!-- Riga vuota di separazione -->
                        <tr>
                          <td colspan="33"></td>
                        </tr>

                        <!-- Righe per le assenze -->
                        <tr
                          v-if="getTotal(assenze, 'tot_malattia') > 0"
                          class="tr-bkg-assenza"
                        >
                          <td>MALATTIA</td>
                          <td
                            v-for="day in daysInMonth"
                            :key="day.date"
                            :class="[
                              'td-right',
                              'fixed-day',
                              {
                                festivo:
                                  day.isSabato || day.isDomenica || day.isFestivita,
                                'zero-value': isZeroValue(
                                  getHours(assenze, day.date, 'malattia')
                                ),
                              },
                            ]"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                getHours(assenze, day.date, "malattia"),
                                1
                              )
                            }}
                          </td>
                          <td class="td-right fixed-day no-pointer totali">
                            {{
                              mixins_formatNumberWithLocale(
                                getTotal(assenze, "tot_malattia"),
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="getTotal(assenze, 'tot_ferie') > 0"
                          class="tr-bkg-assenza"
                        >
                          <td>FERIE</td>
                          <td
                            v-for="day in daysInMonth"
                            :key="day.date"
                            :class="[
                              'td-right',
                              'fixed-day',
                              {
                                festivo:
                                  day.isSabato || day.isDomenica || day.isFestivita,
                                'zero-value': isZeroValue(
                                  getHours(assenze, day.date, 'ferie')
                                ),
                              },
                            ]"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                getHours(assenze, day.date, "ferie"),
                                1
                              )
                            }}
                          </td>
                          <td class="td-right fixed-day no-pointer totali">
                            {{
                              mixins_formatNumberWithLocale(
                                getTotal(assenze, "tot_ferie"),
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <template
                          v-for="(permesso, index) in permessiGiustificati"
                          :key="index"
                        >
                          <tr class="tr-bkg-assenza">
                            <td>
                              PERMESSO GIUSTIFICATO:<br /><span class="small">{{
                                permesso.tipo
                              }}</span>
                            </td>
                            <td
                              v-for="day in daysInMonth"
                              :key="day.date"
                              :class="[
                                'td-right',
                                'fixed-day',
                                {
                                  festivo:
                                    day.isSabato || day.isDomenica || day.isFestivita,
                                  'zero-value': isZeroValue(
                                    getPermessoGiustificatoHours(
                                      assenze,
                                      day.date,
                                      permesso.tipo
                                    )
                                  ),
                                },
                              ]"
                            >
                              {{
                                mixins_formatNumberWithLocale(
                                  getPermessoGiustificatoHours(
                                    assenze,
                                    day.date,
                                    permesso.tipo
                                  ),
                                  1
                                )
                              }}
                            </td>
                            <td class="td-right fixed-day no-pointer totali">
                              {{ mixins_formatNumberWithLocale(permesso.totale, 1) }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          v-if="getTotal(assenze, 'tot_rol') > 0"
                          class="tr-bkg-assenza"
                        >
                          <td>ROL</td>
                          <td
                            v-for="day in daysInMonth"
                            :key="day.date"
                            :class="[
                              'td-right',
                              'fixed-day',
                              {
                                festivo:
                                  day.isSabato || day.isDomenica || day.isFestivita,
                                'zero-value': isZeroValue(
                                  getHours(assenze, day.date, 'rol')
                                ),
                              },
                            ]"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                getHours(assenze, day.date, "rol"),
                                1
                              )
                            }}
                          </td>
                          <td class="td-right fixed-day no-pointer totali">
                            {{
                              mixins_formatNumberWithLocale(
                                getTotal(assenze, "tot_rol"),
                                1
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END Table Rapportino dettagli mensili -->

              <!-- Container Buttons -->
              <div v-if="visualizzazione !== 'annuale'" class="row m-0 my-2">
                <div class="container-fluid p-0 mb-2">
                  <!-- Azzera i campi -->
                  <div v-if="visualizzazione === 'mensile'" class="dropdown float-start">
                    <button
                      @click.prevent="askAzzeraRapportino()"
                      class="btn button-outline-1 small"
                      type="button"
                    >
                      <span>
                        <i class="ME-2 bi bi-recycle"></i>Azzera Dati Rapportino</span
                      >
                    </button>
                  </div>
                  <!-- Esporta Rapportino -->
                  <div class="dropdown float-end">
                    <button
                      class="btn button-outline-1 small dropdown-toggle"
                      type="button"
                      id="dropdown_esporta_rapp"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i class="me-2 fa-solid fa-file-export"></i>Esporta
                        Rapportino</span
                      >
                    </button>
                    <ul
                      class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdown_esporta_rapp"
                    >
                      <li>
                        <button
                          @click="
                            exportStyledTableToExcel(
                              userFullName,
                              selectedMese,
                              selectedAnno
                            )
                          "
                          class="dropdown-item"
                          type="button"
                        >
                          Esporta in Excel
                        </button>
                      </li>
                      <li>
                        <button
                          @click="exportToJpeg(userFullName, selectedMese, selectedAnno)"
                          class="dropdown-item"
                          type="button"
                        >
                          Esporta Immagine
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- START Table Rapportino aggregato -->
              <div class="container-fluid m-0 p-0 pt-4">
                <h4 class="color-mainblue">Totali Commesse | {{ intestazione }}</h4>
              </div>
              <div v-if="hasDataOrdinarie" class="my-3 p-0">
                <div class="container-fluid m-0 p-0">
                  <div class="row m-0 mb-3">
                    <table class="table-bordered table-responsive-md table-setup mb-2">
                      <thead class="table-header-font">
                        <tr class="td-center">
                          <th
                            style="width: 240px !important; min-width: 240px !important"
                          >
                            CLIENTE
                          </th>
                          <th>TITOLO COMMESSA</th>
                          <th
                            style="width: 130px !important; min-width: 130px !important"
                          >
                            ORDINARIO
                          </th>
                          <th
                            style="width: 130px !important; min-width: 130px !important"
                          >
                            STRAORDINARIO
                          </th>
                          <th
                            style="width: 100px !important; min-width: 100px !important"
                          >
                            REPERIBILITÀ
                          </th>
                        </tr>
                      </thead>
                      <!-- Table Records -->
                      <tbody class="table-body-font td-vertical-center">
                        <tr
                          v-for="commessa in dataRapportinoGrouped.commesse"
                          :key="commessa.commessaId"
                        >
                          <td>{{ commessa.clienteRagionesociale }}</td>
                          <td>
                            {{ commessa.commessaDescrizione
                            }}{{
                              commessa.commessaNav
                                ? ` | Codice NAV: ${commessa.commessaNav}`
                                : ""
                            }}
                          </td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  commessa.tot_ore_ordinarie,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(commessa.tot_ore_ordinarie, 1)
                            }}
                          </td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  commessa.tot_straordinario,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(commessa.tot_straordinario, 1)
                            }}
                          </td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  commessa.tot_reperibilita,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(commessa.tot_reperibilita, 1)
                            }}
                          </td>
                        </tr>
                        <!-- Totali -->
                        <tr class="active">
                          <td></td>
                          <td class="td-right fs-6">TOTALE:</td>
                          <td
                            class="td-right fs-6"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_ordinarie
                                    .tot_ore_ordinarie,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_ordinarie.tot_ore_ordinarie,
                                1
                              )
                            }}
                          </td>
                          <td
                            class="td-right fs-6"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_ordinarie
                                    .tot_straordinario,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_ordinarie.tot_straordinario,
                                1
                              )
                            }}
                          </td>
                          <td
                            class="td-right fs-6"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_ordinarie.tot_reperibilita,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_ordinarie.tot_reperibilita,
                                1
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Rapportino aggregato, no data -->
              <div v-if="!hasDataOrdinarie" class="my-3 p-0">
                <h5>Nessun dato da visualizzare</h5>
              </div>
              <!-- END  Table Rapportino aggregato -->

              <!-- START Table Assenze Rapportino aggregato -->
              <div class="container-fluid m-0 p-0 pt-4">
                <h4 class="color-mainblue">Totali Assenze | {{ intestazione }}</h4>
              </div>
              <div v-if="hasDataAssenze" class="m-0 p-0" style="width: 400px">
                <div class="row m-0 mb-3">
                  <div class="scroll-x-container p-0">
                    <table class="table-bordered table-responsive-md table-setup mb-2">
                      <thead class="table-header-font">
                        <tr class="td-center">
                          <th>TIPO ASSENZA</th>
                          <th
                            style="width: 130px !important; min-width: 130px !important"
                          >
                            TOTALE
                          </th>
                        </tr>
                      </thead>

                      <!-- Table Records -->
                      <tbody class="table-body-font td-vertical-center">
                        <tr>
                          <td>MALATTIA</td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_assenze.tot_malattia,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_assenze.tot_malattia,
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>FERIE</td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_assenze.tot_ferie,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_assenze.tot_ferie,
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>PERMESSI GIUSTIFICATI</td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_assenze
                                    .tot_permessi_giustificati,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_assenze
                                  .tot_permessi_giustificati,
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>ROL</td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totali_assenze.tot_rol,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totali_assenze.tot_rol,
                                1
                              )
                            }}
                          </td>
                        </tr>
                        <!-- Totali -->
                        <tr class="active">
                          <td class="td-right fs-6">TOTALE:</td>
                          <td
                            class="td-right"
                            :class="{
                              'zero-value': isZeroValue(
                                mixins_formatNumberWithLocale(
                                  dataRapportinoGrouped.totaleGlobaleAssenze,
                                  1
                                )
                              ),
                            }"
                          >
                            {{
                              mixins_formatNumberWithLocale(
                                dataRapportinoGrouped.totaleGlobaleAssenze,
                                1
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Rapportino aggregato assenze, no data -->
              <div v-if="!hasDataAssenze" class="my-3 p-0">
                <h5>Nessun dato da visualizzare</h5>
              </div>
              <!-- END Table Assenze Rapportino aggregato -->
            </div>
            <!-- Utente non valido, id inesistente -->
            <div v-else class="m-0 p-0">
              <div class="my-3 p-0">
                <h5>Nessun dato da visualizzare, utente non valido</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditRapportino
    :isVisible="isDialogVisible"
    :dialogWidth="'1100px'"
    :dialogTitle="dialogTitle"
    :dataItem="dataOfDay"
    @closeModal="closeModal"
    @saveData="saveData"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import {
  fetchUserAttivitaRapportino,
  fetchUserAttivitaRapportinoAggregato,
  fetchCommessaById,
  fetchSingleUserTeamByUserId,
  createRapportino,
  updateRapportino,
  closeRapportino,
  reopenRapportino,
  validaRapportino,
  completaMeseRapportino,
  fetchUserRapporto,
  azzeraDatiRapportino,
} from "@/services/api";
import { Commessa } from "@/models/CommessaJoiModel";
import { DayCommessa, Day } from "@/models/ConstructorDayCommessa";
import DialogAddEditRapportino from "@/components/dialogs/DialogAddEditRapportino.vue";
import ExcelExport from "@/mixins/ExcelExport.js";
import ExportImage from "@/mixins/ExportImage.js";
import { Rapporto } from "@/models/TeamJoiModel";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

// import * as XLSX from "xlsx";

export default {
  mixins: [UtilityMixins, ProfiliPermissions, ExcelExport, ExportImage],
  components: { Loading, DialogAddEditRapportino },
  data() {
    return {
      localRapporto: new Rapporto(),
      errorMessage: null,
      isLoading: false, // Gestione del loader per indicare il caricamento
      visualizzazione: "mensile", // Vista di default, può essere 'giornaliera', 'settimanale', 'mensile', 'annuale'
      selectedGiorno: "", // Giorno selezionato
      selectedMese: "", // Mese selezionato
      selectedAnno: "", // Anno selezionato
      selectedSettimana: { display: "", start: "", end: "" }, // Settimana corrente selezionata
      giorni: [], // Lista dei giorni per il mese selezionato
      settimane: [], // Lista di settimane popolate dinamicamente
      mesi: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      anni: [], // Lista degli anni
      intestazione: "", // Variabile intestazione da aggiornare
      // rapportino
      dataRapportino: [], // Array rapportino proveniente dal server
      dataMeseRapportino: [], // Array che verrà usato per la validazione del rapportino
      dataRapportinoByCommessa: [], // Array rapportino proveniente dal server raggruppato per commessa e ordinato per data, con totali
      dataRapportinoAggregato: [], // Array rapportino aggregato proveniente dal server
      dataRapportinoGrouped: {}, // Oggetto rapportino aggregato raggruppato per id commessa con calcolo totali
      daysInMonth: [],
      commesse: [],
      assenze: null,
      permessiGiustificati: [],
      commesseAssegnateAperte: [],
      isAlertVisible: false,
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      dataOfDay: null,
      // data rapportino
      tabellaFestivita: [],
      providerUserId: null,
      userFullName: "",
      userCitta: null,
      userProfili: [],
      userOreContrattuali: 8,
      userAbilitazioni: { straordinario: true, reperibilita: true },
      messageTimeoutId: null,
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["getViewsTypeAttivita"]),
    hasDataAssenze() {
      // Controlla se ci sono assenze nel rapportino aggregato
      return this.dataRapportinoGrouped?.totaleGlobaleAssenze > 0;
    },
    hasDataOrdinarie() {
      // Controlla se ci sono totale ore ordinare + straordinario nel rapportino aggregato
      return this.dataRapportinoGrouped?.totaleGlobaleOrdinarie > 0;
    },
    hasGlobalData() {
      // Controlla se ci sono dati totali nelle commesse
      const totHoursGlobal =
        this.dataRapportinoGrouped?.totaleGlobaleOrdinarie +
          this.dataRapportinoGrouped?.totaleGlobaleAssenze || 0;
      return totHoursGlobal > 0;
    },
    isFirstDayClosed() {
      // Controllo se l'array daysInMonth ha almeno un elemento
      if (this.daysInMonth.length > 0) {
        // Ritorna il valore di 'closed' del primo elemento
        return this.daysInMonth[0].closed;
      } else {
        // Ritorna 'false' se l'array è vuoto
        return false;
      }
    },
    isRapportinoChiuso() {
      return this.commesseAssegnateAperte?.length && this.isFirstDayClosed;
    },
    isRapportinoAperto() {
      return this.commesseAssegnateAperte?.length && !this.isFirstDayClosed;
    },
    isRapportinoNonValidato() {
      return this.isRapportinoChiuso && !this.isFirstDayValidated;
    },
    isRapportinoValidato() {
      return this.isRapportinoChiuso && this.isFirstDayValidated;
    },
    isFirstDayValidated() {
      // Controllo se l'array daysInMonth ha almeno un elemento
      if (this.daysInMonth.length > 0) {
        // Ritorna il valore di 'validated' del primo elemento
        return this.daysInMonth[0].validated;
      } else {
        // Ritorna 'false' se l'array è vuoto
        return false;
      }
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    //
    // Profiles
    //
    isProfileEsterno() {
      return this.permissions_isProfileEsterno(this.localRapporto);
    },
    isProfileDipendente() {
      return this.permissions_isProfileDipendente(this.localRapporto);
    },
    //
    // Special flags
    //
    isUserTurnista() {
      // Restituisce se l'utente è un 'turnista' (tab rapporto)
      if (this.localRapporto && this.localRapporto.turnista) {
        return this.localRapporto.turnista;
      }
      return false;
    },
  },
  watch: {
    // Watch per il getter di Vuex che osserva il cambio del valore
    getViewsTypeAttivita(newValue) {
      this.visualizzazione = newValue; // Aggiorna la variabile locale

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();

      // fetch api
      this.fetchAllData();
    },
    // Watcher per il mese, anno e giorno selezionati
    selectedGiorno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il giorno
    },
    selectedMese() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il mese
    },
    selectedAnno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia l'anno
    },
  },

  methods: {
    ...mapActions(["setViewsTypeAttivita"]),
    //
    // Fetch data
    //
    async fetchAllData() {
      if (!this.providerUserId) return;

      // Eseguo il fetch dei dati del rapportino (per le info)
      if (this.visualizzazione === "annuale") {
        // Ciclo per 12 mesi la chiamata al rapportino e calcolo i tolali, tralascio l'ultimo giornno per la chiusura
        const year = this.selectedAnno.toString();
        const totaliAnnuali = await this.sommaRapportinoAnnuale(year);
        console.log("** totaliAnnuali", totaliAnnuali);
        this.dataRapportino.info = totaliAnnuali;
      } else {
        const { year: year, month: month } = this.composeYearMonthRapportino();
        await this.fetchRapportino(year, month);
        console.log("** rapportino", this.dataRapportino);
        if (!this.dataRapportino?.data && this.dataRapportino?.response?.status !== 200) {
          console.error(
            "** rapportino, si è verificato un errore nel caricamento dei dati"
          );
          this.errorMessage = "Si è verificato un errore durante il caricamento dei dati";
          return;
        }

        // Processo i dati del rapportino per ottenere l'array da validare
        this.processMeseRapportino();
        console.log("** rapportino dataMeseRapportino", this.dataMeseRapportino);

        // Raggruppo per commessa, rapportino dettagliato mensile
        this.dataRapportinoByCommessa = this.generateRapportinoMensileByCommessa(
          this.dataRapportino.data
        );
        console.log("** rapportino by commessa", this.dataRapportinoByCommessa);

        // Genero i giorni del mese
        this.daysInMonth = this.generateDaysForMonth(
          this.selectedAnno,
          this.getMonthNumber(this.selectedMese)
        );
        console.log("** daysInMonth", this.daysInMonth);

        // Separo le commesse dalle assenze
        this.commesse = this.dataRapportinoByCommessa.filter((item) => item.commessa);
        this.assenze = this.dataRapportinoByCommessa.find((item) => item.assenze);

        // Calcola i permessi giustificati
        this.calcolaPermessiGiustificati();
      }

      // Eseguo il fetch dei dati del rapportino aggregato
      const { start: dateFrom, end: dateTo } = this.composeDateRangeRapportinoAggregato();
      await this.fetchRapportinoAggregato(dateFrom, dateTo);
      console.log("** rapportino aggregato", this.dataRapportinoAggregato);
      if (
        !this.dataRapportinoAggregato &&
        this.dataRapportinoAggregato?.response?.status !== 200
      ) {
        console.error(
          "** rapportino aggregato, si è verificato un errore nel caricamento dei dati"
        );
        this.errorMessage = "Si è verificato un errore durante il caricamento dei dati";
        return;
      }

      // Raggruppo il rapportino aggregato per commessa
      if (this.dataRapportinoAggregato && this.dataRapportinoAggregato.length > 0) {
        this.dataRapportinoGrouped = await this.generateRapportinoAggregato(
          this.dataRapportinoAggregato
        );
      }

      console.log("** rapportino raggruppato per commessa", this.dataRapportinoGrouped);
    },
    async fetchCommesseAperte() {
      this.isLoading = true;

      try {
        const response = await fetchSingleUserTeamByUserId(1, 10, 0, this.providerUserId);
        if (response.users && response.users[0] && response.users[0].commesse) {
          console.log(
            "** rapportino commesse ricevute dal server",
            response.users[0].commesse
          );
          // Se nell'oggetto commessa esiste "datafine" ed è null allora la commessa è aperta
          this.commesseAssegnateAperte = response.users[0].commesse.filter(
            (commessa) =>
              commessa.datafine === null ||
              commessa.datafine === "" ||
              commessa.datafine === undefined
          );
        }
        // Ordino per 'datainizio' crescente
        this.commesseAssegnateAperte.sort(
          (a, b) => new Date(a.datainizio) - new Date(b.datainizio)
        );
        console.log(
          "** rapportino commesse assegnate aperte",
          this.commesseAssegnateAperte
        );
      } catch (err) {
        console.error(
          "Si è verificato un errore durante fetchSingleUserTeamByUserId",
          err.message
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRapporto() {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchUserRapporto(this.providerUserId);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto, mando un messaggio di errore
          if (this.mixins_isEmptyObject(response)) {
            this.errorMessage =
              "Si è verificato un errore durante il caricamento dei dati";
            return;
          }

          this.localRapporto = new Rapporto(response.rapporto);
          console.log("** dati localRapporto", this.localRapporto);
        } else {
          this.errorMessage = "Dati inesistenti per il rapporto";
        }
      } catch (error) {
        this.errorMessage = "Dati inesistenti per il rapporto";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    //
    // Validazione giorni rapportino e rapportino finale
    //
    processMeseRapportino() {
      // Controllo se dataRapportino e dataRapportino.data esistono e contengono dati
      if (
        !this.dataRapportino ||
        !this.dataRapportino.data ||
        this.dataRapportino.data.length === 0
      ) {
        console.error("Nessun dato disponibile in dataRapportino.data");
        this.dataMeseRapportino = []; // Assicura che dataMeseRapportino sia un array vuoto
        return;
      }

      // Step 1: Mappo le festività
      const festivitaMap = this.tabellaFestivita.map((festivita) => ({
        date: new Date(festivita.data).toLocaleDateString("it-IT"),
        locale: festivita.locale,
        citta: festivita.citta ? festivita.citta.toLowerCase() : null,
        descrizione: festivita.descrizione,
      }));

      // Step 2: Raggruppo i dati per "day"
      const groupedData = this.dataRapportino.data.reduce((acc, item) => {
        const day = item._id.day;
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(item);
        return acc;
      }, {});

      // Step 3: Sommo le ore per ciascun giorno
      const summedData = Object.entries(groupedData).map(([day, items]) => {
        const tot_ore_ordinarie = items.reduce(
          (sum, i) =>
            sum + i.ore_ordinarie + i.ore_ordinarie_notturne + i.ore_ordinarie_festive,
          0
        );
        const tot_ore_straordinarie = items.reduce(
          (sum, i) =>
            sum +
            i.straordinario_ordinario +
            i.straordinario_festivo +
            i.straordinario_notturno,
          0
        );
        const tot_reperibilita = items.reduce((sum, i) => sum + i.reperibilita, 0);
        const tot_assenze = items.reduce(
          (sum, i) => sum + i.ferie + i.rol + i.malattia + i.permessi_giustificati,
          0
        );
        const tot_altro = items.reduce((sum, i) => sum + i.altro, 0);
        const totale_giorno =
          tot_ore_ordinarie +
          tot_ore_straordinarie +
          tot_reperibilita +
          tot_assenze +
          tot_altro;

        return {
          day,
          tot_ore_ordinarie,
          tot_ore_straordinarie,
          tot_reperibilita,
          tot_assenze,
          tot_altro,
          totale_giorno,
        };
      });

      // Step 4: Ottengo il mese e l'anno
      const exampleDay = this.dataRapportino.data[0]._id.day;
      const [year, month] = exampleDay.split("-");

      // Step 5: Creo i giorni del mese
      const daysInMonth = new Date(year, month, 0).getDate();
      this.dataMeseRapportino = []; // Inizializzo l'array

      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month - 1, day);
        const formattedDate = date.toLocaleDateString("it-IT");
        const dataForDay = summedData.find(
          (d) =>
            d.day ===
            `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`
        );

        // Determino se il giorno è sabato, domenica o una festività
        const isSabato = date.getDay() === 6;
        const isDomenica = date.getDay() === 0;

        let isFestivita = false;
        let isFestivitaLocale = false;

        // Controllo se la data è una festività
        const festivita = festivitaMap.find((fest) => fest.date === formattedDate);
        if (festivita) {
          if (festivita.locale) {
            if (this.userCitta) {
              isFestivitaLocale = festivita.citta === this.userCitta.toLowerCase();
              isFestivita = isFestivitaLocale;
            }
          } else {
            isFestivita = true;
          }
        }

        if (dataForDay) {
          this.dataMeseRapportino.push({
            ...dataForDay,
            isSabato,
            isDomenica,
            isFestivita,
            isFestivitaLocale,
          });
        } else {
          this.dataMeseRapportino.push({
            day: `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
              2,
              "0"
            )}`,
            tot_ore_ordinarie: 0,
            tot_ore_straordinarie: 0,
            tot_reperibilita: 0,
            tot_assenze: 0,
            tot_altro: 0,
            totale_giorno: 0,
            isSabato,
            isDomenica,
            isFestivita,
            isFestivitaLocale,
          });
        }
      }
    },
    validateAllDaysRapportino() {
      // Controllo se l'array è vuoto
      if (!this.dataMeseRapportino || this.dataMeseRapportino.length === 0) {
        return null;
      }

      // Filtro solo i giorni lavorativi (escludendo sabati, domeniche e festività)
      const giorniLavorativi = this.dataMeseRapportino.filter(
        (giorno) =>
          !giorno.isSabato &&
          !giorno.isDomenica &&
          !giorno.isFestivita &&
          !giorno.isFestivitaLocale
      );

      // Se non ci sono giorni lavorativi nel mese
      if (giorniLavorativi.length === 0) {
        return null;
      }

      // Controllo se tutti i giorni lavorativi hanno totale_giorno === 0
      const tuttiZero = giorniLavorativi.every((giorno) => giorno.totale_giorno === 0);

      if (tuttiZero) {
        return 0;
      }

      // Controllo se ci sono alcuni giorni con totale > 0 e altri con totale === 0
      const giorniCompilati = giorniLavorativi.filter(
        (giorno) => giorno.totale_giorno > 0
      ).length;

      // Se alcuni giorni sono compilati ma non tutti
      if (giorniCompilati > 0 && giorniCompilati < giorniLavorativi.length) {
        return false;
      }

      // Se tutti i giorni lavorativi sono compilati con valori > 0
      return true;
    },
    isRapportinoCanBeClosed() {
      const isValid = this.validateAllDaysRapportino();
      console.log("** rapportino dataMeseRapportino", this.dataMeseRapportino);

      let canBeClosed = false;

      switch (isValid) {
        case null:
          console.log(
            "** rapportino validateAllDaysRapportino dataMeseRapportino è vuoto, [ impossibile chiudere rapportino ]"
          );
          this.mixins_showMessage(
            "Chiusura Rapportino",
            "Non ci sono dati per poter chiudere il rapportino",
            "warning"
          );
          break;
        case 0:
          console.log(
            "** rapportino validateAllDaysRapportino dataMeseRapportino tutti i giorni feriali hanno valore = 0 [ può chiudere solo turnista o esterno ]"
          );
          if (this.isProfileEsterno || this.isUserTurnista) {
            canBeClosed = true;
          } else {
            this.mixins_showMessage(
              "Chiusura Rapportino",
              "Tutti i giorni hanno 0 ore ordinarie, impossibile chiudere il rapportino",
              "warning"
            );
          }
          break;
        case true:
          console.log(
            "** rapportino validateAllDaysRapportino dataMeseRapportino tutti i giorni feriali sono stati compilati [ tutti possono chiudere il rapportino ]"
          );
          canBeClosed = true;
          break;
        case false:
          console.log(
            "** rapportino validateAllDaysRapportino dataMeseRapportino non tutti i giorni feriali sono stati compilati [ può chiudere solo turnista o esterno ]"
          );
          if (this.isProfileEsterno || this.isUserTurnista) {
            canBeClosed = true;
          } else {
            this.mixins_showMessage(
              "Chiusura Rapportino",
              "Non tutti i giorni sono stati compilati, impossibile chiudere il rapportino",
              "warning"
            );
          }
          break;
      }

      return canBeClosed;
    },
    async askAzzeraRapportino() {
      this.visualizzazione = "mensile"; // Forzo visualizzazione mensile

      this.$swal
        .fire({
          title: "Azzera Dati Rapportino",
          html:
            "Confermi di voler azzerare tutti i dati del rapportino, escluse le assenze?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.azzeraRapportino();
          }
        });
    },
    async azzeraRapportino() {
      const showError = () => {
        this.mixins_showMessage(
          "Azzera Dati Rapportino",
          "Si è verificato un errore durante l'azzeramento dei dati",
          "error"
        );
      };

      const firstDay = this.daysInMonth?.length ? this.daysInMonth[0].dateString : null;
      console.log("** rapportino firstDay", firstDay);
      if (!firstDay) {
        showError();
        return;
      }
      const [anno, mese, giorno] = firstDay.split("-").map(String);
      const firstDayISO =
        new Date(firstDay).toISOString().split("T")[0] + "T00:00:00.000Z";

      const bodyAzzeraCampi = this.commesseAssegnateAperte.map((commessa) => ({
        _id: "",
        user: {
          id: this.providerUserId,
          fullname: this.userFullName,
        },
        commessa: {
          id: commessa._id || "",
          descrizione: commessa.titolo || "",
          commessanav: commessa.identificativonav || "",
        },
        date: firstDayISO,
        ore_ordinarie: 0,
        straordinario_ordinario: 0,
        straordinario_festivo: 0,
        straordinario_notturno: 0,
        reperibilita: 0,
        ferie: 0,
        rol: 0,
        malattia: 0,
        permessi_giustificati: 0,
        altro: 0,
        ore_ordinarie_notturne: 0,
        ore_ordinarie_festive: 0,
        tipo_assenza: "",
      }));

      console.log(
        "** rapportino giorno, mese, anno, bodyAzzeraCampi",
        giorno,
        mese,
        anno,
        bodyAzzeraCampi
      );
      this.isLoading = true;

      try {
        const response = await azzeraDatiRapportino(giorno, mese, anno, bodyAzzeraCampi);
        if (response) {
          console.log("** rapportino azzera campi respons", response);
        } else {
          showError();
          return;
        }
      } catch (error) {
        showError();
        return;
      } finally {
        this.isLoading = false;
        await this.fetchAllData();
      }
    },
    //
    // Gestione rapportino aggregato
    //
    async generateRapportinoAggregato(rapportinoAggregato) {
      console.log(
        "** rapportino inizio generazione aggregato per commessa, vista mensile"
      );
      const result = {
        commesse: [],
        totali_assenze: {
          tot_malattia: 0,
          tot_ferie: 0,
          tot_rol: 0,
          tot_permessi_giustificati: 0,
        },
        totali_ordinarie: {
          tot_ore_ordinarie: 0,
          tot_straordinario: 0,
          tot_ore_ordinarie_festive: 0,
          tot_ore_ordinarie_notturne: 0,
          tot_reperibilita: 0,
          tot_tickets_restaurant: 0,
        },
        totaleGlobaleOrdinarie: 0,
        totaleGlobaleAssenze: 0,
      };

      const commesseMap = {};

      // Loop su tutti i record per raggruppare e sommare i totali
      for (const record of rapportinoAggregato) {
        const { commessaId, commessaDescrizione, commessaNav } = record._id;

        // Se commessaId è vuoto, raggruppo le assenze
        if (!commessaId) {
          result.totali_assenze.tot_malattia += record.malattia;
          result.totali_assenze.tot_ferie += record.ferie;
          result.totali_assenze.tot_rol += record.rol;
          result.totali_assenze.tot_permessi_giustificati += record.permessi_giustificati;
        } else {
          // Se la commessa non è già nel commesseMap, creo un nuovo oggetto per la commessa
          if (!commesseMap[commessaId]) {
            const clienteInfo = await this.fetchClienteInfo(commessaId);
            commesseMap[commessaId] = {
              commessaId: commessaId,
              commessaDescrizione: commessaDescrizione,
              commessaNav: commessaNav,
              clienteId: clienteInfo ? clienteInfo.cliente.idcliente : "",
              clienteRagionesociale: clienteInfo ? clienteInfo.cliente.descrizione : "",
              tot_ore_ordinarie: 0,
              tot_straordinario: 0,
              tot_ore_ordinarie_festive: 0,
              tot_ore_ordinarie_notturne: 0,
              tot_reperibilita: 0,
              tot_tickets_restaurant: 0,
            };
          }

          // Aggiungo i valori alle proprietà corrispondenti
          commesseMap[commessaId].tot_ore_ordinarie += record.ore_ordinarie;
          commesseMap[commessaId].tot_straordinario +=
            record.straordinario_ordinario +
            record.straordinario_festivo +
            record.straordinario_notturno;
          commesseMap[commessaId].tot_ore_ordinarie_festive +=
            record.ore_ordinarie_festive;
          commesseMap[commessaId].tot_ore_ordinarie_notturne +=
            record.ore_ordinarie_notturne;
          commesseMap[commessaId].tot_reperibilita += record.reperibilita;
          commesseMap[commessaId].tot_tickets_restaurant += record.tickets_restaurant;
        }

        // Sommo anche i totali generali per ordinarie e straordinario
        result.totali_ordinarie.tot_ore_ordinarie += record.ore_ordinarie;
        result.totali_ordinarie.tot_straordinario +=
          record.straordinario_ordinario +
          record.straordinario_festivo +
          record.straordinario_notturno;
        result.totali_ordinarie.tot_ore_ordinarie_festive += record.ore_ordinarie_festive;
        result.totali_ordinarie.tot_ore_ordinarie_notturne +=
          record.ore_ordinarie_notturne;
        result.totali_ordinarie.tot_reperibilita += record.reperibilita;
        result.totali_ordinarie.tot_tickets_restaurant += record.tickets_restaurant;
      }

      result.totaleGlobaleOrdinarie =
        result.totali_ordinarie.tot_ore_ordinarie +
        result.totali_ordinarie.tot_straordinario +
        result.totali_ordinarie.tot_ore_ordinarie_festive +
        result.totali_ordinarie.tot_ore_ordinarie_notturne +
        result.totali_ordinarie.tot_reperibilita;

      result.totaleGlobaleAssenze =
        result.totali_assenze.tot_malattia +
        result.totali_assenze.tot_ferie +
        result.totali_assenze.tot_rol +
        result.totali_assenze.tot_permessi_giustificati;

      // Converto la mappa delle commesse in un array
      result.commesse = Object.values(commesseMap);

      return result;
    },

    async fetchClienteInfo(commessaId) {
      if (!commessaId) return;
      const defaultClienteInfo = {
        cliente: {
          idcliente: "",
          descrizione: "-",
        },
      };
      try {
        const response = await fetchCommessaById(commessaId);
        if (response && response.cliente) {
          return new Commessa(response);
        } else {
          return defaultClienteInfo;
        }
      } catch (error) {
        console.error("** errore", error.message);
        return defaultClienteInfo;
      }
    },
    async fetchRapportinoAggregato(dateFrom, dateTo) {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.dataRapportinoAggregato = [];
      this.dataRapportinoGrouped = {};

      try {
        const response = await fetchUserAttivitaRapportinoAggregato(
          this.providerUserId,
          dateFrom,
          dateTo
        );
        if (response) {
          this.dataRapportinoAggregato = response;
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async sommaRapportinoAnnuale(year) {
      // Inizializza le variabili per sommare i risultati
      let totali = {
        oreImputabili: 0,
        oreStrConsentite: 0,
        strResiduo: 0,
      };

      this.dataRapportino.info = totali;

      this.$nextTick(() => {
        this.isLoading = true;
      });

      // Eseguo 12 chiamate fetch, una per ogni mese
      for (let i = 1; i <= 12; i++) {
        try {
          const response = await fetchUserAttivitaRapportino(
            this.providerUserId,
            year,
            i.toString()
          );

          // Controllo se la risposta contiene l'oggetto 'info'
          if (response && response.info) {
            // Sommo i valori dalle chiavi 'info'
            totali.oreImputabili += response.info.oreImputabili || 0;
            totali.oreStrConsentite += response.info.oreStrConsentite || 0;
            totali.strResiduo += response.info.strResiduo || 0;
          } else {
            console.warn(`Nessun dato valido per il mese ${i}`);
          }
        } catch (error) {
          console.error(`Errore nel fetch per il mese ${i}:`, error);
        }
      }
      this.$nextTick(() => {
        this.isLoading = false;
      });
      // Restituisci l'oggetto con i totali
      return totali;
    },
    composeDateRangeRapportinoAggregato() {
      let result;

      if (this.visualizzazione === "giornaliera") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const giorno = this.selectedGiorno.toString().padStart(2, "0");
        result = {
          start: `${anno}-${mese}-${giorno}`,
          end: `${anno}-${mese}-${giorno}`,
        };
      } else if (this.visualizzazione === "settimanale") {
        result = {
          start: this.selectedSettimana.start,
          end: this.selectedSettimana.end,
        };
      } else if (this.visualizzazione === "mensile") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const firstDay = `${anno}-${mese}-01`;
        const lastDay = `${anno}-${mese}-${new Date(anno, parseInt(mese), 0)
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        result = {
          start: firstDay,
          end: lastDay,
        };
      } else if (this.visualizzazione === "annuale") {
        const anno = this.selectedAnno;
        result = {
          start: `${anno}-01-01`,
          end: `${anno}-12-31`,
        };
      }
      console.log("** rapportino composeDateRangeRapportinoAggregato", result);
      return result;
    },
    composeYearMonthRapportino() {
      let result;

      if (
        this.visualizzazione === "giornaliera" ||
        this.visualizzazione === "settimanale"
      ) {
        // Per le viste giornaliera e settimanale, prendo anno e mese del giorno selezionato
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1).toString();
        result = {
          year: anno.toString(),
          month: mese, // Restituisco il numero del mese senza padding
        };
      } else if (this.visualizzazione === "mensile") {
        // Per la vista mensile, uso solo l'anno e il mese selezionato
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1).toString();
        result = {
          year: anno.toString(),
          month: mese, // Restituisco il numero del mese senza padding
        };
      }
      console.log("** rapportino composeYearMonthRapportino", result);
      return result;
    },
    //
    // Gestione rapportino dettagliato mensile
    //
    async fetchRapportino(year, month) {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.dataRapportino = [];

      try {
        const response = await fetchUserAttivitaRapportino(
          this.providerUserId,
          year,
          month
        );
        if (response) {
          this.dataRapportino = response;
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    generateRapportinoMensileByCommessa(data) {
      // Repportinno dettagliato per mese, raggruppato per commessa e ordinato per data (separato commesse e assenze)
      const grouped = {};
      const globalTotalsOrdinarie = {
        total_tot_ore_ordinarie: 0,
        total_tot_straordinario_ordinario: 0,
        total_tot_straordinario_festivo: 0,
        total_tot_straordinario_notturno: 0,
        total_tot_reperibilita: 0,
        total_tot_ore_ordinarie_notturne: 0,
        total_tot_ore_ordinarie_festive: 0,
      };

      const globalTotalsAssenze = {
        total_tot_ferie: 0,
        total_tot_rol: 0,
        total_tot_malattia: 0,
        total_tot_permessi_giustificati: 0,
        total_tot_altro: 0,
      };

      // Funzione per ottenere tutti i giorni del mese
      const getDaysInMonth = (year, month) => {
        // Mi assicuro che il mese sia un numero tra 1 e 12
        month = Math.max(1, Math.min(12, month));
        // Creo una data per il primo giorno del mese specificato
        const firstDay = new Date(Date.UTC(year, month - 1, 1));
        // Calcolo l'ultimo giorno del mese
        const lastDay = new Date(Date.UTC(year, month, 0));
        const days = [];
        // Itero dal primo all'ultimo giorno del mese
        for (
          let day = new Date(firstDay);
          day <= lastDay;
          day.setUTCDate(day.getUTCDate() + 1)
        ) {
          days.push(day.toISOString().split("T")[0]);
        }
        return days;
      };

      const daysInMonth = getDaysInMonth(
        this.selectedAnno,
        this.getMonthNumber(this.selectedMese)
      );
      console.log("** rapportino daysInMonth", daysInMonth);

      // Verifico che "data" sia un array prima di eseguire il forEach
      if (!Array.isArray(data)) {
        console.error("Il parametro 'data' non è un array.");
        return [];
      }

      data.forEach((entry) => {
        const {
          commessaId,
          commessaDescrizione,
          commessaNav,
          day,
          tipo_assenza,
          id_rapportino,
        } = entry._id;

        const isAssenza = commessaId === "";
        const groupKey = isAssenza ? "assenze" : commessaId;

        if (!grouped[groupKey]) {
          grouped[groupKey] = {
            [isAssenza ? "assenze" : "commessa"]: {
              commessaId: commessaId,
              commessaDescrizione: commessaDescrizione,
              commessaNav: commessaNav,
            },
            days_details: [],
            totals: {
              tot_ore_ordinarie: 0,
              tot_straordinario_ordinario: 0,
              tot_straordinario_festivo: 0,
              tot_straordinario_notturno: 0,
              tot_reperibilita: 0,
              tot_ferie: 0,
              tot_rol: 0,
              tot_malattia: 0,
              tot_permessi_giustificati: 0,
              tot_altro: 0,
              tot_ore_ordinarie_notturne: 0,
              tot_ore_ordinarie_festive: 0,
            },
          };
        }

        grouped[groupKey].days_details.push({
          day: day,
          id_rapportino: id_rapportino || "",
          tipo_assenza: tipo_assenza || "- Tipo di permesso non specificato -",
          ore_ordinarie: entry.ore_ordinarie,
          straordinario_ordinario: entry.straordinario_ordinario,
          straordinario_festivo: entry.straordinario_festivo,
          straordinario_notturno: entry.straordinario_notturno,
          reperibilita: entry.reperibilita,
          ferie: entry.ferie,
          rol: entry.rol,
          malattia: entry.malattia,
          permessi_giustificati: entry.permessi_giustificati,
          altro: entry.altro,
          ore_ordinarie_notturne: entry.ore_ordinarie_notturne,
          ore_ordinarie_festive: entry.ore_ordinarie_festive,
        });

        // Somma dei totali per commessa o assenze
        const totals = grouped[groupKey].totals;
        totals.tot_ore_ordinarie += entry.ore_ordinarie;
        totals.tot_straordinario_ordinario += entry.straordinario_ordinario;
        totals.tot_straordinario_festivo += entry.straordinario_festivo;
        totals.tot_straordinario_notturno += entry.straordinario_notturno;
        totals.tot_reperibilita += entry.reperibilita;
        totals.tot_ferie += entry.ferie;
        totals.tot_rol += entry.rol;
        totals.tot_malattia += entry.malattia;
        totals.tot_permessi_giustificati += entry.permessi_giustificati;
        totals.tot_altro += entry.altro;
        totals.tot_ore_ordinarie_notturne += entry.ore_ordinarie_notturne;
        totals.tot_ore_ordinarie_festive += entry.ore_ordinarie_festive;

        if (isAssenza) {
          globalTotalsAssenze.total_tot_ferie += entry.ferie;
          globalTotalsAssenze.total_tot_rol += entry.rol;
          globalTotalsAssenze.total_tot_malattia += entry.malattia;
          globalTotalsAssenze.total_tot_permessi_giustificati +=
            entry.permessi_giustificati;
          globalTotalsAssenze.total_tot_altro += entry.altro;
        } else {
          globalTotalsOrdinarie.total_tot_ore_ordinarie += entry.ore_ordinarie;
          globalTotalsOrdinarie.total_tot_straordinario_ordinario +=
            entry.straordinario_ordinario;
          globalTotalsOrdinarie.total_tot_straordinario_festivo +=
            entry.straordinario_festivo;
          globalTotalsOrdinarie.total_tot_straordinario_notturno +=
            entry.straordinario_notturno;
          globalTotalsOrdinarie.total_tot_reperibilita += entry.reperibilita;
          globalTotalsOrdinarie.total_tot_ore_ordinarie_notturne +=
            entry.ore_ordinarie_notturne;
          globalTotalsOrdinarie.total_tot_ore_ordinarie_festive +=
            entry.ore_ordinarie_festive;
        }
      });

      Object.values(grouped).forEach((commessa) => {
        const existingDays = new Set(commessa.days_details.map((detail) => detail.day));
        daysInMonth.forEach((day) => {
          if (!existingDays.has(day)) {
            commessa.days_details.push({
              day: day,
              id_rapportino: "",
              tipo_assenza: "",
              ore_ordinarie: 0,
              straordinario_ordinario: 0,
              straordinario_festivo: 0,
              straordinario_notturno: 0,
              reperibilita: 0,
              ferie: 0,
              rol: 0,
              malattia: 0,
              permessi_giustificati: 0,
              altro: 0,
              ore_ordinarie_notturne: 0,
              ore_ordinarie_festive: 0,
            });
          }
        });
        // Ordino per date in day
        commessa.days_details.sort((a, b) => new Date(a.day) - new Date(b.day));
        // Ordino per 'datainizio' crescente
        // commessa.sort((a, b) => new Date(a.datainizio) - new Date(b.datainizio));
      });

      const result = Object.values(grouped);
      result.push({
        totals_ordinarie: globalTotalsOrdinarie,
        totals_assenze: globalTotalsAssenze,
      });

      return result;
    },
    generateDaysForMonth(year, month) {
      const date = new Date(year, month - 1, 1);
      const days = [];
      const dayNames = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];

      // Mappo le festività in un array per una ricerca più complessa
      const festivitaMap = this.tabellaFestivita.map((festivita) => ({
        date: new Date(festivita.data).toLocaleDateString("it-IT"),
        locale: festivita.locale,
        citta: festivita.citta ? festivita.citta.toLowerCase() : null,
        descrizione: festivita.descrizione,
      }));

      // Funzione per formattare la data in "YYYY-MM-DD"
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Aggiungo lo 0 se necessario
        const day = String(date.getDate()).padStart(2, "0"); // Aggiungo lo 0 se necessario
        return `${year}-${month}-${day}`;
      };

      // Verifico se esiste almeno un giorno con `closed: true` in `this.dataRapportino.data`
      const hasClosedDay = this.dataRapportino.data.some(
        (rapportino) => rapportino._id.closed === true
      );

      // Verifico se esiste almeno un giorno con `validated: true` in `this.dataRapportino.data`
      const hasValidatedDay = this.dataRapportino.data.some(
        (rapportino) => rapportino._id.validated === true
      );

      while (date.getMonth() === month - 1) {
        const dayOfWeek = date.getDay();
        const dateString = formatDate(date); // Utilizzo la funzione per ottenere la stringa "YYYY-MM-DD"

        // Variabili per festività
        let isFestivita = false;
        let isFestivitaLocale = false;
        let descrizioneFestivita = "";

        // Controllo se la data è una festività
        const festivita = festivitaMap.find(
          (festivita) => festivita.date === date.toLocaleDateString("it-IT")
        );
        if (festivita) {
          if (festivita.locale) {
            if (this.userCitta) {
              isFestivitaLocale = festivita.locale;
              isFestivita =
                festivita.citta && festivita.citta === this.userCitta.toLowerCase();
            }
          } else {
            isFestivita = true;
          }
          descrizioneFestivita = festivita.descrizione;
        }

        // Imposta `closed` in base alla presenza di almeno un giorno con `closed: true`
        const isClosed = hasClosedDay;

        // Imposta `validated` in base alla presenza di almeno un giorno con `closed: true`
        const isValidated = hasValidatedDay;

        days.push({
          date: new Date(date),
          dateString: dateString, // Uso il formato "YYYY-MM-DD"
          dayOfMonth: date.getDate(),
          dayName: dayNames[dayOfWeek],
          isDomenica: dayOfWeek === 0,
          isSabato: dayOfWeek === 6,
          isFestivita: isFestivita, // Verifico se è una festività
          isFestivitaLocale: isFestivitaLocale, // Aggiungo se la festività è locale
          descrizioneFestivita: descrizioneFestivita, // Aggiungo la descrizione della festività
          closed: isClosed, // Aggiungo la chiave closed: true o false
          validated: isValidated, // Aggiungo la chiave validated: true o false
        });

        date.setDate(date.getDate() + 1);
      }

      return days;
    },
    formatHours(hours) {
      return (hours || 0).toFixed(1).replace(".", ",");
    },
    isZeroValue(value) {
      return value === 0.0 || value === 0 || value === "0,0" || value === "0.0";
    },
    getCommessaKey(commessa) {
      return commessa.commessa ? commessa.commessa.commessaId : "assenze";
    },
    getCommessaDescrizione(commessa) {
      return commessa.commessa ? commessa.commessa.commessaDescrizione : "Assenze";
    },
    getCommessaNav(commessa) {
      return commessa.commessa ? commessa.commessa.commessaNav : "";
    },
    getHours(item, date, type) {
      if (!item || !item.days_details) {
        return 0;
      }
      const dayData = item.days_details.find(
        (d) => d && d.day && new Date(d.day).toDateString() === date.toDateString()
      );
      return dayData && dayData[type] ? dayData[type] : 0;
    },
    getTotal(item, totalType) {
      return item && item.totals && item.totals[totalType] ? item.totals[totalType] : 0;
    },
    getTotalOrdinaryHours(date) {
      if (date) {
        return this.commesse.reduce((total, commessa) => {
          return total + this.getHours(commessa, date, "ore_ordinarie");
        }, 0);
      } else {
        return this.commesse.reduce((total, commessa) => {
          return total + this.getTotal(commessa, "tot_ore_ordinarie");
        }, 0);
      }
    },
    getPermessoGiustificatoHours(item, date, tipo) {
      if (!item || !item.days_details) {
        return 0;
      }
      const safeDate = date ? new Date(date) : new Date(); // Uso la data attuale se 'date' è undefined
      const dayData = Array.isArray(item.days_details)
        ? item.days_details
            .filter((d) => d && d.day)
            .find((d) => new Date(d.day).toDateString() === safeDate.toDateString())
        : undefined;
      return dayData && dayData.permessi_giustificati > 0 && dayData.tipo_assenza === tipo
        ? dayData.permessi_giustificati
        : 0;
    },
    // getPermessoGiustificatoHours(item, date, tipo) {
    //   if (!item || !item.days_details) {
    //     return 0;
    //   }
    //   const dayData = item.days_details.find(
    //     (d) => d && d.day && new Date(d.day).toDateString() === date.toDateString()
    //   );
    //   return dayData && dayData.permessi_giustificati > 0 && dayData.tipo_assenza === tipo
    //     ? dayData.permessi_giustificati
    //     : 0;
    // },
    calcolaPermessiGiustificati() {
      this.permessiGiustificati = [];

      if (!this.assenze || !this.assenze.days_details) {
        return;
      }

      const permessi = {};
      if (Array.isArray(this.assenze.days_details)) {
        // Controllo che sia un array
        this.assenze.days_details.forEach((day) => {
          if (day.permessi_giustificati > 0) {
            if (!permessi[day.tipo_assenza]) {
              permessi[day.tipo_assenza] = 0;
            }
            permessi[day.tipo_assenza] += day.permessi_giustificati;
          }
        });
      }

      this.permessiGiustificati = Object.entries(permessi).map(([tipo, totale]) => ({
        tipo,
        totale,
      }));

      console.log("** rapportino assenze", this.assenze);
      console.log("** rapportino permessi giustificati", this.permessiGiustificati);
    },
    //
    // Altri metodi
    //
    closeAlert() {
      clearTimeout(this.messageTimeoutId);
      this.messageTimeoutId = null;
      this.isAlertVisible = false;
    },
    showMessage(message) {
      // Se c'è già un timeout attivo, cancello il precedente
      if (this.messageTimeoutId) {
        // clearTimeout(this.messageTimeoutId);
        // this.messageTimeoutId = null;
        return;
      }

      // Imposto il nuovo messaggio e visualizza l'alert
      this.htmlMessage = message;
      this.isAlertVisible = true;

      // Imposto un nuovo timeout e salvo l'ID
      this.messageTimeoutId = setTimeout(() => {
        this.isAlertVisible = false;
        this.messageTimeoutId = null; // Resetto l'ID del timeout una volta completato
      }, 10000);
    },
    // Funzione per impostare il giorno selezionato
    setGiorno(giorno) {
      this.selectedGiorno = giorno;
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },
    // Funzione per selezionare la settimana
    setSettimana(settimana) {
      this.selectedSettimana = settimana;

      // Verifica se il giorno corrente cade nell'intervallo della settimana selezionata
      const startDate = new Date(settimana.start);
      const endDate = new Date(settimana.end);
      const selectedDate = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );

      // Se il giorno selezionato non è nell'intervallo della settimana, aggiorna giorno e mese
      if (selectedDate < startDate || selectedDate > endDate) {
        this.selectedGiorno = startDate.getDate(); // Imposta il giorno al primo giorno della settimana
        this.selectedMese = this.mesi[startDate.getMonth()]; // Imposta il mese corretto
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },
    // Funzione per impostare il mese selezionato e ripopolare i giorni
    setMese(mese) {
      this.selectedMese = mese;
      const meseIndex = this.mesi.indexOf(mese) + 1;
      this.populateGiorni(this.selectedAnno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
      console.log("** rapportino mese selezionato", this.selectedMese);
    },
    // Funzione per impostare l'anno selezionato e ripopolare i giorni
    setAnno(anno) {
      this.selectedAnno = anno;
      const meseIndex = this.mesi.indexOf(this.selectedMese) + 1;
      this.populateGiorni(anno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },
    // Popola gli anni dinamicamente dall'anno corrente al 2010
    populateAnni() {
      this.anni = this.mixins_getArrayYearsUpToCurrentYear();
    },
    // Popola i giorni del mese selezionato
    populateGiorni(anno, mese) {
      const daysInMonth = new Date(anno, mese, 0).getDate();
      this.giorni = Array.from({ length: daysInMonth }, (v, i) => i + 1);
    },
    // Funzione per ripopolare le settimane in base al giorno, mese e anno selezionati
    updateSettimane() {
      const date = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );

      const currentWeek = this.getStartOfWeek(date);
      this.settimane = this.calculateSurroundingWeeks(currentWeek, 5);
      this.selectedSettimana = this.settimane[5]; // Seleziona la settimana corrente
    },
    getStartOfWeek(date) {
      const day = date.getDay();
      const diff = date.getDate() - (day === 0 ? 6 : day - 1); // Ottieni il lunedì
      return new Date(date.setDate(diff));
    },
    // Calcolo settimane, 5 precedenti e 5 successive
    calculateSurroundingWeeks(startDate, range) {
      const weeks = [];
      const monday = new Date(startDate);

      // 5 settimane precedenti
      for (let i = range; i > 0; i--) {
        const previousMonday = new Date(monday);
        previousMonday.setDate(monday.getDate() - 7 * i);
        const sunday = new Date(previousMonday);
        sunday.setDate(previousMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            previousMonday
          )} - ${this.formatDateForDisplay(sunday)}`,
          start: this.formatDate(previousMonday),
          end: this.formatDate(sunday),
        });
      }

      // Settimana corrente
      const currentMonday = new Date(monday);
      const currentSunday = new Date(currentMonday);
      currentSunday.setDate(currentMonday.getDate() + 6);
      weeks.push({
        display: `${this.formatDateForDisplay(
          currentMonday
        )} - ${this.formatDateForDisplay(currentSunday)}`,
        start: this.formatDate(currentMonday),
        end: this.formatDate(currentSunday),
      });

      // 5 settimane successive
      for (let i = 1; i <= range; i++) {
        const nextMonday = new Date(monday);
        nextMonday.setDate(monday.getDate() + 7 * i);
        const nextSunday = new Date(nextMonday);
        nextSunday.setDate(nextMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            nextMonday
          )} - ${this.formatDateForDisplay(nextSunday)}`,
          start: this.formatDate(nextMonday),
          end: this.formatDate(nextSunday),
        });
      }

      return weeks;
    },
    getMonthNumber(monthName) {
      const monthIndex = this.mesi.indexOf(monthName);
      return monthIndex !== -1 ? monthIndex + 1 : null;
    },
    formatDateForDisplay(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = this.mesi[date.getMonth()].substring(0, 3);
      return `${day} ${month}`;
    },
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    },
    aggiornaIntestazione() {
      if (this.visualizzazione === "giornaliera") {
        // Formato: "Giornaliero del 4 settembre 2024"
        this.intestazione = `Giornaliero del ${
          this.selectedGiorno
        } ${this.selectedMese.toLowerCase()} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "settimanale") {
        // Formato: "Settimanale - 2 settembre 8 settembre 2024"
        const start = new Date(this.selectedSettimana.start);
        const end = new Date(this.selectedSettimana.end);
        this.intestazione = `Settimanale - ${start.getDate()} ${this.mesi[
          start.getMonth()
        ].toLowerCase()}, ${end.getDate()} ${this.mesi[end.getMonth()].toLowerCase()} ${
          this.selectedAnno
        }`;
      } else if (this.visualizzazione === "mensile") {
        // Formato: "Mensile - Settembre 2024"
        this.intestazione = `Mensile - ${this.selectedMese} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "annuale") {
        // Formato: "Annuale - 2024"
        this.intestazione = `Annuale - ${this.selectedAnno}`;
      }
    },
    async onVai() {
      await this.fetchAllData();
    },
    formatDateRange(dataFrom, dataTo) {
      const options = { month: "long", year: "numeric" };
      const fromDate = new Date(dataFrom);
      const toDate = new Date(dataTo);

      const dayFrom = fromDate.getDate();
      const dayTo = toDate.getDate();
      const monthYear = toDate.toLocaleDateString("it-IT", options);

      const formattedMonthYear = monthYear.charAt(0).toUpperCase() + monthYear.slice(1);

      return `dal ${dayFrom} al ${dayTo} ${formattedMonthYear}`;
    },
    formatDateLocale(date) {
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      // Opzioni per formattare la data in italiano con giorno della settimana e mese per esteso
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      // Converto la data nel formato italiano con le opzioni
      const localeDate = new Date(date).toLocaleDateString("it-IT", options);

      // Separo i componenti della data (giorno della settimana, giorno del mese, mese e anno)
      const [weekday, day, month, year] = localeDate.split(" ");

      // Formatto la stringa con la virgola dopo il giorno della settimana
      return `${capitalizeFirstLetter(weekday)}, ${day} ${capitalizeFirstLetter(
        month
      )} ${year}`;
    },
    //
    // Gestione add / edit
    //
    editDaily(day) {
      console.log("** rapportino editDaily day", day);

      // Se l'utente loggato non ha profili non proseguo
      if (this.userProfili.length == 0) {
        this.mixins_showMessage(
          "Inserimento dati attività",
          "Impossibile inserire i dati, l'utente non ha un profilo",
          "warning"
        );
        return;
      }

      // Se non ci sono commesse aperte non proseguo
      if (this.commesseAssegnateAperte.length == 0) {
        this.mixins_showMessage(
          "Inserimento dati attività",
          "Impossibile inserire i dati, non ci sono commesse assegnate aperte",
          "warning"
        );
        return;
      }

      // Converto la data di day.date in ISO date format e l'aggiungo all'oggetto day
      const date = new Date(day.date);
      day.dateIso = date.toISOString();

      // Se la data selezionata è antecedente alla data inizio rapporto
      if (!this.checkDateValidityInizioRapporto(day)) {
        this.mixins_showMessage(
          "Inserimento dati attività",
          "Impossibile inserire i dati, la data selezionata non può essere antecedente alla data di inizio rapporto",
          "warning"
        );
        return;
      }

      // Genero titolo del dialog
      const dateString = day.date ? this.formatDateLocale(day.date) : "";
      const isFestivo =
        day.isFestivita || day.isDomenica
          ? day.isFestivita
            ? `(Festivo) (${day.descrizioneFestivita})`
            : "(Festivo)"
          : "(Ordinario)";
      this.dialogTitle = dateString + " " + isFestivo;

      // Raggruppo i dati per commessa dividendoli in ordinario e assenze, per avere facilità di calcolo nel dialog
      const exportCommesseGrouped = this.groupDataByDayCommessa(new Day(day));
      console.log("** rapportino exportCommesseGrouped", exportCommesseGrouped);

      // Unione degli oggetti day, exportCommesseGrouped, this.dataRapportino.info, this.userProfili, this.userOreContrattuali, this.userAbilitazioni in un unico oggetto dataOfDay che passo al dialog per la modifica
      const exportInfo = { info: this.dataRapportino.info };
      const exportDay = { day: day };
      const exportProfili = { profili: this.userProfili };
      const exportOreContratt = { orecontrattuali: this.userOreContrattuali };
      // const exportAbilitazioni = { abilitazioni: this.userAbilitazioni };
      this.dataOfDay = {
        ...exportDay,
        ...exportCommesseGrouped,
        ...exportInfo,
        ...exportProfili,
        ...exportOreContratt,
        // ...exportAbilitazioni,
      };
      console.log("** rapportino del giorno:" + day.dateString, this.dataOfDay);

      this.isDialogVisible = true;
    },

    checkDateValidityInizioRapporto(day) {
      // Verifico che i parametri esistano
      if (!this.localRapporto?.datainiziorapporto || !day?.dateIso) {
        console.error("** date mancanti:", {
          day: day,
          datainiziorapporto: this.localRapporto?.datainiziorapporto,
          dateIso: day?.dateIso,
        });
        return false;
      }

      try {
        // Converto le stringhe in oggetti Date
        const dataInizioRapporto = new Date(this.localRapporto.datainiziorapporto);
        const dataSelezionata = new Date(day.dateIso);

        // Verifico che le date siano valide
        if (isNaN(dataInizioRapporto.getTime()) || isNaN(dataSelezionata.getTime())) {
          console.error("** date non valide:", {
            dataInizioRapporto,
            dataSelezionata,
          });
          return false;
        }

        // Resetto le ore per entrambe le date
        const dataInizioRapportoSoloGiorno = new Date(
          dataInizioRapporto.getFullYear(),
          dataInizioRapporto.getMonth(),
          dataInizioRapporto.getDate()
        );

        const dataSelezionataSoloGiorno = new Date(
          dataSelezionata.getFullYear(),
          dataSelezionata.getMonth(),
          dataSelezionata.getDate()
        );

        // Confronto le date
        return dataSelezionataSoloGiorno > dataInizioRapportoSoloGiorno;
      } catch (error) {
        console.error("** errore durante il confronto delle date:", error);
        return false;
      }
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    getNextDay(dateString) {
      const [year, month, day] = dateString.split("-").map(Number);

      // Verifica se la data è valida
      const date = new Date(year, month - 1, day);
      if (isNaN(date.getTime())) {
        return null; // La data non è valida
      }

      // Ottieni il giorno successivo
      date.setDate(date.getDate() + 1);

      // Verifica se è il primo giorno del mese successivo
      if (date.getDate() === 1 && date.getMonth() + 1 !== month) {
        return null; // È l'ultimo giorno del mese
      }

      // Ottieni le informazioni necessarie nel formato richiesto
      const giorno = date.getDate();
      const mese = date.getMonth() + 1;
      const anno = date.getFullYear();

      return { giorno, mese, anno };
    },
    async saveData(data, completaMese) {
      this.isLoading = true;
      console.log("** rapportino, dati ricevuti dal dialog", data);

      const day = data?.day?.dateString || null;
      const nextDay = this.getNextDay(day);

      console.log("** rapportino, nextDay", nextDay);
      const rapportini = this.populateRapportini(data);
      console.log("** rapportino, generazione dei rapportini", rapportini);

      // Itero su rapportinoOrdinario
      for (const item of rapportini.rapportinoOrdinario) {
        try {
          if (item._id === "") {
            // Se l'_id è vuoto, chiamo il metodo per creare un nuovo rapportino ordinario
            await createRapportino(item);
          } else {
            // Se l'_id non è vuoto, chiamo il metodo per modificare un rapportino ordinario esistente
            await updateRapportino(item);
          }
          // Se completa mese è true, calcolo il nextDay e richiamo endpoint per il completa mese
          if (completaMese && nextDay) {
            console.log("** rapportino, item per il body", item);
            try {
              await completaMeseRapportino(
                nextDay.giorno,
                nextDay.mese,
                nextDay.anno,
                item
              );
            } catch (error) {
              console.error("** errore durante il completa mese", error);
            }
          }
        } catch (error) {
          console.error("Errore durante l'invio di un rapportino ordinario: ", error);
          this.mixins_showMessage(
            "Aggiornamento dati",
            `Errore durante l'invio dei dati, si prega di controllare ed eventualmente riprovare`,
            "error"
          );
          // Continuo il ciclo anche in caso di errore
        }
      }

      // Itero su rapportinoAssenze
      for (const item of rapportini.rapportinoAssenze) {
        // Verifico se la somma di altro, ferie, malattia, permessi_giustificati e rol è > 0
        const totalAssenze =
          item.altro + item.ferie + item.malattia + item.permessi_giustificati + item.rol;

        if (totalAssenze > 0) {
          try {
            if (item._id === "") {
              // Se l'_id è vuoto, chiamo il metodo per creare un nuovo rapportino assenze
              await createRapportino(item);
            } else {
              // Se l'_id non è vuoto, chiamo il metodo per modificare un rapportino assenze esistente
              await updateRapportino(item);
            }
          } catch (error) {
            console.error("Errore durante l'invio di un rapportino assenze: ", error);
            this.mixins_showMessage(
              "Aggiornamento dati",
              `Errore durante l'invio dei dati, si prega di controllare ed eventualmente riprovare`,
              "error"
            );
            // Continuo il ciclo anche in caso di errore
          }
        }
      }

      this.closeModal();

      this.isLoading = true;
      await this.fetchAllData();

      this.mixins_showMessage(
        "Aggiornamento dati",
        `Dati aggiornati correttamente`,
        "success"
      );

      this.isLoading = false;
    },
    populateRapportini(data) {
      const rapportinoOrdinario = [];
      const rapportinoAssenze = [];

      // Funzione per convertire la stringa giorno in una ISO con l'ora impostata a 00:00:00
      const formatDateWithZeroTime = (dayString) => {
        console.warn("** dateString", dayString);
        const date = new Date(dayString);
        date.setUTCHours(0, 0, 0, 0); // Imposta l'ora a 00:00:00
        console.warn("** day after", date.setUTCHours(0, 0, 0, 0));
        return date.toISOString();
      };

      // Popolare il rapportinoOrdinario
      if (data.ordinario && Array.isArray(data.ordinario)) {
        data.ordinario.forEach((ordinario) => {
          const newOrdinario = {
            _id: ordinario._id.id_rapportino || "", // Campo _id corretto
            user: {
              id: this.providerUserId,
              fullname: this.userFullName,
            },
            commessa: {
              id: ordinario._id.commessaId || "",
              descrizione: ordinario._id.commessaDescrizione || "",
              commessanav: ordinario._id.commessaNav || "",
            },
            date: formatDateWithZeroTime(ordinario._id.day), // Usa il campo day di ordinario
            ore_ordinarie: ordinario.ore_ordinarie || 0,
            straordinario_ordinario: ordinario.straordinario_ordinario || 0,
            straordinario_festivo: ordinario.straordinario_festivo || 0,
            straordinario_notturno: ordinario.straordinario_notturno || 0,
            reperibilita: ordinario.reperibilita || 0,
            ferie: ordinario.ferie || 0,
            rol: ordinario.rol || 0,
            malattia: ordinario.malattia || 0,
            permessi_giustificati: ordinario.permessi_giustificati || 0,
            altro: ordinario.altro || 0,
            ore_ordinarie_notturne: ordinario.ore_ordinarie_notturne || 0,
            ore_ordinarie_festive: ordinario.ore_ordinarie_festive || 0,
            tipo_assenza: ordinario._id.tipo_assenza || "",
          };
          rapportinoOrdinario.push(newOrdinario);
        });
      }

      // Popolare il rapportinoAssenze
      if (data.assenze && Array.isArray(data.assenze)) {
        data.assenze.forEach((assenze) => {
          const newAssenze = {
            _id: assenze._id.id_rapportino || "", // Campo _id corretto
            user: {
              id: this.providerUserId,
              fullname: this.userFullName,
            },
            commessa: {
              id: assenze._id.commessaId || "",
              descrizione: assenze._id.commessaDescrizione || "",
              commessanav: assenze._id.commessaNav || "",
            },
            date: formatDateWithZeroTime(assenze._id.day), // Usa il campo day di assenze
            ore_ordinarie: assenze.ore_ordinarie || 0,
            straordinario_ordinario: assenze.straordinario_ordinario || 0,
            straordinario_festivo: assenze.straordinario_festivo || 0,
            straordinario_notturno: assenze.straordinario_notturno || 0,
            reperibilita: assenze.reperibilita || 0,
            ferie: assenze.ferie || 0,
            rol: assenze.rol || 0,
            malattia: assenze.malattia || 0,
            permessi_giustificati: assenze.permessi_giustificati || 0,
            altro: assenze.altro || 0,
            ore_ordinarie_notturne: assenze.ore_ordinarie_notturne || 0,
            ore_ordinarie_festive: assenze.ore_ordinarie_festive || 0,
            tipo_assenza: assenze._id.tipo_assenza || "",
          };
          rapportinoAssenze.push(newAssenze);
        });
      }

      return {
        rapportinoOrdinario,
        rapportinoAssenze,
      };
    },

    // getResourceById(userId) {
    //   for (const commessa of this.commesseAssegnateAperte) {
    //     const foundResource = commessa.risorseassociate.find(
    //       (risorsa) => risorsa.idrisorsa === userId
    //     );
    //     if (foundResource) {
    //       return foundResource; // Restituisco solo la prima corrispondenza
    //     }
    //   }
    //   return null; // Restituisco null se non trova nessuna corrispondenza
    // },

    // Con ordinamento per far vedere eliminato alla fine
    groupDataByDayCommessa(day) {
      const result = {
        ordinario: [],
        assenze: [],
        tot_ore_ordinarie: 0,
        tot_straordinario_ordinario: 0,
        tot_straordinario_festivo: 0,
        tot_straordinario_notturno: 0,
        tot_reperibilita: 0,
        tot_ferie: 0,
        tot_rol: 0,
        tot_malattia: 0,
        tot_permessi_giustificati: 0,
        tot_altro: 0,
        tot_ore_ordinarie_notturne: 0,
        tot_ore_ordinarie_festive: 0,
      };

      // Filtriamo i dati che corrispondono al giorno richiesto
      const commesseGiornaliere = this.dataRapportino.data.filter(
        (item) => item._id.day === day.dateString
      );

      // Creiamo un set di commessaId già elaborati per evitare duplicati
      const commessaIdProcessed = new Set();

      // Se troviamo delle commesse per quel giorno, le processiamo
      commesseGiornaliere.forEach((item) => {
        const commessa = new DayCommessa({
          _id: item._id,
          ore_ordinarie: item.ore_ordinarie,
          straordinario_ordinario: item.straordinario_ordinario,
          straordinario_festivo: item.straordinario_festivo,
          straordinario_notturno: item.straordinario_notturno,
          reperibilita: item.reperibilita,
          ferie: item.ferie,
          rol: item.rol,
          malattia: item.malattia,
          permessi_giustificati: item.permessi_giustificati,
          altro: item.altro,
          ore_ordinarie_notturne: item.ore_ordinarie_notturne,
          ore_ordinarie_festive: item.ore_ordinarie_festive,
        });

        // Recupera la proprietà eliminato dalle commesseAssegnateAperte
        const commessaAperta = this.commesseAssegnateAperte.find(
          (c) => c._id === item._id.commessaId
        );
        if (commessaAperta) {
          commessa.eliminato = commessaAperta.eliminato || false;

          // Trova la risorsa associata tramite idrisorsa e this.providerUserId
          const risorsaAssociata = commessaAperta.risorseassociate.find(
            (r) => r.idrisorsa === this.providerUserId
          );

          // Se troviamo la risorsa, impostiamo le abilitazioni
          if (risorsaAssociata) {
            commessa.abilitazioni = {
              straordinario:
                risorsaAssociata.straordinario !== undefined
                  ? risorsaAssociata.straordinario
                  : true,
              reperibilita:
                risorsaAssociata.reperibilita !== undefined
                  ? risorsaAssociata.reperibilita
                  : true,
            };
          } else {
            // Imposta i valori di default se non troviamo la risorsa
            commessa.abilitazioni = { straordinario: true, reperibilita: true };
          }
        } else {
          commessa.eliminato = false;
          commessa.abilitazioni = { straordinario: true, reperibilita: true }; // Valori di default
        }

        if (item._id.commessaId) {
          result.ordinario.push(commessa);
          commessaIdProcessed.add(item._id.commessaId); // Aggiungi commessaId al set
          // Calcolo dei totali ordinario
          result.tot_ore_ordinarie += commessa.ore_ordinarie;
          result.tot_straordinario_ordinario += commessa.straordinario_ordinario;
          result.tot_straordinario_festivo += commessa.straordinario_festivo;
          result.tot_straordinario_notturno += commessa.straordinario_notturno;
          result.tot_reperibilita += commessa.reperibilita;
          result.tot_ore_ordinarie_notturne += commessa.ore_ordinarie_notturne;
          result.tot_ore_ordinarie_festive += commessa.ore_ordinarie_festive;
        } else {
          result.assenze.push(commessa);
          // Calcolo dei totali assenze
          result.tot_ferie += commessa.ferie;
          result.tot_rol += commessa.rol;
          result.tot_malattia += commessa.malattia;
          result.tot_permessi_giustificati += commessa.permessi_giustificati;
          result.tot_altro += commessa.altro;
        }
      });

      // Se non ci sono commesse specifiche per quel giorno o ne mancano, inizializziamo vuoti
      this.commesseAssegnateAperte.forEach((commessaAperta) => {
        if (!commessaIdProcessed.has(commessaAperta._id)) {
          const commessaVuotaOrdinario = new DayCommessa({
            _id: {
              commessaId: commessaAperta._id,
              commessaDescrizione: commessaAperta.titolo,
              commessaNav: commessaAperta.identificativonav,
              day: day.dateString,
              tipo_assenza: "",
              id_rapportino: "",
            },
            ore_ordinarie: 0,
            straordinario_ordinario: 0,
            straordinario_festivo: 0,
            straordinario_notturno: 0,
            reperibilita: 0,
            ferie: 0,
            rol: 0,
            malattia: 0,
            permessi_giustificati: 0,
            altro: 0,
            ore_ordinarie_notturne: 0,
            ore_ordinarie_festive: 0,
          });

          // Aggiungiamo la chiave "eliminato" anche alle commesse vuote
          commessaVuotaOrdinario.eliminato = commessaAperta.eliminato || false;

          // Imposta le abilitazioni per le commesse vuote
          const risorsaAssociata = commessaAperta.risorseassociate.find(
            (r) => r.idrisorsa === this.providerUserId
          );
          if (risorsaAssociata) {
            commessaVuotaOrdinario.abilitazioni = {
              straordinario:
                risorsaAssociata.straordinario !== undefined
                  ? risorsaAssociata.straordinario
                  : true,
              reperibilita:
                risorsaAssociata.reperibilita !== undefined
                  ? risorsaAssociata.reperibilita
                  : true,
            };
          } else {
            commessaVuotaOrdinario.abilitazioni = {
              straordinario: true,
              reperibilita: true,
            };
          }

          result.ordinario.push(commessaVuotaOrdinario);
        }
      });

      // Ordinare le commesse in modo che quelle eliminate siano sempre in fondo
      result.ordinario.sort((a, b) => a.eliminato - b.eliminato);

      // Inizializziamo un oggetto vuoto nelle assenze se non ci sono dati per quel giorno
      if (result.assenze.length === 0) {
        const commessaVuotaAssenze = new DayCommessa({
          _id: {
            commessaId: "",
            commessaDescrizione: "",
            commessaNav: "",
            day: day.dateString,
            tipo_assenza: "",
            id_rapportino: "",
          },
          ore_ordinarie: 0,
          straordinario_ordinario: 0,
          straordinario_festivo: 0,
          straordinario_notturno: 0,
          reperibilita: 0,
          ferie: 0,
          rol: 0,
          malattia: 0,
          permessi_giustificati: 0,
          altro: 0,
          ore_ordinarie_notturne: 0,
          ore_ordinarie_festive: 0,
        });
        result.assenze.push(commessaVuotaAssenze);
      }

      return result;
    },
    getResourcesById(userId) {
      const result = this.commesseAssegnateAperte.flatMap((commessa) =>
        commessa.risorseassociate.filter((risorsa) => risorsa.idrisorsa === userId)
      );
      return result.length > 0 ? result : null; // Restituisce un array con tutte le corrispondenze, o null se vuoto
    },
    //
    // ** Chiusura / Riapertura / Validazione rapportino
    //
    async askChiudiRapportino() {
      this.visualizzazione = "mensile"; // Forzo visualizzazione mensile

      await this.mixins_sleep(200);

      // Verifico che ci siano le condizioni per chiudere il rapportino
      const canBeClosed = this.isRapportinoCanBeClosed();
      if (!canBeClosed) return false;

      const { start: dateFrom, end: dateTo } = this.composeDateRangeRapportinoAggregato();
      const periodo = this.formatDateRange(dateFrom, dateTo);

      this.$swal
        .fire({
          title: "Chiusura Rapportino",
          html: `<div style="color:#ffff00">Periodo ${periodo}</div><br>Confermi la chiusura per questo periodo?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.chiudiRapportino(dateFrom, dateTo);
          }
        });
    },

    async chiudiRapportino(dateFrom, dateTo) {
      console.log("** chiusura rapportino", dateFrom, dateTo);

      this.isLoading = true;

      try {
        const response = await closeRapportino(
          this.providerUserId,
          dateFrom,
          dateTo,
          "bypassError"
        );

        console.log("** response closeRapportino", response);

        if (response && response.status === 400) {
          this.mixins_showMessage(
            "Chiusura Rapportino",
            "Ci sono assenze non approvate, non è possibile chiudere il rapportino",
            "warning"
          );
          return;
        }

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino chiuso", response);
          this.mixins_showMessage(
            "Chiusura Rapportino",
            "Rapportino chiuso correttamente",
            "info"
          );
          this.fetchAllData();
        }
      } catch (error) {
        this.mixins_showMessage(
          "Chiusura Rapportino",
          "Errore durante la chiusura del rapportino",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async askRiapriRapportino() {
      this.visualizzazione = "mensile"; // Forzo visualizzazione mensile

      await this.mixins_sleep(200);

      const { start: dateFrom, end: dateTo } = this.composeDateRangeRapportinoAggregato();
      const periodo = this.formatDateRange(dateFrom, dateTo);

      this.$swal
        .fire({
          title: "Riapertura Rapportino",
          html: `<div style="color:#ffff00">Periodo ${periodo}</div><br>Confermi la riapertura per questo periodo?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.apriRapportino(dateFrom, dateTo);
          }
        });
    },
    async apriRapportino(dateFrom, dateTo) {
      console.log("** riapertura rapportino", dateFrom, dateTo);

      this.isLoading = true;

      try {
        const response = await reopenRapportino(this.providerUserId, dateFrom, dateTo);

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino aperto", response);
          this.mixins_showMessage(
            "Riapertura Rapportino",
            "Rapportino riaperto correttamente",
            "info"
          );
          this.fetchAllData();
        }
      } catch (error) {
        this.mixins_showMessage(
          "Riapertura Rapportino",
          `Errore durante la riapertura del rapportino: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async askValidaRapportino() {
      this.visualizzazione = "mensile"; // Forzo visualizzazione mensile

      await this.mixins_sleep(200);

      const { start: dateFrom, end: dateTo } = this.composeDateRangeRapportinoAggregato();
      const periodo = this.formatDateRange(dateFrom, dateTo);

      this.$swal
        .fire({
          title: "Validazione Rapportino",
          html: `<div style="color:#ffff00">Periodo ${periodo}</div><br>Confermi la validazione per questo periodo?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.validaRapportino(dateFrom, dateTo);
          }
        });
    },
    async validaRapportino(dateFrom, dateTo) {
      console.log("** validazione rapportino", dateFrom, dateTo);

      this.isLoading = true;

      try {
        const response = await validaRapportino(this.providerUserId, dateFrom, dateTo);

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino validato", response);
          this.mixins_showMessage(
            "Validazione Rapportino",
            "Rapportino validato correttamente",
            "info"
          );
          this.fetchAllData();
        }
      } catch (error) {
        this.mixins_showMessage(
          "Validazione Rapportino",
          `Errore durante la validazione del rapportino: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    getUserRapporto() {
      return this.localRapporto;
    },
  },
  async created() {
    this.isLoading = true;

    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Ricavo il providerUserId
    this.providerUserId = this.userAuthenticated.providerUserId;

    // Ricavo profili e fullname
    this.userProfili = this.userAuthenticated.profili;
    this.userFullName = this.userAuthenticated.fullname;

    // Ottengo tabella festività
    this.tabellaFestivita = await this.mixins_getTabFestivita();

    // Ottengo la scheda rapporto dell'utente loggato
    const rapporto = await this.mixins_getQuickRapporto(this.providerUserId);

    // Ottengo la sede dell'utente loggato
    if (rapporto && rapporto.sede && rapporto.sede.descrizione) {
      this.userCitta = rapporto.sede.descrizione;
    }
    console.log("** rapportino sede user loggato", this.userCitta);

    // Ottengo le ore contrattuali dell'utente loggato
    if (rapporto && rapporto.orecontrattuali) {
      this.userOreContrattuali = rapporto.orecontrattuali;
    }

    this.isLoading = false;

    // Imposta variabili date
    const today = new Date();
    this.selectedGiorno = today.getDate();
    this.selectedMese = this.mesi[today.getMonth()];
    this.selectedAnno = today.getFullYear();
    console.log(
      "** rapportino updateSettimane mese selezionato",
      this.selectedMese,
      this.mesi.indexOf(this.selectedMese) + 1
    );

    // Popola giorni e settimane per la vista giornaliera e settimanale
    this.populateGiorni(today.getFullYear(), today.getMonth() + 1);
    this.updateSettimane(); // Popola le settimane inizialmente

    // Popola anni dall'anno corrente fino al 2010
    this.populateAnni();

    // Imposto la viasualizzazione corrente
    this.visualizzazione = this.getViewsTypeAttivita;

    // Aggiorno l'intestazione iniziale
    this.aggiornaIntestazione();

    // Fetcho lo user che servirà per ottenere le commesse assegnate
    await this.fetchCommesseAperte();

    // Ottengo il rapporto dell'utente (serve per sapere se turnista o no e per la data inizio rapporto)
    await this.fetchRapporto();

    // Fetcho i dati se esiste providerUserId
    await this.fetchAllData();
  },
};
</script>
