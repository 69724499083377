<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <!-- Main Title -->
          <div
            class="h-100 align-items-end bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              {{ getIsFilterFerie ? "FERIE" : "ASSENZE" }}
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i
                    :class="
                      getIsFilterFerie ? 'bi bi-sun-fill' : 'bi bi-heart-pulse-fill'
                    "
                  ></i>
                </span>
                <span>{{ intestazione }}</span>
              </span>
            </h1>
          </div>
        </div>
        <div class="h-100 align-items-end col-sm-2 d-flex justify-content-end p-0 pb-2">
          <button type="button" class="btn button-outline-1 small">
            <span><i class="zmdi zmdi-plus"></i>Crea Nuovo</span>
          </button>
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Assenze -->
        <div class="row m-0 padding-x-4px">
          <div
            class="align-items-center d-flex flex-row flex-1600-column justify-content-between p-0 py-3"
          >
            <!-- Prima colonna uguale a ferie-->
            <div
              v-if="getIsFilterFerie"
              class="container-fluid align-items-center d-flex flex-row justify-content-between px-0"
            >
              <!-- Info rapportino -->
              <div class="d-flex">
                <!-- Target ferie annuali -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">TARGET FERIE<br />ANNUALE</span>
                  <span class="fs-2 text-white">
                    {{
                      dataAssenzeAggregato.infoObj
                        ? mixins_formatNumberWithLocale(
                            dataAssenzeAggregato.infoObj?.target_ferie_annuale
                          )
                        : "0"
                    }}</span
                  >
                </div>
                <!-- Ferie godute -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">FERIE<br />GODUTE</span>
                  <span class="fs-2 text-white">{{
                    dataAssenzeAggregato.infoObj
                      ? mixins_formatNumberWithLocale(
                          dataAssenzeAggregato.infoObj?.ferie_godute
                        )
                      : "0"
                  }}</span>
                </div>
                <!-- Mancanti al target -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">MANCANTI<br />AL TARGET</span>
                  <span class="fs-2 text-white">{{
                    dataAssenzeAggregato.infoObj
                      ? mixins_formatNumberWithLocale(
                          dataAssenzeAggregato.infoObj?.ferie_mancanti_al_target
                        )
                      : "0"
                  }}</span>
                </div>
                <!-- Ferie validate -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">FERIE<br />VALIDATE</span>
                  <span class="fs-2 text-white">{{
                    dataAssenzeAggregato.infoObj
                      ? mixins_formatNumberWithLocale(
                          dataAssenzeAggregato.infoObj?.ferie_validate
                        )
                      : "0"
                  }}</span>
                </div>
                <!-- Richieste in corso -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">RICHIESTE<br />IN CORSO</span>
                  <span class="fs-2 text-white">{{
                    dataAssenzeAggregato.infoObj
                      ? mixins_formatNumberWithLocale(
                          dataAssenzeAggregato.infoObj?.ferie_richieste_in_corso
                        )
                      : "0"
                  }}</span>
                </div>
              </div>
            </div>
            <!-- Prima colonna diverso da ferie (rol, malattia, permessi giustificati)-->
            <div
              v-if="!getIsFilterFerie"
              class="container-fluid align-items-center d-flex flex-row justify-content-between px-0"
            >
              <!-- Info Assenze -->
              <div class="d-flex">
                <!-- Ore totali di assenza -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">ORE TOTALI<br />DI ASSENZA</span>
                  <span class="fs-2 text-white">
                    {{
                      dataAssenzeAggregato.infoObj
                        ? mixins_formatNumberWithLocale(
                            dataAssenzeAggregato.infoObj?.ore_totali_assenze,
                            1
                          )
                        : "0"
                    }}
                  </span>
                </div>
                <!-- Di cui malattia -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">DI CUI<br />MALATTIVA</span>
                  <span class="fs-2 text-white">
                    {{
                      dataAssenzeAggregato.infoObj
                        ? mixins_formatNumberWithLocale(
                            dataAssenzeAggregato.infoObj?.ore_malattia,
                            1
                          )
                        : "0"
                    }}
                  </span>
                </div>
                <!-- Di cui ROL -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">DI CUI<br />ROL</span>
                  <span class="fs-2 text-white">
                    {{
                      dataAssenzeAggregato.infoObj
                        ? mixins_formatNumberWithLocale(
                            dataAssenzeAggregato.infoObj?.ore_rol,
                            1
                          )
                        : "0"
                    }}
                  </span>
                </div>
                <!-- Di cui permessi -->
                <div
                  class="form-label d-flex align-items-center justify-content-start pe-3"
                >
                  <span class="pe-2 ws-nowrap">DI CUI<br />PERMESSI</span>
                  <span class="fs-2 text-white">
                    {{
                      dataAssenzeAggregato.infoObj
                        ? mixins_formatNumberWithLocale(
                            dataAssenzeAggregato.infoObj?.ore_permessi,
                            1
                          )
                        : "0"
                    }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Seconda colonna -->
            <div
              class="container-fluid align-items-center d-flex flex-row justify-content-between px-0 py-2"
            >
              <!-- Selezione periodo -->

              <div class="d-flex me-0 me-lg-2">
                <!-- Settimana, visibile solo in vista settimanale -->
                <div class="dropdown me-2" v-if="visualizzazione === 'settimanale'">
                  <button
                    class="btn button-outline-1 small dropdown-toggle"
                    type="button"
                    id="dropdown_settimana"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <!-- Mostra solo il campo 'display' dell'oggetto 'selectedSettimana' -->
                    <span>{{ selectedSettimana.display }}</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li v-for="settimana in settimane" :key="settimana.start">
                      <button
                        class="dropdown-item"
                        type="button"
                        @click="setSettimana(settimana)"
                      >
                        {{ settimana.display }}
                      </button>
                    </li>
                  </ul>
                </div>

                <!-- Giorno, visibile solo in vista giornaliera -->
                <div class="dropdown me-2" v-if="visualizzazione === 'giornaliera'">
                  <button
                    class="btn button-outline-1 small dropdown-toggle"
                    type="button"
                    id="dropdown_giorno"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{{ selectedGiorno }}</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li v-for="giorno in giorni" :key="giorno">
                      <button
                        class="dropdown-item"
                        type="button"
                        @click="setGiorno(giorno)"
                      >
                        {{ giorno }}
                      </button>
                    </li>
                  </ul>
                </div>

                <!-- Mese, visibile in vista giornaliera e mensile -->
                <div
                  class="dropdown me-2"
                  v-if="['giornaliera', 'mensile'].includes(visualizzazione)"
                >
                  <button
                    class="btn button-outline-1 small dropdown-toggle"
                    type="button"
                    id="dropdown_mese"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{{ selectedMese }}</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li v-for="mese in mesi" :key="mese">
                      <button class="dropdown-item" type="button" @click="setMese(mese)">
                        {{ mese }}
                      </button>
                    </li>
                  </ul>
                </div>

                <!-- Anno, visibile in tutte le viste tranne la settimanale -->
                <div class="dropdown me-2" v-if="visualizzazione !== 'settimanale'">
                  <button
                    class="btn button-outline-1 small dropdown-toggle"
                    type="button"
                    id="dropdown_anno"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{{ selectedAnno }}</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li v-for="anno in anni" :key="anno">
                      <button class="dropdown-item" type="button" @click="setAnno(anno)">
                        {{ anno }}
                      </button>
                    </li>
                  </ul>
                </div>

                <!-- Bottone Vai, visibile in tutte le viste -->
                <button
                  id="but_vai"
                  ref="but_vai"
                  type="button"
                  class="btn button-outline-1 small"
                  @click="onVai"
                >
                  <i class="zmdi zmdi-long-arrow-return zmdi-hc-lg"></i> VAI
                </button>
              </div>

              <div class="w-100 align-items-center d-flex justify-content-end">
                <button
                  type="button"
                  class="btn button-outline-1 small"
                  @click="openAddRichiestaAssenza"
                >
                  <i class="zmdi zmdi-plus zmdi-hc-lg"></i> Richiesta
                  {{ getIsFilterFerie ? "Ferie" : "Assenze" }}
                </button>
                <!-- Export -->
                <div class="d-none d-flex dropdown">
                  <button
                    class="btn button-outline-1 small dropdown-toggle"
                    type="button"
                    id="dropdown_esporta"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span> <i class="me-2 fa-solid fa-file-export"></i>Esporta</span>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdown_esporta"
                  >
                    <li>
                      <button class="dropdown-item" type="button">
                        {{ getIsFilterFerie ? "FERIE" : "ASSENZE" }} in Excel
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr class="sepGray" />

          <!-- No data -->
          <div v-if="!hasData" class="my-3 p-0">
            <h5>Nessun dato da visualizzare</h5>
          </div>

          <!-- Table Assenze -->
          <div v-if="hasData" class="container-fluid m-0 p-0 pt-4">
            <h4 class="color-mainblue">
              Riepilogo {{ getIsFilterFerie ? "Ferie" : "Assenze" }}
            </h4>
          </div>
          <div v-if="hasData" class="container-fluid m-0 p-0">
            <div class="row m-0 mb-3">
              <div class="scroll-x-container p-0">
                <table class="table-bordered table-responsive-md table-setup mb-2">
                  <thead class="table-header-font">
                    <tr class="td-center">
                      <th style="min-width: 140px !important">TIPO</th>
                      <th style="width: 125px !important; min-width: 125px !important">
                        DAL
                      </th>
                      <th style="width: 125px !important; min-width: 125px !important">
                        AL
                      </th>
                      <th
                        v-if="!getIsFilterFerie"
                        style="width: 80px !important; min-width: 80px !important"
                      >
                        ORE<br />GIORNALIERE
                      </th>
                      <th style="width: 80px !important; min-width: 80px !important">
                        GIORNI
                      </th>
                      <th
                        v-if="!getIsFilterFerie"
                        style="width: 160px !important; min-width: 160px !important"
                      >
                        CODICE PUC
                      </th>
                      <th style="width: 380px !important; min-width: 380px !important">
                        NOTE
                      </th>
                      <th style="width: 60px !important; min-width: 60px !important">
                        ALLEGATO
                      </th>
                      <th style="width: 100px !important; min-width: 100px !important">
                        STATO
                      </th>
                      <th style="min-width: 100px !important; width: 100px !important">
                        AZIONI
                      </th>
                    </tr>
                  </thead>
                  <!-- Table Records -->
                  <tbody class="table-body-font td-vertical-center">
                    <tr
                      v-for="(assenza, index) in dataAssenzeAggregatoSorted"
                      :key="index"
                    >
                      <td>
                        <ul
                          class="list-tags"
                          style="--type-display: flex; --justify: start"
                        >
                          <li>
                            <span
                              class="badge-colored whitespace gray1"
                              :title="assenza.dettaglioassenza_descrizione"
                              :alt="assenza.dettaglioassenza_descrizione"
                              ><span style="font-size: 15px !important">{{
                                assenza.dettaglioassenza_descrizione
                              }}</span></span
                            >
                          </li>
                        </ul>
                      </td>
                      <td class="td-center">
                        {{
                          assenza.datainizio
                            ? mixins_getLocalDate(assenza.datainizio)
                            : "-"
                        }}
                      </td>
                      <td class="td-center">
                        {{
                          assenza.datafine ? mixins_getLocalDate(assenza.datafine) : "-"
                        }}
                      </td>
                      <td
                        v-if="!getIsFilterFerie"
                        class="td-center"
                        :class="{
                          'zero-value': isZeroValue(
                            mixins_formatNumberWithLocale(assenza.oregiornaliere, 1)
                          ),
                        }"
                      >
                        {{ mixins_formatNumberWithLocale(assenza.oregiornaliere, 1) }}
                      </td>
                      <td
                        class="td-center"
                        :class="{
                          'zero-value': isZeroValue(assenza.giorni),
                        }"
                      >
                        {{ mixins_formatNumberWithLocale(assenza.giorni) }}
                      </td>
                      <td v-if="!getIsFilterFerie" class="td-center">
                        {{ assenza.puc ? assenza.puc : "-" }}
                      </td>
                      <td>
                        {{ assenza.note ? assenza.note : "" }}
                      </td>
                      <td class="td-center">
                        <button
                          v-if="assenza.documentoallegato != ''"
                          type="button"
                          class="btn icon-button"
                          alt="VISUALIZZA ALLEGATO"
                          title="VISUALIZZA ALLEGATO"
                          @click="viewDocumento(assenza.documentoallegato)"
                        >
                          <i class="bi bi-eye-fill"></i>
                        </button>
                      </td>
                      <td class="td-center">
                        <ul
                          class="list-tags"
                          style="--type-display: flex; --justify: start"
                        >
                          <li>
                            <span
                              class="badge-colored"
                              :class="getStatusParameters(assenza.status).class"
                              :title="getStatusParameters(assenza.status).status"
                              :alt="getStatusParameters(assenza.status).status"
                              >{{ getStatusParameters(assenza.status).status }}</span
                            >
                          </li>
                        </ul>
                      </td>
                      <!-- Actions -->
                      <td class="td-actions td-center">
                        <button
                          type="button"
                          class="btn icon-button"
                          alt="MODIFICA"
                          title="MODIFICA"
                          @click="editRichiestaAssenza(assenza)"
                          :disabled="isApprovedRejected(assenza.status)"
                        >
                          <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                        </button>
                        <button
                          type="button"
                          class="btn icon-button delete"
                          alt="ELIMINA"
                          title="ELIMINA"
                          @click="askRemoveAssenza(assenza)"
                          :disabled="isApprovedRejected(assenza.status)"
                        >
                          <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditAssenze
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="getDialogTitle"
    :dataItem="selectedAssenza"
    @closeModal="closeModal"
    @saveData="saveAssenza"
    :isEditMode="getIsEditMode"
    :userData="getUserData"
    :isFilterFerie="getIsFilterFerie"
  />
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import DialogAddEditAssenze from "@/components/dialogs/DialogAddEditAssenze.vue";
import { RichiestaAssenza } from "@/models/RichiestaAssenzaJoiModel";
import {
  createAssenza,
  updateAssenza,
  uploadAllegatoAssenza,
  fetchUserAttivitaAssenzeAggregato,
  deleteAssenza,
} from "@/services/api";

export default {
  mixins: [UtilityMixins],
  components: { Loading, DialogAddEditAssenze },
  data() {
    return {
      isLoading: false, // Gestione del loader per indicare il caricamento
      visualizzazione: "settimanale", // Vista di default, può essere 'giornaliera', 'settimanale', 'mensile', 'annuale'
      selectedGiorno: "", // Giorno selezionato
      selectedMese: "", // Mese selezionato
      selectedAnno: "", // Anno selezionato
      selectedSettimana: { display: "", start: "", end: "" }, // Settimana corrente selezionata
      giorni: [], // Lista dei giorni per il mese selezionato
      settimane: [], // Lista di settimane popolate dinamicamente
      mesi: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      anni: [], // Lista degli anni
      intestazione: "", // Variabile intestazione da aggiornare
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      selectedAssenza: {},
      isEditMode: false,
      isFilterFerie: false,
      // data assenze
      dataAssenzeAggregato: [], // Array assenze aggregato proveniente dal server
      dataAssenzeAggregatoSorted: [],
      providerUserId: null,
      userFullName: "",
      userCitta: null,
      userProfili: [],
      userOreContrattuali: 8,
      userData: null,
      status: ["Pending", "Approved", "Rejected"],
      statusStr: ["In attesa", "Approvata", "Respinta"],
      statusClass: ["pending", "approved", "rejected"],
    };
  },
  computed: {
    ...mapGetters(["getViewsTypeAttivita"]),
    hasData() {
      // Controlla se ci sono assenze e se l'array non è vuoto
      return this.dataAssenzeAggregato.data && this.dataAssenzeAggregato.data.length > 0;
    },
    getUserData() {
      return this.userData;
    },
    getIsFilterFerie() {
      return this.isFilterFerie;
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
  },
  watch: {
    // Watch per il getter di Vuex che osserva il cambio del valore
    getViewsTypeAttivita(newValue) {
      this.visualizzazione = newValue; // Aggiorna la variabile locale

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();

      // fetch api
      this.fetchDataAssenze();
    },
    // Watcher per il mese, anno e giorno selezionati
    selectedGiorno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il giorno
    },
    selectedMese() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il mese
    },
    selectedAnno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia l'anno
    },
  },

  methods: {
    //
    // Gestione impostazione periodo
    //
    // Funzione per impostare il giorno selezionato
    setGiorno(giorno) {
      this.selectedGiorno = giorno;
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per selezionare la settimana
    setSettimana(settimana) {
      this.selectedSettimana = settimana;

      // Verifica se il giorno corrente cade nell'intervallo della settimana selezionata
      const startDate = new Date(settimana.start);
      const endDate = new Date(settimana.end);
      const selectedDate = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );

      // Se il giorno selezionato non è nell'intervallo della settimana, aggiorna giorno e mese
      if (selectedDate < startDate || selectedDate > endDate) {
        this.selectedGiorno = startDate.getDate(); // Imposta il giorno al primo giorno della settimana
        this.selectedMese = this.mesi[startDate.getMonth()]; // Imposta il mese corretto
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare il mese selezionato e ripopolare i giorni
    setMese(mese) {
      this.selectedMese = mese;
      const meseIndex = this.mesi.indexOf(mese) + 1;
      this.populateGiorni(this.selectedAnno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare l'anno selezionato e ripopolare i giorni
    setAnno(anno) {
      this.selectedAnno = anno;
      const meseIndex = this.mesi.indexOf(this.selectedMese) + 1;
      this.populateGiorni(anno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Popola gli anni dinamicamente dall'anno corrente al 2010
    populateAnni() {
      this.anni = this.mixins_getArrayYearsUpToCurrentYear();
    },

    // Popola i giorni del mese selezionato
    populateGiorni(anno, mese) {
      const daysInMonth = new Date(anno, mese, 0).getDate();
      this.giorni = Array.from({ length: daysInMonth }, (v, i) => i + 1);
    },

    // Funzione per ripopolare le settimane in base al giorno, mese e anno selezionati
    updateSettimane() {
      const date = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );
      const currentWeek = this.getStartOfWeek(date);
      this.settimane = this.calculateSurroundingWeeks(currentWeek, 5);
      this.selectedSettimana = this.settimane[5]; // Seleziona la settimana corrente
    },

    getStartOfWeek(date) {
      const day = date.getDay();
      const diff = date.getDate() - (day === 0 ? 6 : day - 1); // Ottieni il lunedì
      return new Date(date.setDate(diff));
    },
    // Calcolo settimane, 5 precedenti e 5 successive
    calculateSurroundingWeeks(startDate, range) {
      const weeks = [];
      const monday = new Date(startDate);

      // 5 settimane precedenti
      for (let i = range; i > 0; i--) {
        const previousMonday = new Date(monday);
        previousMonday.setDate(monday.getDate() - 7 * i);
        const sunday = new Date(previousMonday);
        sunday.setDate(previousMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            previousMonday
          )} - ${this.formatDateForDisplay(sunday)}`,
          start: this.formatDate(previousMonday),
          end: this.formatDate(sunday),
        });
      }

      // Settimana corrente
      const currentMonday = new Date(monday);
      const currentSunday = new Date(currentMonday);
      currentSunday.setDate(currentMonday.getDate() + 6);
      weeks.push({
        display: `${this.formatDateForDisplay(
          currentMonday
        )} - ${this.formatDateForDisplay(currentSunday)}`,
        start: this.formatDate(currentMonday),
        end: this.formatDate(currentSunday),
      });

      // 5 settimane successive
      for (let i = 1; i <= range; i++) {
        const nextMonday = new Date(monday);
        nextMonday.setDate(monday.getDate() + 7 * i);
        const nextSunday = new Date(nextMonday);
        nextSunday.setDate(nextMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            nextMonday
          )} - ${this.formatDateForDisplay(nextSunday)}`,
          start: this.formatDate(nextMonday),
          end: this.formatDate(nextSunday),
        });
      }

      return weeks;
    },

    formatDateForDisplay(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = this.mesi[date.getMonth()].substring(0, 3);
      return `${day} ${month}`;
    },

    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    },

    aggiornaIntestazione() {
      if (this.visualizzazione === "giornaliera") {
        // Formato: "Giornaliero del 4 settembre 2024"
        this.intestazione = `Giornaliero del ${
          this.selectedGiorno
        } ${this.selectedMese.toLowerCase()} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "settimanale") {
        // Formato: "Settimanale - 2 settembre 8 settembre 2024"
        const start = new Date(this.selectedSettimana.start);
        const end = new Date(this.selectedSettimana.end);
        this.intestazione = `Settimanale - ${start.getDate()} ${this.mesi[
          start.getMonth()
        ].toLowerCase()}, ${end.getDate()} ${this.mesi[end.getMonth()].toLowerCase()} ${
          this.selectedAnno
        }`;
      } else if (this.visualizzazione === "mensile") {
        // Formato: "Mensile - Settembre 2024"
        this.intestazione = `Mensile - ${this.selectedMese} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "annuale") {
        // Formato: "Annuale - 2024"
        this.intestazione = `Annuale - ${this.selectedAnno}`;
      }
    },
    async onVai() {
      await this.fetchDataAssenze();
    },
    //
    // Fetch data
    //
    async fetchDataAssenze() {
      if (this.isFilterFerie) {
        // 0 = ferie
        await this.fetchAllData(0, "Y");
      } else {
        // 99 tutti i tipi di assenza
        await this.fetchAllData(99, "Y");
      }
    },
    async fetchAllData(tipoAssenza, currentUser) {
      // 0 = Ferie 1=ROL, 2=Malattia, 3=Permessi Giustificati, 99=Tutte le assenze
      if (!this.providerUserId) return;

      // Eseguo il fetch dei dati delle assenze aggregato
      const { start: dateFrom, end: dateTo } = this.composeDateRangeAssenzeAggregato();
      await this.fetchAssenzeAggregato(dateFrom, dateTo, tipoAssenza, currentUser);
      console.log("** assenze aggregato", this.dataAssenzeAggregato);
    },
    async fetchAssenzeAggregato(dateFrom, dateTo, tipoAssenza, currentUser) {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.dataAssenzeAggregato = [];

      try {
        const response = await fetchUserAttivitaAssenzeAggregato(
          dateFrom,
          dateTo,
          tipoAssenza,
          currentUser
        );
        if (response) {
          // Se non sono ferie (=0), filtriamo l'array aggregato con tipoassenza !== 0
          if (!this.isFilterFerie) {
            const arrayFiltered = response.data.filter((item) => item.tipoassenza !== 0);
            response.data = arrayFiltered;
          }
          this.dataAssenzeAggregato = response;
          console.log("** assenze aggregato response", response);
          this.dataAssenzeAggregatoSorted = this.ordinaPerDataInizioDesc(
            this.dataAssenzeAggregato
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    //
    // Gestione assenze
    //
    composeDateRangeAssenzeAggregato() {
      let result;

      if (this.visualizzazione === "giornaliera") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const giorno = this.selectedGiorno.toString().padStart(2, "0");
        result = {
          start: `${anno}-${mese}-${giorno}`,
          end: `${anno}-${mese}-${giorno}`,
        };
      } else if (this.visualizzazione === "settimanale") {
        result = {
          start: this.selectedSettimana.start,
          end: this.selectedSettimana.end,
        };
      } else if (this.visualizzazione === "mensile") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const firstDay = `${anno}-${mese}-01`;
        const lastDay = `${anno}-${mese}-${new Date(anno, parseInt(mese), 0)
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        result = {
          start: firstDay,
          end: lastDay,
        };
      } else if (this.visualizzazione === "annuale") {
        const anno = this.selectedAnno;
        result = {
          start: `${anno}-01-01`,
          end: `${anno}-12-31`,
        };
      }
      console.log("** assenze composeDateRangeAssenzeAggregato", result);
      return result;
    },
    ordinaPerDataInizioDesc(obj) {
      return obj.data.sort((a, b) => new Date(b.datainizio) - new Date(a.datainizio));
    },
    isZeroValue(value) {
      return value === 0.0 || value === 0 || value === "0,0" || value === "0.0";
    },
    getStatusParameters(status) {
      // status = "Rejected";
      const statusIndex = this.status.indexOf(status);

      if (statusIndex === -1) {
        // Se lo status non esiste nell'array, ritorno un valore di default o null
        return { status: "Sconosciuto", class: "black" };
      }

      return {
        status: this.statusStr[statusIndex],
        class: this.statusClass[statusIndex],
      };
    },
    isApprovedRejected(status) {
      return (
        this.status.includes(status) && (status === "Approved" || status === "Rejected")
      );
    },
    async askRemoveAssenza(assenza) {
      this.$swal
        .fire({
          title: "Cancellazione Assenza",
          html: "Sei sicuro di voler cancellare l'assenza?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeAssenza(assenza);
          }
        });
    },
    async removeAssenza(assenza) {
      console.log("** delete assenza id", assenza._id);
      this.isLoading = true;
      try {
        const apiResponse = await deleteAssenza(assenza._id);
        console.log("** assenze removeAssenza", apiResponse);
        if (apiResponse != undefined) {
          this.mixins_showMessage(
            "Cancellazione Assenza",
            "Assenza cancellata correttamente",
            "info"
          );
          await this.fetchDataAssenze();
        }
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'assenza");
      } finally {
        this.isLoading = false;
      }
    },
    //
    // Gestione dialog
    //
    openAddRichiestaAssenza() {
      this.isEditMode = false;
      this.dialogTitle = "NUOVA RICHIESTA " + (this.isFilterFerie ? "FERIE" : "ASSENZE");
      this.selectedAssenza = new RichiestaAssenza();
      this.isDialogVisible = true;
    },
    editRichiestaAssenza(assenza) {
      this.isEditMode = true;
      this.dialogTitle =
        "MODIFICA RICHIESTA " + (this.isFilterFerie ? "FERIE" : "ASSENZE");
      this.selectedAssenza = new RichiestaAssenza(assenza);
      console.log("** this.selectedAssenza", this.selectedAssenza);
      this.isDialogVisible = true;
    },
    async saveAssenza(dataRecived, fileRecived) {
      console.log(
        "** Dati aggiornati ricevuti da DialogAddEditAssenze:",
        dataRecived,
        fileRecived
      );

      this.closeModal();

      let allegatoUrl = dataRecived.documentoallegato
        ? dataRecived.documentoallegato
        : "";

      // Salvo allegato, se va a buon fine salvo assenza
      const file = fileRecived;
      if (file) {
        this.isLoading = true;
        try {
          const response = await uploadAllegatoAssenza(file);
          if (response && response.url) {
            console.log("** allegato url", response.url);
            allegatoUrl = response.url;
            this.mixins_showMessage(
              "Caricamento Allegato",
              "Documento caricato correttamente",
              "info"
            );
          }
        } catch (error) {
          this.mixins_showMessage(
            "Caricamento Allegato",
            `Errore durante il caricamento del documento allegato: ${error}`,
            "error"
          );
          this.isLoading = false;
          return;
        } finally {
          this.isLoading = false;
        }
      }

      // Aggiorna la url
      dataRecived.documentoallegato = allegatoUrl;

      // Salvo i dati dell'assenza
      this.isLoading = true;
      try {
        if (this.isEditMode) {
          const apiResponse = await updateAssenza(dataRecived, dataRecived._id);
          console.log("** assenze updateAssenza", apiResponse);
          if (apiResponse != undefined) {
            this.mixins_showMessage(
              "Aggiornamento Richiesta Assenza",
              "Dati modificati correttamente",
              "info"
            );
            await this.fetchDataAssenze();
          }
        } else {
          const apiResponse = await createAssenza(dataRecived, "bypassError");
          console.log("** assenze createAssenza", apiResponse);

          if (apiResponse && apiResponse.status === 400) {
            this.mixins_showMessage(
              "Richiesta Assenza",
              "Il rapportino è già stato validato, non è possibile inserire altre assenze",
              "warning"
            );
            return;
          }

          if (apiResponse != undefined) {
            this.mixins_showMessage(
              "Richiesta Assenza",
              "Richiesta inviata correttamente",
              "info"
            );
            await this.fetchDataAssenze();
          }
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    viewDocumento(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.mixins_showMessage("Errore", "Documento non disponibile", "error");
      }
    },
  },
  async created() {
    // Se sono sulla pagina assenze isFilterFerie = false, altrimenti isFilterFerie = true
    if (this.$route.name == "route-attivita-assenza") {
      this.isFilterFerie = false;
    } else {
      this.isFilterFerie = true;
    }

    this.isLoading = true;

    // Ricavo il providerUserId, profili e fullname dell'utente loggato
    const userDataLS = this.mixin_getUserDataLS();
    this.providerUserId = userDataLS.providerUserId;
    this.userProfili = userDataLS.profili;
    this.userFullName = userDataLS.fullname;

    // Ottengo la scheda rapporto dell'utente loggato
    const rapporto = await this.mixins_getQuickRapporto(this.providerUserId);

    // Ottengo la sede dell'utente loggato
    if (rapporto && rapporto.sede && rapporto.sede.descrizione) {
      this.userCitta = rapporto.sede.descrizione;
    }

    console.log("** assenze sede user loggato", this.userCitta);

    // Ottengo le ore contrattuali dell'utente loggato
    if (rapporto && rapporto.orecontrattuali) {
      this.userOreContrattuali = rapporto.orecontrattuali;
    }

    // Compongo l'oggetto user da passare al dialog
    this.userData = {
      providerUserId: this.providerUserId,
      profili: this.userProfili,
      fullname: this.userFullName,
      citta: this.userCitta,
      orecontrattuali: this.userOreContrattuali,
    };

    this.isLoading = false;

    // Imposta variabili date
    const today = new Date();
    this.selectedGiorno = today.getDate();
    this.selectedMese = this.mesi[today.getMonth()];
    this.selectedAnno = today.getFullYear();

    // Popola giorni e settimane per la vista giornaliera e settimanale
    this.populateGiorni(today.getFullYear(), today.getMonth() + 1);
    this.updateSettimane(); // Popola le settimane inizialmente

    // Popola anni dall'anno corrente fino al 2010
    this.populateAnni();

    // Imposto la viasualizzazione corrente
    this.visualizzazione = this.getViewsTypeAttivita;

    // Aggiorno l'intestazione iniziale
    this.aggiornaIntestazione();

    await this.fetchDataAssenze();
  },
};
</script>
