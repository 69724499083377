<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE COMMESSE<span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-building-user fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <!-- Main contents -->
        <div v-if="!mixins_isEmptyObject(localDataAnagrafica) && !errorMessage">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="color-white m-0 p-0">
                <span v-html="localDataAnagrafica.titolo || blank"></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>

          <!-- Navigazione dei tab -->
          <div class="row m-0 padding-x-4px">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <!-- Tab Anagrafica -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentAnagrafica';
                    fetchAnagraficaHandler();
                  "
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex active"
                  data-bs-toggle="tab"
                  >ANAGRAFICA<span
                    class="ms-2 change-data"
                    v-if="isModified.anagrafica"
                  ></span
                ></a>
              </li>
              <!-- Tab Risorse -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentRisorse';
                    fetchRisorseHandler();
                  "
                  href="#contentRisorse"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >RISORSE<span class="ms-2 change-data" v-if="isModified.risorse"></span
                ></a>
              </li>
              <!-- Tab Attività -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentAttivita';
                    fetchAttivitaHandler();
                  "
                  href="#contentAttivita"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >ATTIVITÀ<span
                    class="ms-2 change-data"
                    v-if="isModified.attivita"
                  ></span
                ></a>
              </li>
            </ul>

            <!-- Contenuto dei tab -->
            <div class="tab-content p-0">
              <!-- Content Anagrafica -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentAnagrafica' }"
                id="contentAnagrafica"
              >
                <TabCommessaAnagrafica
                  ref="commessaAnagrafica"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataAnagraficaUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:commessaDataAnagrafica="localDataAnagrafica"
                />
              </div>
              <!-- Content Risorse -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentRisorse' }"
                id="contentRisorse"
              >
                <TabCommessaRisorse
                  ref="commessaRisorse"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataRisorseUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:commessaDataRisorse="localDataRisorse"
                />
              </div>
              <!-- Content Attività -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentAttivita' }"
                id="contentAttivita"
              >
                <TabCommessaAttivita ref="commessaAttivita" :isEditMode="isEditMode" />
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                @click="backToList()"
                type="button"
                class="btn button-outline-1 float-start"
              >
                <span><i class="zmdi zmdi-arrow-left"></i>Torna alla lista</span>
              </button>
              <button
                v-if="!shouldHideUpdateButton"
                type="button"
                class="btn button-outline-1 float-end"
                :class="{
                  notSaved:
                    isModified.anagrafica || isModified.risorse || isModified.dotazioni,
                }"
                @click="updateAllData"
                :disabled="isAuthenticatedConsultazione"
              >
                <span
                  ><i class="zmdi zmdi-floppy"></i
                  >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { mapGetters } from "vuex";
import { Commessa } from "@/models/CommessaJoiModel";
import { updateCommessa } from "@/services/api";
import TabCommessaAnagrafica from "@/views/menu_commesse/TabCommessaAnagrafica.vue";
import TabCommessaRisorse from "@/views/menu_commesse/TabCommessaRisorse.vue";
import TabCommessaAttivita from "@/views/menu_commesse/TabCommessaAttivita.vue";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    TabCommessaAnagrafica,
    TabCommessaRisorse,
    TabCommessaAttivita,
  },
  data() {
    return {
      errorMessage: null,
      isEditMode: true,
      isModified: {
        anagrafica: false,
        risorse: false,
      },
      isVerified: {
        anagrafica: false,
        risorse: false,
      },
      isDataFetched: {
        anagrafica: false,
        risorse: false,
      },
      headerTitle: "",
      activeTab: "contentAnagrafica",
      paramsId: this.$route.params.id || null,
      // gestione tab data
      localDataAnagrafica: new Commessa(),
      localDataRisorse: new Commessa(),
      // localDataAttivita: new Attivita(),
      blank: `&nbsp;`,
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageCommesse"]),
    shouldHideUpdateButton() {
      // Verifica se il tab attivo è ATTIVITÀ
      return this.activeTab === "contentAttivita";
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
    isMe() {
      return this.userAuthenticated?.providerUserId == this.paramsId;
    },
  },
  methods: {
    //
    // ** Fetch Data
    //
    fetchAnagraficaHandler() {
      if (this.isModified.anagrafica || this.isDataFetched.anagrafica) {
        console.log(
          "** Anagrafica: modifiche rilevate o dati già caricati, fetchData non eseguito."
        );
        return;
      }
      if (
        this.$refs.commessaAnagrafica &&
        this.$refs.commessaAnagrafica.fetchDataAndUpdateFields
      ) {
        this.$refs.commessaAnagrafica.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.commessaAnagrafica.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchRisorseHandler() {
      if (this.isModified.risorse || this.isDataFetched.risorse) {
        console.log(
          "** Risorse: modifiche rilevate o dati già caricati, fetchData non eseguito."
        );
        return;
      }
      if (
        this.$refs.commessaRisorse &&
        this.$refs.commessaRisorse.fetchDataAndUpdateFields
      ) {
        this.$refs.commessaRisorse.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.commessaRisorse.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchAttivitaHandler() {
      if (
        this.$refs.commessaAttivita &&
        this.$refs.commessaAttivita.fetchDataAndUpdateFields
      ) {
        this.$refs.commessaAttivita.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.commessaAttivita.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    onFetchData(section) {
      this.isDataFetched[section] = true;
      console.log(`** Dati sezione ${section} recuperati dal server`);
    },
    //
    // ** Gestione aggiornamenti e validazioni
    //
    //
    // Anagrafica
    //
    handleDataAnagraficaUpdate(updatedData) {
      this.localDataAnagrafica = new Commessa(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Anagrafica:",
        this.localDataAnagrafica
      );
    },
    //
    // Risorse
    //
    handleDataRisorseUpdate(updatedData) {
      this.localDataRisorse = new Commessa(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Risorse:",
        this.localDataRisorse
      );
    },

    //
    // Handle per sezioni modificate e verificate
    //
    handleSectionModified(section) {
      this.isModified[section] = true;
      console.log(`** Sezione ${section} modificata`);
    },
    handleSectionVerified(section, value) {
      this.isVerified[section] = value;
      console.log(`** Sezione ${section} verificata`, value);
    },
    //
    // ** Aggiornamento Dati
    //
    async updateAllData() {
      // Array per tracciare le sezioni aggiornate e quelle con errori
      const isUpdated = [];
      const hasErrors = [];

      // Definisco le sezioni e le rispettive funzioni di aggiornamento
      const sections = [
        { label: "ANAGRAFICA", name: "anagrafica", updateFn: this.updateDataAnagrafica },
        { label: "RISORSE", name: "risorse", updateFn: this.updateDataRisorse },
      ];

      // Ciclo attraverso ogni sezione e chiamo la funzione di aggiornamento corrispondente
      for (const section of sections) {
        if (section && section.updateFn) {
          const updated = await section.updateFn.call(this);
          if (updated) {
            isUpdated.push(section.name);
            console.log("** is updated", section.name);
          } else if (this.isModified[section.name]) {
            console.log("** is modified", section.name, this.isModified[section.name]);
            hasErrors.push(section.label);
          }
        }
      }

      // Se ci sono stati aggiornamenti
      if (isUpdated.length > 0) {
        this.resetSectionModified(isUpdated);
        this.resetSectionVerified(isUpdated);
        this.resetDataFetched(isUpdated);
        // this.mixins_showMessage(
        //   "Aggiornamento dati",
        //   `Le seguenti sezioni sono state aggiornate correttamente: ${isUpdated.join(
        //     ", "
        //   )}`,
        //   "success"
        // );
        console.log(
          `** Le seguenti sezioni sono state aggiornate correttamente: ${isUpdated.join(
            ", "
          )}`
        );
      }

      // Se ci sono errori, mostro un messaggio specifico
      if (hasErrors.length > 0) {
        let msg;
        if (hasErrors.length == 1) {
          msg = "Ci sono dati non validi nella seguente sezione:";
        } else if (hasErrors.length > 1) {
          msg = "Ci sono dati non validi nelle seguenti sezioni:";
        }
        this.mixins_showMessage("Attenzione", `${msg} ${hasErrors.join(", ")}`, "error");
      }
    },

    async updateDataAnagrafica() {
      let isUpdated = false;
      if (this.isModified.anagrafica && this.isVerified.anagrafica) {
        this.mixins_showLoader(this);
        console.log("** Aggiornamento sezione Anagrafica", this.localDataAnagrafica);
        const data = this.localDataAnagrafica;
        const response = await updateCommessa(this.paramsId, data);
        if (typeof response == "object" && response._id == this.paramsId) {
          this.mixins_showMessage("Anagrafica", "Dati salvati correttamente", "info");
          isUpdated = true;
        }
        this.mixins_hideLoader(this);
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    async updateDataRisorse() {
      let isUpdated = false;
      if (this.isModified.risorse && this.isVerified.risorse) {
        this.mixins_showLoader(this);
        console.log("** Aggiornamento sezione Risorse", this.localDataRisorse);
        const data = this.localDataRisorse;
        const response = await updateCommessa(this.paramsId, data);
        if (typeof response == "object" && response._id == this.paramsId) {
          this.mixins_showMessage("Risorse", "Dati salvati correttamente", "info");
          isUpdated = true;
        }
        this.mixins_hideLoader(this);
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    //
    // ** Altri metodi
    //
    backToList() {
      console.error(this.getCurrentPageCommesse);
      this.$router.push({
        name: "route-commesse",
        query: {
          ...this.$route.query, // Mantiene i filtri attuali
          page: this.getCurrentPageCommesse,
        },
      });
    },
    // resetto le sezioni modificate
    resetSectionModified(sections = []) {
      sections.forEach((section) => {
        this.isModified[section] = false;
      });
    },

    // resetto le sezioni verificate
    resetSectionVerified(sections = []) {
      sections.forEach((section) => {
        this.isVerified[section] = false;
      });
    },

    // resetto i dati fetchati
    resetDataFetched(sections = []) {
      sections.forEach((section) => {
        this.isDataFetched[section] = false;
      });
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return this.isModified.anagrafica || this.isModified.risorse;
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  mounted() {
    console.log("** CommesseEdit montato");
    this.resetSectionModified();
    this.resetSectionVerified();
    if (this.paramsId) {
      // modalità modifica
      this.headerTitle = "Modifica";
    } else {
      // modalità creazione
      this.headerTitle = "Crea Nuovo";
    }
    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
