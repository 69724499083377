<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <sidebar />
        <!-- Main content -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/views/menu_impostazioni/fornitori/FornitoriSidebar.vue";

export default {
  components: { Sidebar },
  data() {
    return {};
  },
  methods: {},
};
</script>
