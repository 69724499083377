<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Routes / Filters -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link
            :to="{ name: 'route-fornitori-add' }"
            exact
            :class="{ active: $route.name === 'route-fornitori-add' }"
            >+ Crea Nuovo</router-link
          >
        </li>

        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <div>
          <li>
            <router-link
              @click.prevent="resetSearch()"
              :to="{ name: 'route-fornitori' }"
              exact
              :class="{ active: $route.name === 'route-fornitori' }"
              >Tutti</router-link
            >
          </li>
        </div>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Sort -->
      <ul class="unstyled nav-parent-item" :class="{ 'element-disabled': isEditing }">
        <li v-for="item in sortOrderList" :key="item.text" @click="setOrder(item.value)">
          <a href="#" :class="{ active: currentSortType === item.value }">{{
            item.text
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sortOrderList from "@/data/sort_order_list.json";

export default {
  components: {},
  data() {
    return {
      sortOrderList,
    };
  },
  computed: {
    ...mapGetters(["getFornitoriSortType", "getSearchFornitori"]),
    currentSortType() {
      return this.getFornitoriSortType;
    },
    isEditing() {
      return (
        this.$route.name === "route-fornitori-edit" ||
        this.$route.name === "route-fornitori-add"
      );
    },
  },
  methods: {
    ...mapActions([
      "setFornitoriSortType",
      "setCurrentPageFornitori",
      "setSearchFornitori",
    ]),
    setOrder(value) {
      this.setFornitoriSortType(value);
      this.setCurrentPageFornitori(1);
    },
    resetSearch() {
      this.setSearchFornitori("");
      this.setCurrentPageFornitori(1);
    },
  },
};
</script>
