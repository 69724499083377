<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#000000"
    color="#149efd"
    loader="bars"
  />
  <!-- Skill Matrix -->
  <div class="d-flex justify-content-center m-0 pt-4 pb-4 row">
    <!-- Details Skill -->
    <div class="col-lg-9 p-0">
      <div class="row mb-1">
        <label
          for="inputCertificazioni"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>CERTIFICAZIONI</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.certificazioni"
            :options="certificazioniOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'CERTIFICAZIONE')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.certificazioni &&
                  selectedLocalModels.certificazioni.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="
                  clearMultitagType('CERTIFICAZIONE', 'certificazioni')
                "
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputCompetenze"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>COMPETENZE</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.competenze"
            :options="competenzeOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'COMPETENZA')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.competenze && selectedLocalModels.competenze.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="clearMultitagType('COMPETENZA', 'competenze')"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputCorsi"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>CORSI</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.corsi"
            :options="corsiOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'CORSI')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="selectedLocalModels.corsi && selectedLocalModels.corsi.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearMultitagType('CORSI', 'corsi')"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputDatabase"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>DATABASE</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.database"
            :options="databaseOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'DATABASE')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="selectedLocalModels.database && selectedLocalModels.database.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearMultitagType('DATABASE', 'database')"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputFrameworkProdotti"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>FRAMEWORK-PRODOTTI</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.frameworkprodotti"
            :options="frameworkprodottiOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'FRAMEWORK')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.frameworkprodotti &&
                  selectedLocalModels.frameworkprodotti.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="
                  clearMultitagType('FRAMEWORK', 'frameworkprodotti')
                "
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputLingue"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>LINGUE</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.lingue"
            :options="lingueOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'LINGUA')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="selectedLocalModels.lingue && selectedLocalModels.lingue.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearMultitagType('LINGUA', 'lingue')"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputLinguaggiDiProgrammazione"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>LINGUAGGI DI PROGRAMMAZIONE</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.linguaggidiprogrammazione"
            :options="linguaggiProgrammazioneOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'LINGUAGGIO_PROGRAMMAZIONE')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.linguaggidiprogrammazione &&
                  selectedLocalModels.linguaggidiprogrammazione.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="
                  clearMultitagType(
                    'LINGUAGGIO_PROGRAMMAZIONE',
                    'linguaggidiprogrammazione'
                  )
                "
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          for="inputSistemiOperativi"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>SISTEMI OPERATIVI</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.sistemioperativi"
            :options="sistemioperativiOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'OS')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.sistemioperativi &&
                  selectedLocalModels.sistemioperativi.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="clearMultitagType('OS', 'sistemioperativi')"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="inputTitoliStudio"
          class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
        >
          <span>TITOLI DI STUDIO</span>
        </label>
        <div class="align-items-center col-lg-9 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.titolidistudio"
            :options="titolidistudioOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onMultitagTypeUpdate($event, 'TITOLO_STUDIO')"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="
                  selectedLocalModels.titolidistudio &&
                  selectedLocalModels.titolidistudio.length
                "
                class="multiselect__clear"
                @mousedown.prevent.stop="
                  clearMultitagType('TITOLO_STUDIO', 'titolidistudio')
                "
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
    </div>
  </div>

  <!-- No data -->
  <div v-if="localSkill.skills.length == 0">
    <h5 class="mt-4">Nessuno skill selezionato</h5>
  </div>

  <!-- Table Skill -->
  <div v-if="localSkill.skills.length" class="container-fluid m-0 p-0 pt-4">
    <div class="row m-0 mb-5">
      <table class="table-bordered table-responsive-md table-setup">
        <thead class="table-header-font">
          <tr class="td-center">
            <th>TIPOLOGIA</th>
            <th>SKILL</th>
            <th width="200">MERCATO DI RIFERIMENTO</th>
            <th width="200">LIVELLO</th>
            <th style="width: 125px !important; min-width: 125px !important">
              DATA ACQUISIZIONE
            </th>
            <th style="width: 125px !important; min-width: 125px !important">
              DATA SCADENZA
            </th>
            <th style="min-width: 100px !important; width: 100px !important">AZIONI</th>
          </tr>
        </thead>
        <!-- Table Records -->
        <tbody class="table-body-font td-vertical-center">
          <tr v-for="(skill, index) in localSkill.skills" :key="index">
            <td>{{ labelTipologia(skill.tipologia) }}</td>
            <td>
              <ul class="list-tags">
                <li>
                  <span
                    class="truncate-ch ch-35 badge-colored orange"
                    :title="skill.tiposkill"
                    :alt="skill.tiposkill"
                    >{{ skill.tiposkill }}</span
                  >
                </li>
              </ul>
            </td>
            <td class="td-center">
              <MultiSelect
                :searchable="false"
                v-model="selectedLocalModels.mercatodiriferimento[index]"
                :options="mercatodiriferimentoOptions"
                placeholder="- SELEZIONA -"
                label="label"
                track-by="value"
                :openDirection="'below'"
                :showLabels="false"
                @update:modelValue="
                  onMercatoriferimentoUpdate(
                    index,
                    selectedLocalModels.mercatodiriferimento[index]
                  )
                "
                :maxHeight="210"
              >
                <template #noOptions>
                  <span>L'elenco è vuoto</span>
                </template>
                <template #noResult>
                  <span>Nessun elemento trovato</span>
                </template>
              </MultiSelect>
            </td>
            <td class="td-center">
              <MultiSelect
                :searchable="false"
                v-model="selectedLocalModels.livello[index]"
                :options="livelloOptions"
                placeholder="- SELEZIONA -"
                label="label"
                track-by="value"
                :openDirection="'below'"
                :showLabels="false"
                @update:modelValue="
                  onLivelloUpdate(index, selectedLocalModels.livello[index])
                "
                :maxHeight="210"
              >
                <template #noOptions>
                  <span>L'elenco è vuoto</span>
                </template>
                <template #noResult>
                  <span>Nessun elemento trovato</span>
                </template>
              </MultiSelect>
            </td>
            <td>
              <VueDatePicker
                v-model="selectedLocalModels.dataacquisizione[index]"
                dark
                locale="it"
                format="dd/MM/yyyy"
                cancelText="Annulla"
                selectText="Conferma"
                :enable-time-picker="false"
                @update:modelValue="
                  onDataAcquisizioneUpdate(
                    index,
                    selectedLocalModels.dataacquisizione[index]
                  )
                "
                @cleared="onDataAcquisizioneClear[index]"
                placeholder="AQUISIZIONE"
              >
                <template #input-icon>
                  <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                </template>
                <template #clear-icon="{ clear }">
                  <i
                    @click="clear"
                    class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                  ></i>
                </template>
              </VueDatePicker>
            </td>
            <td>
              <VueDatePicker
                v-model="selectedLocalModels.datascadenza[index]"
                dark
                locale="it"
                format="dd/MM/yyyy"
                cancelText="Annulla"
                selectText="Conferma"
                :enable-time-picker="false"
                @update:modelValue="
                  onDataScadenzaUpdate(index, selectedLocalModels.datascadenza[index])
                "
                @cleared="onDataScadenzaClear[index]"
                placeholder="SCADENZA"
              >
                <template #input-icon>
                  <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                </template>
                <template #clear-icon="{ clear }">
                  <i
                    @click="clear"
                    class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                  ></i>
                </template>
              </VueDatePicker>
            </td>
            <td class="td-actions td-center">
              <button
                alt="AGGIUNGI ALLEGATO"
                title="AGGIUNGI ALLEGATO"
                @click="triggerFileInput(index)"
              >
                <i class="zmdi zmdi-attachment zmdi-hc-lg"></i>
              </button>
              <input
                type="file"
                ref="fileInput"
                :accept="acceptedFileTypes"
                @change="handleFileUpload($event, index)"
                class="d-none"
              />
              <button
                class="delete"
                alt="ELIMINA"
                title="ELIMINA"
                @click="removeSkill(index, skill.tipologia)"
              >
                <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Errors model container -->
  <div class="d-flex justify-content-center m-0 pb-4 row">
    <div class="col-lg-7 p-0">
      <!-- Errors model -->
      <div v-if="errorsModel" class="mb-4">
        <div
          v-for="(errorModel, key) in errorsModel"
          :key="key"
          class="text-danger small mt-1"
        >
          {{ errorModel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SkillSet,
  SkillSetSchema,
  SkillSchema,
  Skill,
  Documento,
  DocumentoSet,
} from "@/models/TeamJoiModel";
import {
  fetchUserSkill,
  uploadFileDocument,
  fetchUserDocumento,
  updateUserDocumento,
} from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import labelsSkillsType from "@/data/skill_type_normalized.json";
import { formatISO, parseISO } from "date-fns";
import "date-fns/locale/it";

export default {
  mixins: [UtilityMixins],
  components: {
    Loading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    userDataSkill: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:userDataSkill",
  ],
  data() {
    return {
      localSkill: new SkillSet(), // Creo un oggetto per gestire i dati locali
      localDocumento: new DocumentoSet(), // Creo un oggetto per gestire i documenti locali
      initialSkill: null, // Memorizzo lo stato iniziale per il confronto
      isLoading: false, // Gestione del loader per indicare il caricamento
      isShowingSkill: true, // Gestione della visualizzazione skill matrix o tabella
      errorsModel: null, // Oggetto per memorizzare gli errori di validazione
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        mercatodiriferimento: [],
        livello: [],
        dataacquisizione: [],
        datascadenza: [],
        linguaggidiprogrammazione: [],
        certificazioni: [],
        competenze: [],
        corsi: [],
        database: [],
        frameworkprodotti: [],
        lingue: [],
        sistemioperativi: [],
        titolidistudio: [],
      },
      skillsData: [], // Array di tutti gli skills
      mercatodiriferimentoOptions: [], // Array delle opzioni per il mercato di riferimento
      livelloOptions: [], // Array delle opzioni per il livello
      certificazioniOptions: [], // Array delle opzioni per le certificazioni
      competenzeOptions: [], // Array delle opzioni per le competenze
      corsiOptions: [], // Array delle opzioni per le competenze
      databaseOptions: [], // Array delle opzioni per i database
      frameworkprodottiOptions: [], // Array delle opzioni per i framework-prodotti
      lingueOptions: [], // Array delle opzioni per le lingue
      linguaggiProgrammazioneOptions: [], // Array delle opzioni per i linguaggi di programmazione
      sistemioperativiOptions: [], // Array delle opzioni per i sistemi operativi
      titolidistudioOptions: [], // Array delle opzioni per il titolo di studio
      labelsSkillsType, // Array della descrizione degli skills normalizzato
      acceptedFileTypes: ".pdf,.txt,image/*", // accetta PDF, TXT, e immagini
      acceptedTypes: [
        // MIME types dei file accettati
        "application/pdf",
        "text/plain",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/webp",
      ],
    };
  },
  computed: {},
  watch: {
    // Osservo il cambiamento di userDataSkill per aggiornare i dati locali
    userDataSkill: {
      immediate: true, // Eseguo l'handler al montaggio
      handler(newVal) {
        // Aggiorno localSkill e i modelli locali al cambio dei dati
        this.localSkill = new SkillSet(newVal);
        this.initialSkill = JSON.parse(JSON.stringify(this.localSkill));
      },
    },
  },
  methods: {
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchUserSkill(this.$route.params.id);
        if (response) {
          if (this.mixins_isEmptyObject(response)) {
            console.error("L'elemento non esiste");
            // this.$parent.errorMessage = "L'elemento non esiste";
            return;
          }

          // Aggiorno i dati locali con la risposta
          this.localSkill = new SkillSet(response);
          this.initialSkill = JSON.parse(JSON.stringify(this.localSkill));

          // Ordino l'array localSkill.skills per tipologia
          this.sortSkillsByTipologia();

          console.log("** dati localSkill", this.localSkill);
          console.log("** dati initialSkill", this.initialSkill);

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:userDataSkill", this.localSkill);
          this.$emit("fetch-data", "skill");
          this.$parent.errorMessage = "";
        } else {
          console.error("L'elemento non esiste");
          // this.$parent.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.$parent.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchUserDocumenti() {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      try {
        const response = await fetchUserDocumento(this.$route.params.id);
        if (response) {
          // Aggiorno i dati locali dei documenti
          this.localDocumento = new DocumentoSet(response);
          console.log("** dati localDocumento", this.localDocumento);
        } else {
          console.error("L'elemento non esiste");
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati dello skill
        await this.fetchData();

        this.$nextTick(() => {
          this.isLoading = true;
        });

        // Carico tutte le tabelle
        await this.getAllSkillTables();

        // Sincronizzo i modelli locali
        this.updateLocalModels();

        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    //
    // Gestione aggiornamenti
    //
    updateParent() {
      console.warn("** localSkill prima di validarlo", this.localSkill);

      if (JSON.stringify(this.localSkill) !== JSON.stringify(this.initialSkill)) {
        this.markAsModified("skill");

        if (this.validateImmediate()) {
          this.$emit("update:userDataSkill", this.localSkill);
          this.$emit("data-updated", this.localSkill);
        } else {
          console.error("Errore nella validazione dei dati.");
        }
      }
    },
    //
    // ** Gestione Validazioni **
    //
    prepareDataForValidation_manual(skillSet) {
      // Non avendo disponibile la funzionalità completa di Joi con Joi describe, devo definire manualmente le chiavi
      // Definisco manualmente le chiavi dello schema
      const schemaKeys = [
        "id",
        "mercatodiriferimento",
        "tipologia",
        "tiposkill",
        "livello",
        "livellonumber",
        "dataacquisizione",
        "datascadenza",
      ];

      const preparedSkills = skillSet.skills.map((skill) => {
        const preparedSkill = { ...skill };

        schemaKeys.forEach((key) => {
          if (preparedSkill[key] === undefined || preparedSkill[key] === null) {
            if (
              [
                "id",
                "mercatodiriferimento",
                "tipologia",
                "tiposkill",
                "livello",
              ].includes(key)
            ) {
              preparedSkill[key] = ""; // Assicuro che sia una stringa vuota
            } else if (["dataacquisizione", "datascadenza"].includes(key)) {
              preparedSkill[key] = null; // Assicuro che sia null per date se non presente
            } else if (key === "livellonumber") {
              preparedSkill[key] = null; // Se è un numero ma non presente, imposto a null
            }
          }
        });

        return preparedSkill;
      });

      return {
        skills: preparedSkills,
      };
    },
    prepareDataForValidation(skillSet) {
      // Verifica che skillSet.skills sia un array
      if (!Array.isArray(skillSet.skills)) {
        console.error("skillSet.skills non è un array valido:", skillSet.skills);
        return { skills: [] }; // Ritorna un array vuoto se il dato non è valido
      }

      const preparedSkills = skillSet.skills.map((skill) => {
        const preparedSkill = { ...skill };
        const schemaKeys = Object.keys(SkillSchema.describe().keys);

        schemaKeys.forEach((key) => {
          if (preparedSkill[key] === undefined || preparedSkill[key] === null) {
            const fieldType = SkillSchema.describe().keys[key].type;

            switch (fieldType) {
              case "string":
                preparedSkill[key] = "";
                break;
              case "date":
                preparedSkill[key] = null;
                break;
              case "number":
                preparedSkill[key] = null;
                break;
              default:
                preparedSkill[key] = null;
            }
          }
        });

        return preparedSkill;
      });

      return {
        skills: preparedSkills,
      };
    },

    validateImmediate() {
      const preparedData = this.prepareDataForValidation(this.localSkill);

      console.log("** validateImmediate -> preparedData", preparedData);

      const result = SkillSetSchema.validate(preparedData, {
        abortEarly: false,
      });

      if (result.error) {
        const uniqueErrors = new Set(); // Utilizzo di un Set per memorizzare messaggi unici

        this.errorsModel = result.error.details.reduce((acc, curr) => {
          const message = curr.message;

          // Aggiunge il messaggio solo se non è già presente
          if (!uniqueErrors.has(message)) {
            const fieldName = curr.path.join("_");
            acc[fieldName] = message;
            uniqueErrors.add(message); // Aggiunge il messaggio al Set per evitare duplicazioni
          }

          return acc;
        }, {});

        this.markAsVerified("skill", false);
        return false;
      } else {
        this.errorsModel = null;
        this.markAsVerified("skill", true);
        return true;
      }
    },
    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    //
    // ** Gestione delle date **
    //
    onDataAcquisizioneUpdate(index, newDate) {
      // Aggiorna dataacquisizione nel localSkill
      this.localSkill.skills[index].dataacquisizione = newDate
        ? formatISO(newDate)
        : null;

      // Sincronizza con il modello locale
      this.selectedLocalModels.dataacquisizione[index] = newDate
        ? formatISO(newDate)
        : null;

      this.validateImmediate();
      this.updateParent();
    },
    onDataAcquisizioneClear(index) {
      // Imposta dataacquisizione su null
      this.localSkill.skills[index].dataacquisizione = null;

      // Resetta il modello locale
      this.selectedLocalModels.dataacquisizione[index] = null;

      this.validateImmediate();
      this.updateParent();
    },
    onDataScadenzaUpdate(index, newDate) {
      // Aggiorna datascadenza nel localSkill
      this.localSkill.skills[index].datascadenza = newDate ? formatISO(newDate) : null;

      // Sincronizza con il modello locale
      this.selectedLocalModels.datascadenza[index] = newDate ? formatISO(newDate) : null;

      this.validateImmediate();
      this.updateParent();
    },
    onDataScadenzaClear(index) {
      // Imposta datascadenza su null
      this.localSkill.skills[index].datascadenza = null;

      // Resetta il modello locale
      this.selectedLocalModels.datascadenza[index] = null;

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Gestione Skill Matrix
    //
    onMultitagTypeUpdate(selectedValues, skillType) {
      const selectedSkills = selectedValues.map((selected) => selected.label);

      // Trovo le skills attuali di tipo skillType
      const currentSkills = this.localSkill.skills.filter(
        (skill) => skill.tipologia === skillType
      );

      // Aggiorno skills esistenti e aggiungere nuovi skill
      selectedSkills.forEach((skillLabel) => {
        const existingSkill = currentSkills.find(
          (skill) => skill.tiposkill === skillLabel
        );

        if (!existingSkill) {
          // Aggiungo nuovi skill che non esistono attualmente
          this.localSkill.skills.push(
            new Skill({
              id: "", // L'id viene generato dal server
              tipologia: skillType,
              tiposkill: skillLabel,
              mercatodiriferimento: "",
              livello: "",
              livellonumber: null,
              dataacquisizione: null,
              datascadenza: null,
            })
          );
        }
      });

      // Rimuovo skills che non sono più selezionati
      this.localSkill.skills = this.localSkill.skills.filter(
        (skill) =>
          skill.tipologia !== skillType || selectedSkills.includes(skill.tiposkill)
      );

      // Ordino l'array localSkill.skills per tipologia
      this.sortSkillsByTipologia();

      // Aggiorno i modelli locali
      this.updateLocalModels();

      this.updateParent();
      this.validateImmediate();
    },
    clearMultitagType(skillType, skillTag) {
      this.selectedLocalModels[skillTag] = [];
      this.onMultitagTypeUpdate([], skillType);
    },
    removeSkill(index, skillType) {
      // Ottiengo lo skill da rimuovere
      const skillToRemove = this.localSkill.skills[index];

      // Rimuovo lo skill dall'array localSkill.skills
      this.localSkill.skills.splice(index, 1);

      // Trovo il corrispondente array in selectedLocalModels
      const modelKey = this.getModelKeyBySkillType(skillType);

      // Rimuovo il tag associato nella MultiSelect
      this.selectedLocalModels[modelKey] = this.selectedLocalModels[modelKey].filter(
        (tag) => tag.label !== skillToRemove.tiposkill
      );

      // Ordino l'array localSkill.skills per tipologia
      this.sortSkillsByTipologia();

      // Aggiorno lo stato locale e valido nuovamente
      this.updateParent();
      this.validateImmediate();
    },
    sortSkillsByTipologia() {
      // Ordino l'array localSkill.skills in base al campo 'tipologia'
      this.localSkill.skills.sort((a, b) => {
        if (a.tipologia < b.tipologia) return -1;
        if (a.tipologia > b.tipologia) return 1;
        return 0;
      });
    },
    groupSkillsByTipologia() {
      const groupedSkills = this.localSkill.skills.reduce((acc, skill) => {
        if (!acc[skill.tipologia]) {
          acc[skill.tipologia] = [];
        }
        acc[skill.tipologia].push(skill);
        return acc;
      }, {});
      return groupedSkills;
    },
    getModelKeyBySkillType(skillType) {
      const mapping = {
        LINGUAGGIO_PROGRAMMAZIONE: "linguaggidiprogrammazione",
        CERTIFICAZIONE: "certificazioni",
        COMPETENZA: "competenze",
        CORSI: "corsi",
        DATABASE: "database",
        FRAMEWORK: "frameworkprodotti",
        LINGUA: "lingue",
        OS: "sistemioperativi",
        TITOLO_STUDIO: "titolidistudio",
      };

      return mapping[skillType] || null;
    },
    //
    // Gestione multiselect della tabella
    //
    //
    // Mercato di riferimento
    //
    onMercatoriferimentoUpdate(index, selected) {
      if (selected) {
        // Aggiorniamo il valore di mercatodiriferimento nel localSkill
        this.localSkill.skills[index].mercatodiriferimento = selected.value;

        // Aggiorniamo il modello locale per riflettere la selezione corrente
        this.selectedLocalModels.mercatodiriferimento[index] = selected;
      } else {
        // Se l'utente rimuove la selezione, impostiamo su una stringa vuota
        this.localSkill.skills[index].mercatodiriferimento = "";
        this.selectedLocalModels.mercatodiriferimento[index] = null;
      }

      this.updateParent();
      this.validateImmediate();
    },
    //
    // Livello
    //
    onLivelloUpdate(index, selected) {
      if (selected) {
        // Aggiorniamo il valore di livellonumber nel localSkill
        this.localSkill.skills[index].livellonumber = selected.value;
        this.localSkill.skills[index].livello = selected.label;

        // Aggiorniamo il modello locale per riflettere la selezione corrente
        this.selectedLocalModels.livello[index] = selected;
      } else {
        // Se l'utente rimuove la selezione, imposto livellonumber su null
        this.localSkill.skills[index].livellonumber = null;
        this.localSkill.skills[index].livello = "";
        this.selectedLocalModels.livello[index] = null;
      }

      this.updateParent();
      this.validateImmediate();
    },
    //
    // Sincronizzazione modelli locali
    //
    updateModelMercatoriferimento() {
      this.selectedLocalModels.mercatodiriferimento = this.localSkill.skills.map(
        (skill) => {
          if (skill.mercatodiriferimento === "") {
            return null;
          }

          const selectedOption = this.mercatodiriferimentoOptions.find(
            (option) => option.value === skill.mercatodiriferimento
          );

          return selectedOption
            ? { value: selectedOption.value, label: selectedOption.label }
            : { value: skill.mercatodiriferimento, label: skill.mercatodiriferimento };
        }
      );
    },
    updateModelLivello() {
      this.selectedLocalModels.livello = this.localSkill.skills.map((skill) => {
        if (skill.livellonumber === null || skill.livellonumber === undefined) {
          return null;
        }

        const selectedOption = this.livelloOptions.find(
          (option) => option.value === skill.livellonumber
        );

        return selectedOption
          ? { value: selectedOption.value, label: selectedOption.label }
          : { value: skill.livellonumber, label: skill.livello };
      });
    },
    updateModelDataAcquisizione() {
      if (Array.isArray(this.localSkill.skills) && this.localSkill.skills.length > 0) {
        this.selectedLocalModels.dataacquisizione = this.localSkill.skills.map(
          (skill) => {
            return skill.dataacquisizione ? parseISO(skill.dataacquisizione) : null;
          }
        );
      } else {
        this.selectedLocalModels.dataacquisizione = [];
      }
    },
    updateModelDataScadenza() {
      if (Array.isArray(this.localSkill.skills) && this.localSkill.skills.length > 0) {
        this.selectedLocalModels.datascadenza = this.localSkill.skills.map((skill) => {
          return skill.datascadenza ? parseISO(skill.datascadenza) : null;
        });
      } else {
        this.selectedLocalModels.datascadenza = [];
      }
    },
    updateLocalModels() {
      // Aggiorno il modello locale per mercatodiriferimento
      this.updateModelMercatoriferimento();
      // Aggiorno il modello locale per livello e livellonumber
      this.updateModelLivello();
      // Aggiorno il modello locale per dataacquisizione
      this.updateModelDataAcquisizione();
      // Aggiorno il modello locale per datascadenza
      this.updateModelDataScadenza();

      // Popolo selectedLocalModels.linguaggidiprogrammazione
      this.fillMultiselectSkillMatrix();
    },
    fillMultiselectSkillMatrix() {
      const groupedSkills = this.groupSkillsByTipologia();

      // Mappa dei tipi di skill e dei relativi campi nel modello locale
      const skillTypeMapping = {
        CERTIFICAZIONE: "certificazioni",
        COMPETENZA: "competenze",
        CORSI: "corsi",
        DATABASE: "database",
        FRAMEWORK: "frameworkprodotti",
        LINGUA: "lingue",
        LINGUAGGIO_PROGRAMMAZIONE: "linguaggidiprogrammazione",
        OS: "sistemioperativi",
        TITOLO_STUDIO: "titolidistudio",
      };

      // Itera sulla mappa per popolare `selectedLocalModels` in base ai tipi di skill
      Object.keys(skillTypeMapping).forEach((skillType) => {
        const localModelKey = skillTypeMapping[skillType];

        if (Array.isArray(groupedSkills[skillType])) {
          // Se esiste un gruppo di skill per il tipo corrente, mappalo nel modello locale
          this.selectedLocalModels[localModelKey] = groupedSkills[skillType].map(
            (skill) => ({
              value: skill.tiposkill,
              label: skill.tiposkill,
            })
          );
        } else {
          // Inizializza come array vuoto se non ci sono skill di quel tipo
          this.selectedLocalModels[localModelKey] = [];
        }
      });
    },
    //
    // Gestione upload di un allegato
    //
    triggerFileInput(index) {
      // Attiva l'input file per un determinato skill
      this.$refs.fileInput[index].click();
    },
    resetFileInput() {
      // Resetta l'input file per permettere un nuovo upload dello stesso file
      this.$refs.fileInput.value = "";
    },
    async handleFileUpload(event, index) {
      const file = event.target.files[0];

      console.log("** file upload dello skill", this.localSkill.skills[index]);

      if (!file) return;

      // Verifica se il file è del tipo accettato
      if (!this.acceptedTypes.includes(file.type)) {
        this.mixins_showMessage(
          "Tipo di file non supportato",
          "Sono accettati solo PDF, TXT o immagini",
          "warning"
        );
        return;
      }
      if (file) {
        this.loading = true;

        const fileSize = file.size;
        const nuovaDescrizione = this.concatenateStrings(this.localSkill.skills[index]);

        // Carico i documenti
        await this.fetchUserDocumenti();

        try {
          const response = await uploadFileDocument(file);
          if (response && response.url) {
            // Creazione di un nuovo documento
            const nuovoDocumento = new Documento({
              id: "", // sarà generato dal server
              url: response.url,
              descrizione: nuovaDescrizione,
              tipo: "",
              datacaricamento: new Date().toISOString(),
              dimensione: fileSize,
              filetype: file.name.split(".").pop().toUpperCase(),
            });

            // Aggiunta del documento all'array dei documenti
            this.localDocumento.documenti.push(nuovoDocumento);

            // Invio i documenti aggiornati al server
            await this.sendDocumentiAlServer();
          }
        } catch (error) {
          this.mixins_showMessage(
            "Upload",
            "Errore durante il caricamento del documento",
            "error"
          );
        } finally {
          this.resetFileInput(); // Reset dell'input file dopo il caricamento
          this.loading = false;
        }
      }
    },
    async sendDocumentiAlServer() {
      try {
        // Inviare i documenti al server
        await updateUserDocumento(this.$route.params.id, this.localDocumento); // Metodo per salvare i documenti al server
        this.mixins_showMessage(
          "Aggiungi Allegato",
          "Documento caricato correttamente",
          "info"
        );
      } catch (error) {
        console.error("Errore durante l'invio dei documenti al server:", error);
        this.mixins_showMessage(
          "Errore",
          "Errore durante il salvataggio del documento",
          "error"
        );
      }
    },
    concatenateStrings(data) {
      // Mappo il valore di 'tipologia' al suo 'label' corrispondente
      const tipoLabel =
        labelsSkillsType.find((item) => item.value === data.tipologia)?.label ||
        data.tipologia;

      // Concateno le stringhe, ignorando 'mercatodiriferimento' se è vuoto
      const concatenatedString = [
        tipoLabel,
        data.tiposkill,
        data.livello,
        data.mercatodiriferimento,
      ]
        .filter((part) => part) // Filtra le parti vuote
        .join(" | ");

      return concatenatedString;
    },
    //
    // Caricamento tabelle
    //
    async getTabSkillsData() {
      // Tabella di tutti gli skill, i dati saranno raggruppati e filtrati
      return await this.mixins_getTabSkillsData();
    },

    async getTabMercatoDiRiferimento() {
      const array = await this.mixins_getTabMercatoDiRiferimento();

      if (Array.isArray(array)) {
        this.mercatodiriferimentoOptions = array.map((item) => ({
          value: item.descrizione,
          label: item.descrizione,
        }));
      } else {
        console.error("La risposta non è un array valido:", array);
      }

      console.log("** tab mercatodiriferimentoOptions", this.mercatodiriferimentoOptions);
    },
    async getTabSkillsLevelType() {
      const array = await this.mixins_getTabSkillsLevelType();

      if (Array.isArray(array)) {
        this.livelloOptions = array.map((item) => ({
          value: item.livellonumber,
          label: item.livello,
        }));
      } else {
        console.error("La risposta non è un array valido:", array);
      }

      console.log("** tab livelloOptions", this.livelloOptions);
    },

    // 9 Tabelle skills

    async getTabSkillsTypeCertificazione(skillsData) {
      // Tabella 'Certificazioni'
      const specificType = "CERTIFICAZIONE";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.certificazioniOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab certificazioniOptions", this.certificazioniOptions);
    },

    async getTabSkillsTypeCompetenze(skillsData) {
      // Tabella 'Competenze'
      const specificType = "COMPETENZA";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.competenzeOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab competenzeOptions", this.competenzeOptions);
    },

    async getTabSkillsTypeCorsi(skillsData) {
      // Tabella 'Corsi'
      const specificType = "CORSI";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.corsiOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab corsiOptions", this.corsiOptions);
    },

    async getTabSkillsTypeDatabase(skillsData) {
      // Tabella 'Database'
      const specificType = "DATABASE";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.databaseOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab databaseOptions", this.databaseOptions);
    },

    async getTabSkillsTypeFramework(skillsData) {
      // Tabella 'Framework-Prodotti'
      const specificType = "FRAMEWORK";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.frameworkprodottiOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab frameworkprodottiOptions", this.frameworkprodottiOptions);
    },

    async getTabSkillsTypeLingue(skillsData) {
      // Tabella 'Lingue'
      const specificType = "LINGUA";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.lingueOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab lingueOptions", this.lingueOptions);
    },

    async getTabSkillsTypeLinguaggiDiProgrammazione(skillsData) {
      // Tabella 'Linguaggi di Programmazione'
      const specificType = "LINGUAGGIO_PROGRAMMAZIONE";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.linguaggiProgrammazioneOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log(
        "** tab linguaggiProgrammazioneOptions",
        this.linguaggiProgrammazioneOptions
      );
    },

    async getTabSkillsTypeSistemiOperativi(skillsData) {
      // Tabella 'Sistemi operativi'
      const specificType = "OS";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.sistemioperativiOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab sistemioperativiOptions", this.sistemioperativiOptions);
    },

    async getTabSkillsTypeTitoloDiStudio(skillsData) {
      // Tabella 'Titolo di Studio'
      const specificType = "TITOLO_STUDIO";
      const array = await this.mixins_getTabSkillsDataBySpecificType(
        specificType,
        skillsData
      );
      this.titolidistudioOptions = array.map((item) => ({
        value: item.descrizione,
        label: item.descrizione,
      }));
      console.log("** tab titolidistudioOptions", this.titolidistudioOptions);
    },

    async getAllSkillTables() {
      // Carico la tabella degli skill e poi filtro per tipo, tabelle da utilizzare per la gestione di skill matrix

      // Carico la tabella degli skills
      this.skillsData = await this.getTabSkillsData();
      console.log("** tab skillsData", this.skillsData);

      // Carico la tabella mercato di riferimento e livello di skill
      await this.getTabMercatoDiRiferimento();
      await this.getTabSkillsLevelType();

      // Filtro le tabelle per i 9 tipi di skill
      await this.getTabSkillsTypeCertificazione(this.skillsData);
      await this.getTabSkillsTypeCompetenze(this.skillsData);
      await this.getTabSkillsTypeCorsi(this.skillsData);
      await this.getTabSkillsTypeDatabase(this.skillsData);
      await this.getTabSkillsTypeFramework(this.skillsData);
      await this.getTabSkillsTypeLingue(this.skillsData);
      await this.getTabSkillsTypeLinguaggiDiProgrammazione(this.skillsData);
      await this.getTabSkillsTypeSistemiOperativi(this.skillsData);
      await this.getTabSkillsTypeTitoloDiStudio(this.skillsData);
    },
    //
    // Altri metodi
    //
    labelTipologia(value) {
      const founded = this.labelsSkillsType.find((item) => item.value === value);
      if (founded) {
        return founded.label;
      } else {
        return value;
      }
    },
    showMatrixSkill() {
      this.isShowingSkill = true;
    },
  },
};
</script>
