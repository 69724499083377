import Joi from "joi";

class Sede {
    constructor(data = {}) {
        this.id = data.id || "";
        this.sede = data.sede || "";
        this.indirizzo = data.indirizzo || "";
        this.cap = data.cap || "";
        this.citta = data.citta || "";
        this.prov = data.prov || "";
        this.codicefiliale = data.codicefiliale || "";
        this.logo = data.logo || "";
    }
}

class Azienda {
    constructor(data = {}) {
        this._id = data._id || "";
        this.descrizione = data.descrizione || "";
        this.ragionesociale = data.ragionesociale || "";
        this.identificativonav = data.identificativonav || "";
        this.codicepaghe = data.codicepaghe || 0;
        this.codiceazienda = data.codiceazienda || "";
        this.giorniFerie = data.giorniFerie || 0;
        this.logo = data.logo || "";
        this.sedi = (data.sedi || []).map((sede) => new Sede(sede));
    }
}

const SedeSchema = Joi.object({
    id: Joi.string().allow(""),
    sede: Joi.string().required().messages({
        "string.empty": 'Il campo "DESCRIZIONE SEDE" è obbligatorio',
        "any.required": 'Il campo "DESCRIZIONE SEDE" è obbligatorio',
    }),
    indirizzo: Joi.string().required().messages({
        "string.empty": 'Il campo "INDIRIZZO" è obbligatorio',
        "any.required": 'INDIRIZZO" è obbligatorio',
    }),
    cap: Joi.string()
        .pattern(/^\d{5}$/)
        .required()
        .messages({
            "string.pattern.base": 'Il campo "CAP" deve essere composto da 5 cifre',
            "string.empty": 'Il campo "CAP" è obbligatorio',
            "any.required": 'Il campo "CAP" è obbligatorio',
        }),
    prov: Joi.string().length(2).required("").messages({
        "string.length": 'La "PROVINCIA" deve essere di 2 caratteri',
        "string.empty": 'Il campo "PROVINCIA" è obbligatorio',
        "any.required": 'Il campo "PROVINCIA" è obbligatorio',
        "string.base": 'Il campo "PROVINCIA" è obbligatorio',
    }),
    citta: Joi.string().required().messages({
        "string.empty": 'Il campo "CITTÀ" è obbligatorio',
        "any.required": 'Il campo "CITTÀ" è obbligatorio',
        "string.base": 'Il campo "CITTÀ" è obbligatorio',
    }),
    codicefiliale: Joi.string().allow(""),
    logo: Joi.string().allow(""),
});

const AziendaSchema = Joi.object({
    _id: Joi.string().allow(""),
    descrizione: Joi.string().allow(""),
    ragionesociale: Joi.string().required().messages({
        "string.empty": 'Il campo "RAGIONE SOCIALE" è obbligatorio',
        "any.required": 'Il campo "RAGIONE SOCIALE" è obbligatorio',
    }),
    identificativonav: Joi.string().required().messages({
        "string.empty": 'Il campo "IDENTIFICATIVO NAV" è obbligatorio',
        "any.required": 'Il campo "IDENTIFICATIVO NAV" è obbligatorio',
    }),
    codicepaghe: Joi.number().min(0).required().messages({
        "number.base": 'Il campo "CODICE PAGHE" deve essere un numero',
        "number.min": 'Il campo "CODICE PAGHE" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "CODICE PAGHE" è obbligatorio',
    }),
    codiceazienda: Joi.string().required().messages({
        "string.empty": 'Il campo "CODICE AZIENDA" è obbligatorio',
        "any.required": 'Il campo "CODICE AZIENDA" è obbligatorio',
    }),
    giorniFerie: Joi.number().min(0).messages({
        "number.base": 'Il campo "GIORNI DI FERIE" deve essere un numero',
        "number.min": 'Il campo "GIORNI DI FERIE" deve essere maggiore o uguale a 0',
        "any.required": 'Il campo "GIORNI DI FERIE" è obbligatorio',
    }),
    logo: Joi.string().allow(""),
    sedi: Joi.array().items(SedeSchema),
});

export { AziendaSchema, SedeSchema, Azienda, Sede };
