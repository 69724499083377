<template>
  <div
    v-if="
      isAuthenticatedSuperAdmin ||
      isAuthenticatedDTC ||
      isAuthenticatedGestionePersonale ||
      isAuthenticatedConsultazione
    "
    class="col-4 sidebar"
  >
    <nav class="main-left-nav" role="navigation">
      <!-- Routes / Filters -->
      <ul class="unstyled nav-parent-item">
        <li
          v-if="
            isAuthenticatedSuperAdmin ||
            isAuthenticatedDTC ||
            isAuthenticatedGestionePersonale
          "
        >
          <router-link
            :to="{ name: 'route-commesse-add' }"
            exact
            :class="{ active: $route.name === 'route-commesse-add' }"
            >+ Crea Nuova</router-link
          >
        </li>

        <hr
          v-if="
            isAuthenticatedSuperAdmin ||
            isAuthenticatedDTC ||
            isAuthenticatedGestionePersonale
          "
          role="separator"
          aria-orientation="horizontal"
          class="divider"
        />
        <div>
          <li>
            <router-link
              @click.prevent="clearAllAttributesFilters()"
              :to="{ name: 'route-commesse' }"
              exact
              :class="{ active: $route.name === 'route-commesse' }"
              >Tutti</router-link
            >
          </li>
        </div>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Società -->
      <div v-if="tabellaSocieta.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroSocieta"
              style="color: #fff !important"
              >{{
                `Società (${
                  checkedTabellaSocieta.length > 0
                    ? checkedTabellaSocieta.length
                    : "tutte"
                })`
              }}</a
            >
            <div id="menuFiltroSocieta" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="societa in tabellaSocieta"
                  :key="societa._id"
                >
                  <input
                    :id="'filter_' + societa._id"
                    :value="societa._id"
                    type="checkbox"
                    class="btn-check-filter"
                    :checked="checkedTabellaSocieta.includes(societa._id)"
                    @change="toggleCheckbox('societa', societa._id)"
                    :disabled="isEditing"
                  />
                  <label class="btn btn-filter" :for="'filter_' + societa._id">{{
                    societa.ragionesociale
                  }}</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Tipologia -->
      <div v-if="tabellaTipologia.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroTipologie"
              style="color: #fff !important"
              >{{
                `Tipologia (${
                  checkedTabellaTipologia.length > 0
                    ? checkedTabellaTipologia.length
                    : "tutte"
                })`
              }}</a
            >
            <div id="menuFiltroTipologie" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="tipologia in tabellaTipologia"
                  :key="tipologia.id"
                >
                  <input
                    :id="'filter_tipologia' + tipologia.id"
                    :value="tipologia.id"
                    type="checkbox"
                    class="btn-check-filter"
                    :checked="checkedTabellaTipologia.includes(tipologia.id)"
                    @change="toggleCheckbox('tipologia', tipologia.id)"
                    :disabled="isEditing"
                  />
                  <label
                    class="btn btn-filter"
                    :for="'filter_tipologia' + tipologia.id"
                    >{{ tipologia.nome }}</label
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Sort -->
      <ul class="unstyled nav-parent-item" :class="{ 'element-disabled': isEditing }">
        <li v-for="item in sortOrderList" :key="item.text" @click="setOrder(item.value)">
          <a href="#" :class="{ active: currentSortType === item.value }">{{
            item.text
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import sortOrderList from "@/data/sort_order_list.json";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {},
  data() {
    return {
      tabellaSocieta: [],
      tabellaTipologia: [],
      checkedTabellaSocieta: [],
      checkedTabellaTipologia: [],
      sortOrderList,
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters([
      "getCommesseSortType",
      "getFilterCommesseSocieta",
      "getFilterCommesseTipologia",
    ]),
    currentSortType() {
      return this.getCommesseSortType;
    },
    isEditing() {
      return (
        this.$route.name === "route-commesse-edit" ||
        this.$route.name === "route-commesse-add"
      );
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        // Sincronizza i filtri dei checkbox con i nuovi parametri della query
        this.syncFiltersWithQueryParams(newQuery);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "setCommesseSortType",
      "setCurrentPageCommesse",
      "setFilterCommesseSocieta",
      "setFilterCommesseTipologia",
      "setSearchCommesse",
    ]),
    //
    // ** Gestione Filtri **
    //
    syncFiltersWithQueryParams(queryParams = this.$route.query) {
      this.checkedTabellaSocieta = queryParams.societa
        ? queryParams.societa.split(",")
        : [];
      this.checkedTabellaTipologia = queryParams.tipologia
        ? queryParams.tipologia.split(",")
        : [];
    },
    clearAllAttributesFilters() {
      this.setCurrentPageCommesse(1);
      this.checkedTabellaSocieta = [];
      this.checkedTabellaTipologia = [];

      this.setSearchCommesse("");

      this.$router.push({
        name: "route-commesse",
        query: this.mixins_initCommesseQueryFilters(),
      });

      // if (this.$route.name === "route-commesse") {
      //   this.updateQueryParams();
      // } else {
      //   this.$router.push({
      //     name: "route-commesse",
      //     query: {}, // Resetta i query params per rimuovere i filtri
      //   });
      // }
    },
    onChangeFilterAttributes(attribute) {
      if (attribute === "societa") {
        this.setFilterCommesseSocieta(this.checkedTabellaSocieta);
      } else if (attribute === "tipologia") {
        this.setFilterCommesseTipologia(this.checkedTabellaTipologia);
      }

      this.setCurrentPageCommesse(1);
      this.updateQueryParams();
    },
    collectFiltersData() {
      return {
        societa: this.checkedTabellaSocieta.join(","),
        tipologia: this.checkedTabellaTipologia.join(","),
        labels: [
          ...this.tabellaSocieta
            .filter((a) => this.checkedTabellaSocieta.includes(a._id))
            .map((a) => a.ragionesociale),
          ...this.tabellaTipologia
            .filter((t) => this.checkedTabellaTipologia.includes(t.id))
            .map((t) => t.nome),
        ],
      };
    },
    updateQueryParams() {
      const filtersData = this.collectFiltersData();
      this.$router.push({ query: { ...this.$route.query, ...filtersData } });
    },
    toggleCheckbox(type, id) {
      const targetArray = this[
        `checkedTabella${type.charAt(0).toUpperCase() + type.slice(1)}`
      ];

      if (targetArray.includes(id)) {
        const index = targetArray.indexOf(id);
        if (index > -1) {
          targetArray.splice(index, 1);
        }
      } else {
        targetArray.push(id);
      }

      this.onChangeFilterAttributes(type);
    },
    //
    // ** Gestione Sort **
    //
    setOrder(value) {
      this.setCommesseSortType(value);
      this.setCurrentPageCommesse(1);
      this.updateQueryParams();
    },
  },
  async created() {
    try {
      this.tabellaTipologia = await this.mixins_fetchTipologiaCommesse();
      console.log("** tab per filtro tipologia commessa", this.tabellaTipologia);

      const datiSocieta = await this.mixins_getTabAziende();
      this.tabellaSocieta = JSON.parse(JSON.stringify(datiSocieta));
      console.log("** tab per filtro societa", this.tabellaSocieta);
    } catch (err) {
      console.error("Si è verificato un errore", err);
    }
  },
  mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    this.syncFiltersWithQueryParams();
  },
};
</script>
