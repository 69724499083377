class DayCommessa {
    constructor(data = {}) {
        this._id = {
            commessaId: data._id?.commessaId ?? "",
            commessaDescrizione: data._id?.commessaDescrizione ?? "",
            commessaNav: data._id?.commessaNav ?? "",
            day: data._id?.day ?? "",
            tipo_assenza: data._id?.tipo_assenza ?? "",
            id_rapportino: data._id?.id_rapportino ?? "",
            closed: data.closed?.closed ?? false,
            validated: data.validated?.validated ?? false,
        };
        this.ore_ordinarie = data.ore_ordinarie ?? 0;
        this.straordinario_ordinario = data.straordinario_ordinario ?? 0;
        this.straordinario_festivo = data.straordinario_festivo ?? 0;
        this.straordinario_notturno = data.straordinario_notturno ?? 0;
        this.reperibilita = data.reperibilita ?? 0;
        this.ferie = data.ferie ?? 0;
        this.rol = data.rol ?? 0;
        this.malattia = data.malattia ?? 0;
        this.permessi_giustificati = data.permessi_giustificati ?? 0;
        this.altro = data.altro ?? 0;
        this.ore_ordinarie_notturne = data.ore_ordinarie_notturne ?? 0;
        this.ore_ordinarie_festive = data.ore_ordinarie_festive ?? 0;
    }
}


class Day {
    constructor(data = {}) {
        this.date = data.date ?? null;
        this.dateString = data.dateString ?? "";
        this.dayOfMonth = data.dayOfMonth ?? null;
        this.dayName = data.dayName ?? "";
        this.isDomenica = data.isDomenica ?? false;
        this.isSabato = data.isSabato ?? false;
        this.isFestivita = data.isFestivita ?? false;
        this.isFestivitaLocale = data.isFestivitaLocale ?? false;
        this.descrizioneFestivita = data.descrizioneFestivita ?? "";

        // Verifico che commesse sia un array e che ogni elemento sia valido
        this.commesse = Array.isArray(data.commesse)
            ? data.commesse.map((c) => c ? new DayCommessa(c) : null).filter(c => c !== null)
            : [];
    }
}


export { DayCommessa, Day }