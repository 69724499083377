import Joi from "joi";

//
// Commessa
//
class RisorsaAssociata {
    constructor(data = {}) {
        this.idrisorsa = data.idrisorsa || "";
        this.fullname = data.fullname || "";
        this.datainizio = data.datainizio || null;
        this.datafine = data.datafine || null;
        this.straordinario = data.straordinario || false;
        this.reperibilita = data.reperibilita || false;
    }
}
class Commessa {
    constructor(data = {}) {
        this._id = data._id || "";
        this.cliente = {
            idcliente: data.cliente?.idcliente || "",
            descrizione: data.cliente?.descrizione || "",
        };
        this.societainterna = {
            idsocieta: data.societainterna?.idsocieta || "",
            descrizione: data.societainterna?.descrizione || "",
        };
        this.titolo = data.titolo || "";
        this.clientefinale = {
            idclientefinale: data.clientefinale?.idclientefinale || "",
            descrizione: data.clientefinale?.descrizione || "",
        };
        this.identificativonav = data.identificativonav || "";
        this.tipologia = data.tipologia || "";
        this.datainizio = data.datainizio || null;
        this.datafine = data.datafine || null;
        this.commerciale = {
            idcommerciale: data.commerciale?.idcommerciale || "",
            fullname: data.commerciale?.fullname || "",
        };
        this.centrocosto = data.centrocosto || "";
        this.risorseassociate = data.risorseassociate?.map(
            risorsa => new RisorsaAssociata(risorsa)
        ) || [];
        this.createdby = data.createdby || "";
        this.createdwhen = data.createdwhen || null;
        this.updatedby = data.updatedby || "";
        this.updatedwhen = data.updatedwhen || null;
        this.oldId = data.oldId || null;
    }
}

const RisorsaAssociataSchema = Joi.object({
    idrisorsa: Joi.string().allow("").optional(),
    fullname: Joi.string().allow("").optional(),
    datainizio: Joi.date().iso().allow(null).optional(),
    datafine: Joi.date().iso().allow(null).optional(),
    straordinario: Joi.boolean().optional(),
    reperibilita: Joi.boolean().optional(),
});
const CommessaSchema = Joi.object({
    _id: Joi.string().allow("").optional(),
    cliente: Joi.object({
        idcliente: Joi.string().required().messages({
            "string.empty": 'Il campo "CLIENTE DIRETTO" è obbligatorio',
            "any.required": 'Il campo "CLIENTE DIRETTO" è obbligatorio',
        }),
        descrizione: Joi.string().required().messages({
            "string.empty": 'Il campo "CLIENTE DIRETTO" è obbligatorio',
            "any.required": 'Il campo "CLIENTE DIRETTO" è obbligatorio',
        }),
    }).required(),
    societainterna: Joi.object({
        idsocieta: Joi.string().allow(""),
        descrizione: Joi.string().allow(""),
    }).optional(),
    titolo: Joi.string().required().messages({
        "string.empty": 'Il campo "TITOLO" è obbligatorio',
        "any.required": 'Il campo "TITOLO" è obbligatorio',
    }),
    clientefinale: Joi.object({
        idclientefinale: Joi.string().allow(""),
        descrizione: Joi.string().allow(""),
    }).optional(),
    identificativonav: Joi.string().allow(""),
    tipologia: Joi.string().required().messages({
        "string.empty": 'Il campo "TIPOLOGIA" è obbligatorio',
        "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
    }),
    datainizio: Joi.date().iso().required().messages({
        "date.base": 'Il campo "DATA INIZIO" deve essere una data valida',
        "any.required": 'Il campo "DATA INIZIO" è obbligatorio',
    }),
    datafine: Joi.date().iso().allow(null),
    commerciale: Joi.object({
        idcommerciale: Joi.string().allow(""),
        fullname: Joi.string().allow(""),
    }).optional(),
    centrocosto: Joi.string().allow(""),
    risorseassociate: Joi.array().items(RisorsaAssociataSchema).optional(),
    createdby: Joi.string().allow(""),
    createdwhen: Joi.date().iso().allow(null),
    updatedby: Joi.string().allow(""),
    updatedwhen: Joi.date().iso().allow(null),
    oldId: Joi.number().allow(null),
});

class Attivita {
    constructor({
        _id = '',
        risorsa = '',
        from = null,
        to = null,
        ordinario_diurno = 0,
        ordinario_festivo = 0,
        straordinario = 0,
        reperibilita = 0,
        societa = {},
        profili = [],
        ruolo = {}
    } = {}) {
        this._id = _id;
        this.risorsa = risorsa;
        this.from = this.parseDateToISO(from);
        this.to = this.parseDateToISO(to);
        this.ordinario_diurno = ordinario_diurno;
        this.ordinario_festivo = ordinario_festivo;
        this.straordinario = straordinario;
        this.reperibilita = reperibilita;
        this.societa = {
            id: societa.id || '',
            descrizione: societa.descrizione || '',
            giorniFerie: societa.giorniFerie || 0
        };
        this.profili = profili.map(profilo => ({
            id: profilo.id || '',
            descrizione: profilo.descrizione || ''
        }));
        this.ruolo = {
            id: ruolo.id || '',
            descrizione: ruolo.descrizione || ''
        };
    }

    parseDateToISO(dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date.toISOString() : null;
    }
}

export {
    Commessa,
    CommessaSchema,
    RisorsaAssociata,
    RisorsaAssociataSchema,
    Attivita
};