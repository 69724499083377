import messagesData from '@/data/messages_table.json';

const getMessageData = (group, code) => {

    const messageNotFound = {
        group: group,
        code: code,
        text: `${group} - ${code} | Messaggio inesistente nella tabella messaggi`,
        notificationType: "warning"
    }
    const message = messagesData.find(
        message => message.group === group && message.code === code
    );
    return message ? message : messageNotFound;
};

export default {
    getMessageData
};