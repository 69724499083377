<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE AZIENDE<span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-city fa-fw"></i>
                </span>
                <span v-if="getSearchAziende" class="mx-3 color-white"
                  >[ <span class="color-mainblue">{{ getSearchAziende }}</span> ]</span
                >
                <span v-if="aziende.length > 0 && !loading">
                  Tutti
                  <span class="ms-2" style="font-size: smaller">
                    ({{ totalRecords }} {{ totalRecords == 1 ? "elemento" : "elementi" }})
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="getSearchAziende"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="aziende.length > 0 && !loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Error -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <!-- No data -->
        <div v-if="aziende.length == 0 && !loading && !error">
          <h5 class="mt-1">Nessun dato da visualizzare</h5>
        </div>
        <!-- Data -->
        <div v-if="aziende.length > 0 && !loading">
          <!-- Export Data -->
          <div class="d-none row m-0 my-2 padding-x-4px">
            <div class="container-fluid p-0 mb-2">
              <div class="dropdown float-end">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_esporta"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span> <i class="me-2 fa-solid fa-file-export"></i>Esporta </span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_esporta"
                >
                  <li>
                    <div class="but dropdown-item small">
                      <span>AZIENDE in Excel</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Table Data -->
          <div class="row m-0 padding-x-4px">
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th>
                    RAGIONE SOCIALE<span class="small color-white fw-light ms-2"
                      >[ {{ getSortLabeltByValue(getAziendeSortType) }} ]</span
                    >
                  </th>
                  <th>DESCRIZIONE</th>
                  <th>IDENTIFICATIVO NAV</th>
                  <th width="80">PAGHE</th>
                  <th width="130">CODICE AZIENDA</th>
                  <th width="80">SEDI</th>
                  <th style="min-width: 100px !important; width: 100px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <tbody class="table-body-font td-vertical-center">
                <tr v-for="azienda in aziende" :key="azienda._id">
                  <td>{{ azienda.ragionesociale }}</td>
                  <td>{{ azienda.descrizione }}</td>
                  <td>{{ azienda.identificativonav }}</td>
                  <td class="td-center">{{ azienda.codicepaghe }}</td>
                  <td class="td-center">{{ azienda.codiceazienda }}</td>
                  <td class="td-center">{{ azienda.sedi.length }}</td>
                  <td class="td-actions td-center">
                    <button alt="MODIFICA" title="MODIFICA" @click="editAzienda(azienda)">
                      <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                    </button>
                    <button
                      class="delete"
                      alt="ELIMINA"
                      title="ELIMINA"
                      @click="askRemoveAzienda(azienda._id)"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <div
              class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
            >
              <div class="row" v-if="aziende.length > 0 && totalPages > 1">
                <vue-pagination
                  :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="6"
                  @pagechanged="onPageChange"
                ></vue-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { fetchAziende, deleteAzienda } from "@/services/api";
// import JsonExcel from "vue-json-excel3";
import VuePagination from "@/components/ui/VuePagination.vue";
import { mapGetters, mapActions } from "vuex";
import { Azienda } from "@/models/AziendaJoiModel";
import sortOrderList from "@/data/sort_order_list.json";

export default {
  components: {
    SearchButton,
    VuePagination,
    // esportaExcel: JsonExcel,
  },
  data() {
    return {
      isSearching: false,
      aziende: [],
      loading: false,
      error: null,
      page: 1,
      limit: 20,
      totalPages: 1,
      export_json_meta: [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
      totalRecords: 0,
      sortOrderList,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageAziende", "getAziendeSortType", "getSearchAziende"]),
  },
  watch: {
    getAziendeSortType() {
      this.fetchAziende();
    },
    getSearchAziende() {
      this.fetchAziende();
    },
  },
  methods: {
    ...mapActions(["setCurrentPageAziende", "setSearchAziende"]),
    async fetchAziendeData() {
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchAziende(
          this.page,
          this.limit,
          this.getAziendeSortType,
          this.getSearchAziende
        );
        const totalCount = response?.totalCount ?? 0;
        this.aziende = response.aziende?.map((item) => new Azienda(item)) ?? [];
        this.totalPages = Math.ceil(totalCount / this.limit);
        this.totalRecords = totalCount;
        console.log(this.aziende);
      } catch (err) {
        this.error = "Si è verificato un errore";
      } finally {
        this.loading = false;
      }
    },
    editAzienda(azienda) {
      this.$router.push({ name: "route-aziende-edit", params: { id: azienda._id } });
    },
    async askRemoveAzienda(id) {
      this.$swal
        .fire({
          title: "Cancellazione Azienda",
          html: "Sei sicuro di voler cancellare l'azienda?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeAzienda(id);
          }
        });
    },
    async removeAzienda(id) {
      this.loading = true;
      try {
        await deleteAzienda(id);
        this.page = 1;
        this.setCurrentPageAziende(1);
        await this.fetchAziendeData();
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'azienda", error);
        console.error("Error details:", error.response?.data);
        this.error = "Errore durante l'eliminazione dell'azienda";
      } finally {
        this.loading = false;
      }
    },
    onPageChange(page) {
      this.page = page;
      this.setCurrentPageAziende(page);
      this.fetchAziendeData();
    },
    fetchAziende() {
      this.page = 1;
      this.setCurrentPageAziende(1);
      this.fetchAziendeData();
    },
    getSortLabeltByValue(value) {
      const foundItem = this.sortOrderList.find((item) => item.value === value);
      return foundItem ? foundItem.text : "";
    },
    //
    // ** Gestione ricerca **
    //
    filterTextToSearch(text) {
      this.setSearchAziende(text);
    },
    filterClearTextToSearch() {
      this.setSearchAziende("");
    },
  },
  async created() {
    this.page = this.getCurrentPageAziende;
    await this.fetchAziendeData();
  },
};
</script>
