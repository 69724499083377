<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Routes / Filters -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link
            :to="{ name: 'route-dotazioni-add' }"
            exact
            :class="{ active: $route.name === 'route-dotazioni-add' }"
            >+ Crea Nuovo</router-link
          >
        </li>

        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <div>
          <li>
            <router-link
              @click.prevent="clearAllAttributesFilters()"
              :to="{ name: 'route-dotazioni' }"
              exact
              :class="{ active: $route.name === 'route-dotazioni' }"
              >Tutti</router-link
            >
          </li>
        </div>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Tipologia dotazioni -->
      <div v-if="tabellaDotazioni.length > 0">
        <ul class="unstyled nav-parent-item">
          <li style="z-index: 10 !important">
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuFiltroTipologia"
              style="color: #fff !important"
              >{{
                `Tipologia (${
                  checkedTabellaDotazioni.length > 0
                    ? checkedTabellaDotazioni.length
                    : "tutte"
                })`
              }}</a
            >
            <div id="menuFiltroTipologia" class="collapse show">
              <div
                class="scroll-container-filter"
                :class="{ 'element-disabled': isEditing }"
              >
                <div
                  class="btn-group-vertical d-block"
                  v-for="dotazione in tabellaDotazioni"
                  :key="dotazione.id"
                >
                  <input
                    :id="'filter_' + dotazione.nome"
                    :value="{ id: dotazione.id, nome: dotazione.nome }"
                    type="checkbox"
                    class="btn-check-filter"
                    v-model.trim="checkedTabellaDotazioni"
                    @change="onChangeFilterAttributes('tipologia')"
                  />
                  <label class="btn btn-filter" :for="'filter_' + dotazione.nome">{{
                    dotazione.nome
                  }}</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
      </div>

      <!-- Sort -->
      <ul class="unstyled nav-parent-item" :class="{ 'element-disabled': isEditing }">
        <li v-for="item in sortOrderList" :key="item.text" @click="setOrder(item.value)">
          <a href="#" :class="{ active: currentSortType === item.value }">{{
            item.text
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { fetchTabelle } from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import EventBus from "@/eventBus.js";
import sortOrderList from "@/data/sort_order_list.json";

export default {
  mixins: [UtilityMixins],
  components: {},
  data() {
    return {
      tabellaDotazioni: [],
      checkedTabellaDotazioni: [],
      sortOrderList,
    };
  },
  computed: {
    ...mapGetters([
      "getDotazioniSortType",
      "getFilterDotazioniTipologia",
      "getSearchDotazioni",
    ]),
    currentSortType() {
      return this.getDotazioniSortType;
    },
    isEditing() {
      return (
        this.$route.name === "route-dotazioni-edit" ||
        this.$route.name === "route-dotazioni-add"
      );
    },
  },
  watch: {
    checkedTabellaDotazioni(newVal) {
      EventBus.emit("filterChange", newVal);
    },
  },
  methods: {
    ...mapActions([
      "setDotazioniSortType",
      "setCurrentPageDotazioni",
      "setFilterDotazioniTipologia",
      "setSearchDotazioni",
    ]),
    setOrder(value) {
      this.setDotazioniSortType(value);
      // si posiziona sulla prima pagina
      this.setCurrentPageDotazioni(1);
      this.$emit("fetchDotazioni");
    },
    //
    // Rivedere la gestione dei filtri eliminare EventBus.emit e farla come Team (con query params)
    //
    clearAllAttributesFilters() {
      // si posiziona sulla prima pagina
      this.setCurrentPageDotazioni(1);
      // clear filtri tipologia dotazioni
      this.checkedTabellaDotazioni = [];
      this.setSearchDotazioni("");
      this.setFilterDotazioniTipologia(this.checkedTabellaDotazioni);
    },
    onChangeFilterAttributes(attribute) {
      if (attribute == "tipologia") {
        this.setCurrentPageDotazioni(1);
        this.setFilterDotazioniTipologia(this.checkedTabellaDotazioni);
        console.log("** filtro ", attribute, this.checkedTabellaDotazioni);
        return;
      }
    },
  },
  async mounted() {
    try {
      // get tabella dotazioni
      this.tabellaDotazioni = await this.mixins_getTabDotazioni();

      // clear tutti i filtri
      this.clearAllAttributesFilters();
    } catch (err) {
      console.error = "Si è verificato un errore";
    }
  },
};
</script>
