<template>
  <div class="d-flex justify-content-end">
    <div class="align-items-center d-flex justify-content-end">
      <div class="header-search-2">
        <div class="search-wrap-2 search-open" id="searchWrap">
          <form class="search-bar" novalidate @submit.prevent="submitSearch()">
            <input
              placeholder="Cerca..."
              type="search"
              id="inputSearch"
              name="inputSearch"
              ref="inputSearch"
              v-model.trim="model_searchText"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @input="handleClear($event)"
            />
            <button type="submit" class="button-search">
              <i class="zmdi zmdi-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  props: {
    searchText: { type: String, required: false, default: "" },
    searching: { type: Boolean, required: false, default: false },
  },
  emits: ["txtToSearch"],
  data() {
    return {
      minCharSearch: 2,
      model_searchText: "",
      model_searching: false,
    };
  },
  methods: {
    checkKeyDownAlphaNumSpecial(event) {
      if (!/[ A-Za-z0-9_@./#&+-,;:{}?!="'%$òàùèìé]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : "";
        event.preventDefault();
      }
    },
    handleClear(event) {
      if (!event.target.value.length) {
        console.log("** clearSearchResult");
        this.clearSearchResult();
      }
    },
    clearSearchResult() {
      this.model_searching = false;
      this.$emit("clearTxtToSearch");
    },
    submitSearch() {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      this.model_searchText = this.mixins_cleanTextForQuery(this.model_searchText);
      if (this.model_searchText.length < this.minCharSearch) {
        this.mixins_showMessage("Ricerca", "Digitare minimo 2 caratteri", "info");
        return;
      }
      this.model_searching = true;
      this.$emit("txtToSearch", this.model_searchText);
    },
  },
  mounted() {
    this.model_searchText = this.searchText;
    this.model_searching = this.searching;
  },
};
</script>
