<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Tipologia -->
      <ul class="unstyled nav-parent-item">
        <li
          v-for="(typeData, type) in groupedSkillsByType"
          :key="type"
          @click="selectType(type)"
        >
          <a href="#" :class="{ active: activeType === type }"
            >{{ typeData.label }} ({{ typeData.count }})</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import MappingSkillType from "@/data/mapping_skills_type.json";

// class SkillObject {
//   constructor(data = {}) {
//     this.id = data.id || "";
//     this.nome = data.nome || "";
//     this.descrizione = data.descrizione || "";
//     this.type = data.type || "";
//   }
// }

export default {
  mixins: [UtilityMixins],
  components: {},
  emits: ["type-selected", "update-skills", "loading-start", "loading-end"],
  data() {
    return {
      mappingSkillType: MappingSkillType,
      skillsSottoclassi: [],
      activeType: MappingSkillType[0].value, // Imposto la prima tipologia come attiva per default
    };
  },
  computed: {
    groupedSkillsByType() {
      const grouped = this.skillsSottoclassi.reduce((acc, skill) => {
        if (!acc[skill.type]) {
          const typeLabel =
            this.mappingSkillType.find((item) => item.value === skill.type)?.label ||
            skill.type;

          acc[skill.type] = { count: 0, label: typeLabel, skills: [] };
        }
        acc[skill.type].count++;
        acc[skill.type].skills.push(skill);
        return acc;
      }, {});

      return this.mappingSkillType.reduce((acc, item) => {
        if (grouped[item.value]) {
          acc[item.value] = grouped[item.value];
        }
        return acc;
      }, {});
    },
  },
  watch: {},
  methods: {
    selectType(type) {
      this.activeType = type; // Aggiorna la tipologia attiva
      this.$emit("type-selected", type);
    },
  },
  async mounted() {
    this.$emit("loading-start");

    // Carico la tabella delle sottoclassi degli skill
    this.skillsSottoclassi = await this.mixins_getTabSkillsData();
    console.log("** tab skillsSottoclassi", this.skillsSottoclassi);

    // Emetti la prima tipologia come default al caricamento
    this.$emit("type-selected", this.activeType);
    this.$emit("update-skills", this.skillsSottoclassi, this.mappingSkillType);

    this.$emit("loading-end");
  },
};
</script>
