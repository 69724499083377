<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- All -->
      <ul class="unstyled nav-parent-item">
        <li v-if="isVisible">
          <router-link to="/" exact>+ Crea Nuovo</router-link>
        </li>

        <hr
          v-if="isVisible"
          role="separator"
          aria-orientation="horizontal"
          class="divider"
        />
        <div v-if="isVisible">
          <li>
            <router-link to="/" @click.prevent="clearAllAttributesFilters()"
              >Tutte le comunicazioni</router-link
            >
          </li>
          <li>
            <router-link to="/" @click.prevent="clearAllAttributesFilters()"
              >Da leggere</router-link
            >
          </li>
          <li>
            <router-link to="/" @click.prevent="clearAllAttributesFilters()"
              >Lette</router-link
            >
          </li>
        </div>
      </ul>

      <hr
        v-if="isVisible"
        role="separator"
        aria-orientation="horizontal"
        class="divider"
      />
      <!-- Sort -->
      <ul v-if="isVisible" class="unstyled nav-parent-item">
        <li
          v-for="item in sortOrder"
          :key="item.text"
          @click.prevent="setOrder(item.value)"
        >
          <a
            href
            :class="{
              active: order == item.value,
            }"
            >{{ item.text }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      order: 0,
      isVisible: false,
    };
  },
  computed: {
    sortOrder() {
      return [
        {
          text: "Più Recenti",
          value: 0,
        },
        {
          text: "Meno Recenti",
          value: 1,
        },
        {
          text: "Alfabetico (A-Z)",
          value: 2,
        },
        {
          text: "Alfabetico (Z-A)",
          value: 3,
        },
      ];
    },
  },
  methods: {},
};
</script>
