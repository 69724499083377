import * as XLSX from "xlsx";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
    mixins: [UtilityMixins],
    methods: {
        exportStyledTableToExcel(fullname, mese, anno) {
            try {
                this.isLoading = true;

                // Aggiungo una leggera pausa per permettere al browser di aggiornare l'interfaccia
                setTimeout(() => {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = {};
                    const table = document.getElementById("tableRapportino");

                    // Ottieni il testo dell'intestazione
                    const headerTitle = document.querySelector("#dettaglioMensile h4").textContent;

                    // Crea la prima riga con il titolo
                    this.createCell(worksheet, 0, 0, headerTitle);

                    // Aggiungi una riga vuota per spaziatura
                    this.createCell(worksheet, 1, 0, '');

                    // Ottieni tutte le celle dell'header
                    const headerCells = Array.from(table.querySelectorAll("thead th:not(:first-child)"));

                    // Estrai i dati dell'header, escludendo l'ultimo TOT
                    const headerData = headerCells
                        .filter(cell => !cell.textContent.includes('TOT'))
                        .map(cell => {
                            const parts = cell.innerHTML.split('<br>').map(part =>
                                part.replace(/<\/?[^>]+(>|$)/g, '').trim()
                            );

                            return {
                                number: parts[0] || '',
                                day: parts[1] || ''
                            };
                        });

                    // Prima riga della tabella - numeri (ora alla riga 2)
                    this.createCell(worksheet, 2, 0, 'GIORNI', headerData.length);
                    headerData.forEach((data, index) => {
                        this.createCell(worksheet, 2, index + 1, data.number, headerData.length);
                    });
                    this.createCell(worksheet, 2, headerData.length + 1, 'TOT', headerData.length);

                    // Seconda riga della tabella - giorni (ora alla riga 3)
                    this.createCell(worksheet, 3, 0, '', headerData.length);
                    headerData.forEach((data, index) => {
                        this.createCell(worksheet, 3, index + 1, data.day, headerData.length);
                    });

                    // Resto dei dati, spostati di 2 righe in giù
                    let rowIndex = 4; // Inizia da 4 invece che da 2
                    table.querySelectorAll("tbody tr").forEach(tr => {
                        if (tr.querySelector('td')) {
                            const cells = tr.querySelectorAll('td');
                            cells.forEach((td, colIndex) => {
                                let value = td.textContent.trim();
                                if (value.match(/^\d+,?\d*$/)) {
                                    value = parseFloat(value.replace(',', '.'));
                                }
                                this.createCell(worksheet, rowIndex, colIndex, value, headerData.length);
                            });
                            rowIndex++;
                        }
                    });

                    // Aggiorna le dimensioni del foglio
                    worksheet['!ref'] = XLSX.utils.encode_range({
                        s: { r: 0, c: 0 },
                        e: { r: rowIndex - 1, c: headerData.length + 1 }
                    });

                    // Aggiorna le dimensioni delle colonne
                    worksheet['!cols'] = [
                        { wch: 25 },
                        ...Array(headerData.length).fill({ wch: 8 }),
                        { wch: 10 }
                    ];

                    // Aggiorna l'altezza delle righe
                    worksheet['!rows'] = [
                        { hpt: 30 },  // Riga titolo più alta
                        { hpt: 15 },  // Riga vuota
                        { hpt: 20 },  // Prima riga header
                        { hpt: 20 },  // Seconda riga header
                        ...Array(rowIndex - 4).fill({ hpt: 25 })  // Resto delle righe
                    ];

                    // Aggiorna i merge considerando le nuove posizioni
                    worksheet['!merges'] = [
                        // Merge per il titolo attraverso tutte le colonne
                        { s: { r: 0, c: 0 }, e: { r: 0, c: headerData.length + 1 } },
                        // Merge per GIORNI
                        { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
                        // Merge per TOT
                        { s: { r: 2, c: headerData.length + 1 }, e: { r: 3, c: headerData.length + 1 } }
                    ];

                    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapportino");

                    const fileName = `unigate-rapportino-${fullname.toLowerCase().replace(/\s+/g, '-')}-${mese.toLowerCase()}-${anno}.xlsx`;

                    XLSX.writeFile(workbook, fileName, {
                        bookType: 'xlsx',
                        compression: true
                    });

                    this.mixins_showMessage(
                        "Esporta in Excel",
                        "Esportazione completata con successo",
                        "success"
                    );
                    this.isLoading = false;
                }, 500); // Ritardo di 500ms per permettere l'aggiornamento dell'interfaccia
            } catch (error) {
                console.error("Errore durante l'esportazione:", error);
                this.mixins_showMessage(
                    "Esporta in Excel",
                    "Si è verificato un errore durante l'esportazione dei dati",
                    "error"
                );
                this.isLoading = false;
            }
        },
        createCell(worksheet, row, col, value, maxCols) {
            const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

            // Crea la cella di base
            worksheet[cellRef] = {
                v: value,
                t: typeof value === 'number' ? 'n' : 's'
            };

            // Applica formato numerico se necessario
            if (typeof value === 'number') {
                worksheet[cellRef].z = '0.0';
            }

            // Determina se la cella deve essere in grassetto
            const shouldBeBold = row === 0 ||                        // Intestazione utente
                row === 2 || row === 3 ||             // Righe dei giorni
                (col === 0 && row <= 3) ||            // GIORNI
                (col === maxCols + 1 && row <= 3);    // TOT

            // Applica gli stili
            worksheet[cellRef].s = {
                font: {
                    bold: shouldBeBold ? 1 : 0  // Usa 1 per bold, 0 per normal
                },
                alignment: {
                    vertical: 'center',
                    horizontal: this.getCellAlignment(row, col),
                    wrapText: true
                },
                border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                }
            };
        },



        getCellAlignment(row, col) {
            if (row <= 1) return 'center';
            if (col === 0) return 'left';
            return 'right';
        }
    }
};