<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <!-- Decrizione -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DESCRIZIONE</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <textarea
                  rows="3"
                  type="text"
                  v-input-textarea
                  class="form-control"
                  v-model.trim="localData.descrizione"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="DESCRIZIONE"
                  @keydown.enter.prevent
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
              :disabled="isSaveDisabled"
            >
              <span
                ><i class="zmdi zmdi-floppy"></i
                >{{ isEditMode ? "Modifica skill" : "Salva skill" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveData"],
  data() {
    return {
      localData: {},
      originalData: null,
    };
  },
  computed: {
    isSaveDisabled() {
      // Verifico se localData è definito e ha una descrizione
      if (!this.localData || !this.localData.descrizione) return true;

      // Controllo se non ci sono modifiche tra localData e originalData
      const noChanges =
        JSON.stringify(this.localData) === JSON.stringify(this.originalData);

      // Disabilito il pulsante se non ci sono modifiche in modalità di modifica o aggiunta
      return noChanges;
    },
  },

  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData`
        this.localData = { ...this.dataItem };
        this.originalData = { ...this.dataItem };
        console.log("** watch localData", this.localData);
      }
    },
  },
  methods: {
    closeModal() {
      this.localData = null;
      this.$emit("closeModal");
    },
    async saveData() {
      this.localData.nome = this.localData.descrizione;
      this.$emit("saveData", this.localData);
    },
  },
};
</script>
