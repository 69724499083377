<template>
  <main>
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :width="64"
      :height="64"
      backgroundColor="#000000"
      color="#149efd"
      loader="bars"
    />
    <!-- Main content -->
    <div class="container" style="margin-top: 33px !important">
      <div
        class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
      >
        <h1>
          PACO<span class="text-white small ms-3"><i class="bi bi-grid-3x3"></i></span>
        </h1>
      </div>
      <!-- Content -->
      <div class="container m-0 p-2 background-gray container-contents">
        <div class="align-items-center d-flex flex-row justify-content-center my-5 row">
          <!-- Colonna sinistra -->
          <div class="col-lg-4">
            <!-- Pac Data -->
            <div class="row g-2 align-items-center mb-3">
              <div class="col-lg-3 text-lg-end text-start">
                <label class="form-label">SOCIETÀ</label>
              </div>
              <div class="col-lg-9">
                <MultiSelect
                  v-model="selectedLocalModels.societa"
                  :options="societaOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'below'"
                  tagPlaceholder="Premi enter per creare un tag"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                  @update:modelValue="onSocietaUpdate"
                  :maxHeight="210"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <!-- Sede -->
            <div class="row g-2 align-items-center mb-3">
              <div class="col-lg-3 text-lg-end text-start">
                <label class="form-label">SEDE</label>
              </div>
              <div class="col-lg-9">
                <MultiSelect
                  :searchable="false"
                  v-model="selectedLocalModels.sede"
                  :options="sedeOptions"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :openDirection="'below'"
                  :showLabels="false"
                  @update:modelValue="onSedeUpdate"
                  :maxHeight="210"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <!-- Colonna destra -->
          <div class="col-lg-3">
            <!-- Paco Data -->
            <div class="row g-2 align-items-center mb-3">
              <div class="col-lg-3 text-lg-end text-start">
                <label class="form-label">MESE</label>
              </div>
              <div class="col-lg-9">
                <MultiSelect
                  :searchable="false"
                  v-model="selectedLocalModels.mese"
                  :options="mesiOptions"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :openDirection="'below'"
                  :showLabels="false"
                  @update:modelValue="onMeseUpdate"
                  :maxHeight="210"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <!-- Sede -->
            <div class="row g-2 align-items-center mb-3">
              <div class="col-lg-3 text-lg-end text-start">
                <label class="form-label">ANNO</label>
              </div>
              <div class="col-lg-9">
                <MultiSelect
                  :searchable="false"
                  v-model="selectedLocalModels.anno"
                  :options="anniOptions"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :openDirection="'below'"
                  :showLabels="false"
                  @update:modelValue="onAnnoUpdate"
                  :maxHeight="210"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
        </div>
        <!-- Download -->
        <div
          v-if="urlFileToDownload != ''"
          class="align-items-center d-flex flex-row justify-content-center my-5"
        >
          <button
            type="button"
            @click.prevent="downloadFile()"
            class="btn button-outline-1 small"
          >
            <span><i class="bi bi-download"></i>Scarica il file generato</span>
          </button>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <!-- Esporta -->
          <div class="my-3">
            <button
              v-if="urlFileToDownload == ''"
              @click="esportaPaco"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span><i class="bi bi-file-earmark-excel-fill"></i>Esporta Paco</span>
            </button>
            <button
              v-if="urlFileToDownload != ''"
              @click="nuovaEsportazione()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span><i class="bi bi-plus-lg"></i>Nuova Esportazione</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { exportDataPaco } from "@/services/api";

class PacoData {
  constructor(data = {}) {
    this.societa = data.societa
      ? {
          id: data.societa.id ?? "",
          descrizione: data.societa.descrizione ?? "",
        }
      : null;
    this.sede = data.sede
      ? {
          id: data.sede.id ?? "",
          descrizione: data.sede.descrizione ?? "",
        }
      : null;
    this.mese = data.mese ? data.mese : null;
    this.anno = data.anno ? data.anno : null;
  }
}

export default {
  mixins: [UtilityMixins],
  components: { Loading },
  data() {
    return {
      isLoading: false,
      localInputData: new PacoData(),
      societaOptions: [], // Array delle opzioni per la società
      sedeOptions: [], // Array delle sedi della società selezionata
      selectedLocalModels: {
        societa: [],
        sede: [],
        mese: [],
        anno: [],
      },
      mesi: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      mesiOptions: [],
      anniOptions: [],
      urlFileToDownload: "",
    };
  },
  methods: {
    async esportaPaco() {
      console.log("** parametri per endpoint", this.localInputData, this.validation());
      if (!this.validation()) {
        this.mixins_showMessage(
          "Esportazione Paco",
          "Tutti i campi sono obbligatori",
          "warning"
        );
        return;
      }
      // Effettuo l'esportazione...
      this.isLoading = true;

      try {
        const idAzienda = this.localInputData.societa.id;
        const idSede = this.localInputData.sede.descrizione;
        const anno = this.localInputData.anno;
        const mese = this.localInputData.mese;

        const response = await exportDataPaco(anno, mese, idAzienda, idSede);

        console.log("** response", response);

        if (response && response.url) {
          console.log("** paco export url", response.url);
          this.mixins_showMessage(
            "Esportazione Paco",
            "Esportazione terminata con successo",
            "info"
          );

          this.urlFileToDownload = response.url;
        } else {
          this.mixins_showMessage(
            "Esportazione Paco",
            `Errore durante l'esportazione dei dati`,
            "error"
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Esportazione Paco",
          `Errore durante l'esportazione dei dati`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    validation() {
      const societaValida =
        this.localInputData.societa && this.localInputData.societa.id !== "";
      const sedeValida = this.localInputData.sede && this.localInputData.sede.id !== "";
      const meseValido = this.localInputData.mese !== null;
      const annoValido = this.localInputData.anno !== null;

      return societaValida && sedeValida && meseValido && annoValido ? true : false;
    },
    getFileName(url) {
      // Uso split per dividere l'URL e ottenere l'ultimo segmento come nome del file e .pop prende l'ultima parte della stringa URL
      return url.split("/").pop();
    },
    nuovaEsportazione() {
      this.initDefaultValues();
    },
    initDefaultValues() {
      this.localInputData = new PacoData();
      this.urlFileToDownload = "";
      this.selectedLocalModels.societa = null;
      this.selectedLocalModels.sede = null;
      this.selectedLocalModels.mese = null;

      // Ottengo la data corrente
      const today = new Date();
      const currentYear = today.getFullYear();
      let currentMonth = today.getMonth() + 1; // Mesi in JavaScript partono da 0

      // Imposto il mese precedente e l'anno corretto
      if (currentMonth === 1) {
        // Se il mese corrente è gennaio, imposto dicembre come mese da elaborare e l'anno precedente
        this.selectedLocalModels.mese = { value: 12, label: this.mesi[11] }; // Mese dicembre
        this.selectedLocalModels.anno = {
          value: currentYear - 1,
          label: (currentYear - 1).toString(),
        };
      } else {
        this.selectedLocalModels.mese = {
          value: currentMonth - 1,
          label: this.mesi[currentMonth - 2],
        };
        this.selectedLocalModels.anno = {
          value: currentYear,
          label: currentYear.toString(),
        };
      }

      // Popolo localInputData con i valori selezionati per mese e anno
      this.localInputData.mese = this.selectedLocalModels.mese.value;
      this.localInputData.anno = this.selectedLocalModels.anno.value;
    },
    downloadFile_old() {
      // Non funziona. Il comportamento del browser di visualizzare alcuni tipi di file (come .txt o immagini)
      //invece di scaricarli dipende dal supporto nativo del browser per quei tipi di file
      if (this.urlFileToDownload) {
        const link = document.createElement("a");
        link.href = this.urlFileToDownload;
        const fileName = this.urlFileToDownload.split("/").pop();
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    async downloadFile() {
      // Questa soluzione crea un Blob per forzare il download
      const fileUrl = this.urlFileToDownload;
      const fileName = this.getFileName(fileUrl);

      try {
        const response = await fetch(fileUrl, {
          mode: "cors", // Modalità cross-origin
        });

        if (!response.ok) {
          throw new Error("Errore durante il download del file");
        }

        // Ottiengo i dati come blob
        const blob = await response.blob();

        // Creo un URL temporaneo per il blob
        const url = window.URL.createObjectURL(blob);

        // Creo un link temporaneo e simula il click per avviare il download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Nome del file che sarà scaricato
        document.body.appendChild(link);
        link.click();

        // Rimuovo il link temporaneo
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Errore durante il download:", error);
      }
    },
    //
    // ** Gestione Multiselect **
    //
    //
    // Societa
    //
    onSocietaUpdate(selected) {
      if (selected) {
        // Aggiorno i dettagli della società selezionata
        this.localInputData.societa = {
          id: selected.value,
          descrizione: selected.label,
        };

        // Popolo sedeOptions con le sedi corrispondenti alla società selezionata
        if (selected.sedi && selected.sedi.length > 0) {
          this.sedeOptions = selected.sedi.map((sede) => ({
            value: sede.id,
            label: sede.citta,
          }));
        } else {
          this.sedeOptions = [];
        }

        // Reset del modello sede se le sedi vengono aggiornate
        this.selectedLocalModels.sede = null;
        this.localInputData.sede = null;
      } else {
        this.localInputData.societa = null;
        this.sedeOptions = [];
        this.selectedLocalModels.sede = null;
      }
    },
    //
    // Sede
    //
    onSedeUpdate(selected) {
      if (selected) {
        this.localInputData.sede = {
          id: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localInputData.sede = null;
      }
      console.log(this.localInputData);
    },
    //
    // Mese
    //
    onMeseUpdate(selected) {
      if (selected) {
        this.localInputData.mese = selected.value ? selected.value : "";
      } else {
        this.localInputData.mese = null;
      }
    },
    //
    // Anno
    //
    onAnnoUpdate(selected) {
      if (selected) {
        this.localInputData.anno = selected.value ? selected.value : "";
      } else {
        this.localInputData.anno = null;
      }
    },
  },
  async created() {
    this.isLoading = true;

    // Carico le opzioni delle società
    const tabAziende = await this.mixins_getTabAziende();

    // Filtro le società che hanno almeno una sede
    const aziendeConSedi = tabAziende.filter(
      (societa) => societa.sedi && societa.sedi.length > 0
    );

    this.societaOptions = aziendeConSedi.map((societa) => ({
      value: societa._id,
      label: societa.ragionesociale,
      sedi: societa.sedi,
    }));

    // Carico le opzioni delle sedi per società
    if (this.societaOptions.sedi) {
      this.sedeOptions = this.societaOptions.sedi.map((sede) => ({
        value: sede.id,
        label: sede.citta,
      }));
    }

    // Carico le opzioni per gli anni
    const anni = this.mixins_getArrayYearsUpToCurrentYear();
    this.anniOptions = anni.map((item) => ({ value: item, label: item }));

    // Carico le opzioni per i mesi
    this.mesiOptions = this.mesi.map((item, index) => ({
      value: index + 1,
      label: item,
    }));

    // Inizializzo i valori
    this.initDefaultValues();

    this.isLoading = false;
  },
};
</script>
