import Joi from 'joi';

class Assegnazione {
    constructor(data = {}) {
        this.iduser = data.iduser || '';
        this.fullname = data.fullname || '';
        this.dataassegnazione = data.dataassegnazione || null;
        this.datariconsegna = data.datariconsegna || null;
    }
}

class Dotazione {
    constructor(data = {}) {
        this._id = data._id || '';
        this.tipologia = data.tipologia?.map(t => ({
            idtipologia: t.idtipologia || '',
            descrizione: t.descrizione || ''
        })) || [];
        this.descrizione = data.descrizione || '';
        this.matricola = data.matricola || '';
        this.annoacquisto = data.annoacquisto ?? 0;
        this.costostorico = data.costostorico ?? 0;
        this.ammortamentoanni = data.ammortamentoanni ?? 0;
        this.assegnatoa = data.assegnatoa?.map(a => new Assegnazione(a)) || [];
        this.logo = data.logo || '';
    }
}

const AssegnazioneSchema = Joi.object({
    iduser: Joi.string().required().messages({
        'string.base': 'Il campo "ASSEGNATO A" deve essere una stringa',
        'string.empty': 'Il campo "ASSEGNATO A" è obbligatorio',
        'any.required': 'Il campo "ASSEGNATO A" è obbligatorio',
    }),
    fullname: Joi.string().required().messages({
        'string.base': 'Il campo "ASSEGNATO A" deve essere una stringa',
        'string.empty': 'Il campo "ASSEGNATO A" non può essere vuoto',
        'any.required': 'Il campo "ASSEGNATO A" è obbligatorio',
    }),
    dataassegnazione: Joi.date().required().messages({
        'date.base': 'Il campo "DATA ASSEGNAZIONE" deve essere una data valida',
        'any.required': 'Il campo "DATA ASSEGNAZIONE" è obbligatorio',
    }),
    datariconsegna: Joi.date().allow(null).messages({
        'date.base': 'Il campo "DATA RICONSEGNA" deve essere una data valida o campo vuoto',
    }),
});

const DotazioneSchema = Joi.object({
    _id: Joi.string().allow(''),
    // tipologia: Joi.array().items(
    //     Joi.object({
    //         idtipologia: Joi.string().required(),
    //         descrizione: Joi.string().required(),
    //     }).required(),
    // ).required().messages({
    //     "array.base": 'Il campo "TIPOLOGIA" è obbligatorio',
    //     "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
    // }),
    tipologia: Joi.array()
        .min(1)
        .items(
            Joi.object({
                idtipologia: Joi.string().required().messages({
                    "string.base": 'Il campo "TIPOLOGIA" è obbligatorio',
                    "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
                }),
                descrizione: Joi.string().required().messages({
                    "string.base": 'Il campo "TIPOLOGIA" è obbligatorio',
                    "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
                }),
            }).required().messages({
                "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
            })
        )
        .required()
        .messages({
            "array.base": 'Il campo "TIPOLOGIA" è obbligatorio',
            "array.includes": 'Il campo "TIPOLOGIA" è obbligatorio',
            "array.min": 'Il campo "TIPOLOGIA" è obbligatorio',
            "any.required": 'Il campo "TIPOLOGIA" è obbligatorio',
            "any.empty": 'Il campo "TIPOLOGIA" è obbligatorio',
        }),
    descrizione: Joi.string().required().messages({
        'string.base': 'Il campo "DESCRIZIONE" è obbligatorio',
        'string.empty': 'Il campo "DESCRIZIONE" è obbligatorio',
        'any.required': 'Il campo "DESCRIZIONE" è obbligatorio',
    }),
    matricola: Joi.string().required().messages({
        'string.base': 'Il campo "MATRICOLA" è obbligatorio',
        'string.empty': 'Il campo "MATRICOLA" è obbligatorio',
        'any.required': 'Il campo "MATRICOLA" è obbligatorio',
    }),
    annoacquisto: Joi.number().integer().min(2010).max(new Date().getFullYear()).required().messages({
        'number.base': 'Il campo "ANNO ACQUISTO" è obbligatorio',
        'number.integer': 'Il campo "ANNO ACQUISTO" deve essere un numero intero',
        'number.min': 'Il campo "ANNO ACQUISTO" deve essere uguale o successivo al 2010',
        'number.max': `Il campo "ANNO ACQUISTO" non può essere successivo all'anno corrente`,
        'any.required': 'Il campo "ANNO ACQUISTO" è obbligatorio'
    }),
    costostorico: Joi.number().precision(2).min(0).messages({
        'number.base': 'Il campo "COSTO STORICO" è obbligatorio',
        'number.precision': 'Il campo "COSTO STORICO" può avere al massimo due decimali',
        'number.min': 'Il campo "COSTO STORICO" non può essere negativo'
    }),
    ammortamentoanni: Joi.number().min(0).messages({
        'number.base': 'Il campo "AMMORTAMENTO ANNI" deve essere un numero',
        'number.min': 'Il campo "AMMORTAMENTO ANNI" deve essere maggiore o uguale a 0'
    }),
    assegnatoa: Joi.array().items(AssegnazioneSchema),
    logo: Joi.string().allow(''),
});

export { DotazioneSchema, AssegnazioneSchema, Dotazione, Assegnazione };