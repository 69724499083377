import * as Profiles from '@/mixins/Profiles.js';

export default {
    data() {
        return {
        };
    },
    methods: {
        //
        // Authenticated
        //
        permissions_isAuthenticatedSuperAdmin(userAuthenticated) {
            // Restituisce se autenticato come 'SuperAdmin'
            if (userAuthenticated && userAuthenticated.isSuperAdmin) {
                return userAuthenticated.isSuperAdmin;
            }
            return false;
        },
        permissions_isAuthenticatedDTC(userAuthenticated) {
            // Restituisce se è autenticato come 'DTC'
            if (userAuthenticated && userAuthenticated.profili) {
                return userAuthenticated.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_DTC.toLowerCase()
                );
            }
            return false;
        },
        permissions_isAuthenticatedGestionePersonale(userAuthenticated) {
            // Restituisce se è autenticato come 'Gestione personale'
            if (userAuthenticated && userAuthenticated.profili) {
                return userAuthenticated.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_GESTIONE_PERSONALE.toLowerCase()
                );
            }
            return false;
        },
        permissions_isAuthenticatedConsultazione(userAuthenticated) {
            // Restituisce se è autenticato come 'Consultazione'
            if (userAuthenticated && userAuthenticated.profili) {
                return userAuthenticated.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_CONSULTAZIONE.toLowerCase()
                );
            }
            return false;
        },
        //
        // Profiles
        //
        permissions_isProfileGestionePersonale(localRapporto) {
            // Restituisce se è un profilo di tipo 'Gestione personale'
            if (localRapporto && localRapporto.profili) {
                return localRapporto.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_GESTIONE_PERSONALE.toLowerCase()
                );
            }
            return false;
        },
        permissions_isProfileDTC(localRapporto) {
            // Restituisce se è un profilo di tipo 'DTC'
            if (localRapporto && localRapporto.profili) {
                return localRapporto.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_DTC.toLowerCase()
                );
            }
            return false;
        },
        permissions_isProfileConsultazione(localRapporto) {
            // Restituisce se è un profilo di tipo 'Consultazione'
            if (localRapporto && localRapporto.profili) {
                return localRapporto.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_CONSULTAZIONE.toLowerCase()
                );
            }
            return false;
        },
        permissions_isProfileEsterno(localRapporto) {
            // Restituisce se è un profilo di tipo 'Esterno'
            if (localRapporto && localRapporto.profili) {
                return localRapporto.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_ESTERNO.toLowerCase()
                );
            }
            return false;
        },
        permissions_isProfileDipendente(localRapporto) {
            // Restituisce se è un profilo di tipo 'Dipendente'
            if (localRapporto && localRapporto.profili) {
                return localRapporto.profili.some(
                    (item) => item.descrizione.toLowerCase() === Profiles.PROFILE_DIPENDENTE.toLowerCase()
                );
            }
            return false;
        },
    }
};