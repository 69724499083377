<template>
  <teleport to="body">
    <vue3-snackbar bottom :duration="5000"></vue3-snackbar>
  </teleport>
  <div class="theme--dark application">
    <div class="application-wrap">
      <!-- Header -->
      <header-comp />
      <!-- Content -->
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.path"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import HeaderComp from "@/components/header/HeaderComp.vue";
import EventBus from "@/eventBus"; // Importa l'EventBus

export default {
  name: "App",
  components: {
    HeaderComp,
  },
  data() {
    return {
      options: {
        top: false,
        bottom: true,
        left: false,
        right: false,
        duration: 0,
      },
    };
  },
  created() {
    // Ascolta l'evento showMessage
    EventBus.on("showMessage", this.showMessage);
  },
  methods: {
    showMessage({ title, text, type }) {
      console.log("Messaggio:", title + " - " + text + " - " + type);
      // Usa Snackbar per visualizzare il messaggio
      this.$snackbar.add({
        title: title,
        type: type,
        text: text,
        options: this.options,
      });
    },
  },
};
</script>

<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
