<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <sidebar
          @type-selected="updateSelectedType"
          @update-skills="updateSkillsSottoclassi"
          @loading-start="loading = true"
          @loading-end="loading = false"
        />
        <!-- Main content -->
        <router-view
          :key="$route.fullPath"
          :selectedType="selectedType"
          :skillsSottoclassi="skillsSottoclassi"
          :mappingSkillType="mappingSkillType"
          :loading="loading"
        ></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/views/menu_impostazioni/skills/SkillsSidebar.vue";

export default {
  components: { Sidebar },
  data() {
    return {
      selectedType: null,
      skillsSottoclassi: [],
      mappingSkillType: [],
      loading: false, // Variabile per tracciare lo stato di caricamento
    };
  },
  methods: {
    updateSelectedType(type) {
      this.selectedType = type;
    },
    updateSkillsSottoclassi(skills, mapping) {
      this.skillsSottoclassi = skills;
      this.mappingSkillType = mapping;
    },
  },
};
</script>
