class ConstructorRapportinoTeam {
    constructor(data = {}) {
        this._id = data._id ?? "";

        this.user = {
            id: data.user?.id ?? "",
            fullname: data.user?.fullname ?? ""
        };

        this.commessa = {
            id: data.commessa?.id ?? "",
            descrizione: data.commessa?.descrizione ?? "",
            commessanav: data.commessa?.commessanav ?? ""
        };

        this.date = data.date ?? "";
        this.ore_ordinarie = data.ore_ordinarie ?? 0;
        this.straordinario_ordinario = data.straordinario_ordinario ?? 0;
        this.straordinario_festivo = data.straordinario_festivo ?? 0;
        this.straordinario_notturno = data.straordinario_notturno ?? 0;
        this.reperibilita = data.reperibilita ?? 0;
        this.ferie = data.ferie ?? 0;
        this.rol = data.rol ?? 0;
        this.malattia = data.malattia ?? 0;
        this.permessi_giustificati = data.permessi_giustificati ?? 0;
        this.altro = data.altro ?? 0;
        this.ore_ordinarie_notturne = data.ore_ordinarie_notturne ?? 0;
        this.ore_ordinarie_festive = data.ore_ordinarie_festive ?? 0;

        this.tipo_assenza = data.tipo_assenza ?? "";
        this.codice_ricetta = data.codice_ricetta ?? "";

        this.createdAt = data.createdAt ?? "";
        this.updatedAt = data.updatedAt ?? "";
        this.updatedby = data.updatedby ?? "";
        this.approvedby = data.approvedby ?? "";
        this.approvedAt = data.approvedAt ?? null;

        this.closed = data.closed ?? false;
        this.validated = data.validated ?? false;  // Imposta a false se null o undefined
        this.datevalidated = data.datevalidated ?? null;
        this.dateclosed = data.dateclosed ?? null;
    }
}


export { ConstructorRapportinoTeam }