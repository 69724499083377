<template>
  <p class="text-center">Pagina {{ currentPage }} di {{ totalPages }}</p>
  <ul class="pagination-ul">
    <li>
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
        aria-label="Vai alla prima pagina"
        alt="Vai alla prima pagina"
        title="Vai alla prima pagina"
        class="pagination-item"
      >
        Prima
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        aria-label="Vai alla pagina precedente"
        alt="Vai alla pagina precedente"
        title="Vai alla pagina precedente"
        class="pagination-item"
      >
        Precedente
      </button>
    </li>

    <li v-for="(page, index) in pages" :key="index">
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Vai alla pagina n° ${page.name}`"
        :alt="`Vai alla pagina n° ${page.name}`"
        :title="`Vai alla pagina n° ${page.name}`"
        class="pagination-item"
      >
        {{ page.name }}
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
        aria-label="Vai alla pagina successiva"
        alt="Vai alla pagina successiva"
        title="Vai alla pagina successiva"
        class="pagination-item"
      >
        Successiva
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        aria-label="Vai all'ultima pagina"
        alt="Vai all'ultima pagina"
        title="Vai all'ultima pagina"
        class="pagination-item"
      >
        Ultima
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  emits: ["pagechanged"],
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      let startPage = 1;
      if (this.currentPage === 1) {
        startPage = 1;
        return startPage;
      }
      if (this.currentPage === this.totalPages) {
        startPage = this.totalPages - this.maxVisibleButtons + 1;
        if (startPage < 1) {
          startPage = 1;
        }
        return startPage;
      }
      startPage = this.currentPage - 1;
      if (startPage < 1) {
        startPage = 1;
      }
      return startPage;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style>
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
}

.pagination-ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.pagination-item {
  font-size: 16px;
  text-decoration: none;
  color: #149efd;
  padding: 2px 10px;
  margin: 2px;
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #149efd;
  transition: 0.3s;
  background-color: white;
}

.pagination-item:hover,
.pagination-item.active {
  color: white !important;
  background-color: #149efd;
  border-color: #149efd;
}

.pagination-item.disable {
  color: white !important;
  background-color: #6c6c6c;
  border-color: #2e2e2e;
}
</style>
